<p-dialog
  header="Registro de ponto"
  [(visible)]="visible"
  [style]="{
    width: '50rem',
    height: '25rem',
  }"
  [draggable]="false"
  (onHide)="confirmarPonto()"
  contentStyleClass="blur-background"
>
  <div class="container">
    <form #pessoa="ngForm">
      <app-input
        label="Selecione o pessoa:"
        type="select"
        name="pessoa"
        [(ngModel)]="form.TxNome"
        (ngModelChange)="escolherPessoa($event)"
        [selectData]="pessoaOptions"
      ></app-input>
      <app-botao text="Confirmar ponto" (click)="confirmarPonto()"></app-botao>
    </form>
  </div>
</p-dialog>

<div *ngIf="visible" class="blur-overlay"></div>
<app-loader *ngIf="loader"></app-loader>
