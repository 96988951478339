import { Component, Input } from '@angular/core';

type BtnTypes = 'primario' | 'secundario';

@Component({
  selector: 'app-botao',
  templateUrl: './botao.component.html',
  styleUrl: './botao.component.scss',
})
export class BotaoComponent {
  @Input() type: BtnTypes = 'primario';
  @Input() width: string = '';
  @Input() bgColor: string = '';
  @Input() color: string = '';
  @Input() border: string = '';
  @Input() text: string = '';
  @Input() icon: string = '';
  @Input() disabled: boolean = false;
}
