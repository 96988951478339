import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { IFrenteTrabalhoForm } from './frente-trabalho-layout.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ViaCepService } from '../../../services/viacep/viacep.service';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';

type IEtapas = 1 | 2;

type IOptions = {
  name: string;
  value: number | string;
  idPerfil?: number;
  email?: string;
  telefone?: string;
  funcionarioId?: number;
};

@Component({
  selector: 'app-frente-trabalho-layout',
  templateUrl: './frente-trabalho-layout.component.html',
  styleUrls: ['./frente-trabalho-layout.component.scss'],
})
export class FrenteTrabalhoLayoutComponent implements OnInit, AfterViewInit {
  permission: number = 2;
  editando: boolean = false;
  etapa: IEtapas = 1;
  totalEtapas: number = 2;
  loader: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private toast: ToastrService,
    private activatedRoute: ActivatedRoute,
    private viaCepService: ViaCepService,
    private httpService: HttpService,
    private permissionService: PermissionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.activateRoute();
    this.getFuncionarios();
    this.changeDetectorRef.detectChanges();
  }

  isMode: number = 1;
  disabledToEdit: boolean = false;
  form: IFrenteTrabalhoForm = {
    nomeFrenteDeTrabalho: '',
    proprietario: '',
    email: '',
    telefone: '',
    cep: '',
    rua: '',
    numeroLocal: '',
    cidade: '',
    encarregado: '',
    telefoneEncarregado: '',
    tipoFrenteDeTrabalho: 0,
    capacidadeFuncionarios: '',
    frenteTrabalhoId: 0,
  };

  title: string = 'Cadastrar frente de trabalho';

  selectedEncarregado: IOptions = {
    name: '',
    value: '',
  };

  mockedSelectData = [
    {
      name: 'Alimentícia',
      value: 1,
    },
    {
      name: 'Construção',
      value: 2,
    },
    {
      name: 'Mecânica',
      value: 3,
    },
    {
      name: 'Automotivo',
      value: 4,
    },
  ];

  proprietarioOptions: IOptions[] = [
    {
      name: '',
      value: '',
      idPerfil: 0,
      email: '',
      telefone: '',
      funcionarioId: 0,
    },
  ];

  encarregadoOptions: IOptions[] = [
    {
      name: '',
      value: '',
      email: '',
    },
  ];

  ngAfterViewInit(): void {
    this.activateRoute();

    if (this.isMode == 2) {
      this.title = 'Editar frente de trabalho';
      this.disabledToEdit = true;
    }
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.isMode = params['isMode'];
      if (params['isMode'] == 2) {
        const IdFrenteTrabalho = params['IdFrenteTrabalho'];

        this.httpService
          .useGet('FrenteTrabalhoByFilter', { IdFrenteTrabalho })
          .subscribe({
            next: (_response) => {
              const valueForm = _response.DataSet[0];

              this.selectedEncarregado = this.findUser(
                this.encarregadoOptions,
                valueForm.IdEncarragado
              );
              this.handlerOnChangeSelect(
                this.selectedEncarregado,
                'encarregado'
              );

              this.form.encarregado = Number(this.selectedEncarregado.value);

              setTimeout(() => {
                this.form.tipoFrenteDeTrabalho =
                  Number(valueForm.TipoFrente) ?? 0;
              }, 100);

              this.form.frenteTrabalhoId = valueForm.Id;
              this.form.nomeFrenteDeTrabalho = valueForm.Descricao ?? '';
              this.form.proprietario = valueForm.Proprietario ?? '';
              this.form.cidade = valueForm.Cidade ?? '';
              this.form.capacidadeFuncionarios = valueForm.Capacidade;
              this.form.telefone = valueForm.Telefone ?? '';
              this.form.cep = valueForm.Cep;
              this.form.email = valueForm.Email ?? '';
              this.form.rua = valueForm.Endereco ?? '';
              this.form.numeroLocal = valueForm.NuResidencia ?? '';
            },
          });
      }
    });
  }

  proximaEtapa() {
    const form = this.form;

    if (form.nomeFrenteDeTrabalho && form.proprietario) {
      if (this.etapa < this.totalEtapas) {
        this.etapa++;
      }
    } else {
      Swal.fire({
        title: 'Atenção!',
        text: 'Preencha os campos obrigatórios antes de prosseguir.',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
  }

  etapaAnterior() {
    if (this.etapa > 0) {
      this.etapa--;
    }
  }

  findUser(option: any, event: any) {
    const selectedUser = option.find((x: any) => x.value === Number(event));
    return selectedUser;
  }

  handlerOnChangeSelect(selected: any, type: string) {
    if (selected) {
      if (selected.telefone) {
        this.form.telefoneEncarregado = selected?.telefone;
      }
    }
  }

  onChangeSelect(type: string, event: any) {
    this.selectedEncarregado = this.findUser(this.encarregadoOptions, event);
    this.handlerOnChangeSelect(this.selectedEncarregado, 'encarregado');
  }

  cadastrarFrenteDeTrabalho() {
    const payload = this.createPayload(this.form);
    const form = this.form;

    if (
      form.encarregado !== '0' &&
      form.tipoFrenteDeTrabalho !== '' &&
      form.capacidadeFuncionarios !== ''
    ) {
      if (this.isMode == 2) {
        this.httpService.usePut('UpdateFrenteTrabalho', payload).add(() => {
          this.router.navigate(['recursos-humanos/frente-de-trabalho']);
        });
      } else {
        this.httpService.usePost('CreateFrenteTrabalho', payload).add(() => {
          this.router.navigate(['recursos-humanos/frente-de-trabalho']);
        });
      }
    } else {
      Swal.fire({
        title: 'Atenção!',
        text: 'Preencha os campos obrigatórios antes de efetivar o cadastro.',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
  }

  getFuncionarios(idPerfil?: number) {
    let url = '/v1/Funcionario/ByFilter';

    if (idPerfil) {
      url = url + `?idPerfil=${idPerfil}`;
    }

    this.http.get(environment.url + url).subscribe({
      next: (response: any) => {
        this.encarregadoOptions = response.DataSet.map((x: any) => {
          return {
            name: x.TxNome,
            value: x.FuncionarioId,
            idPerfil: x.Perfil.IdPerfil,
            funcionarioId: Number(x.FuncionarioId),
            telefone: x.TxTeleFuncionario,
          } as IOptions;
        });
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }

  createPayload(form: IFrenteTrabalhoForm) {
    const body = {
      TxCep: form.cep,
      NuResidencia: form.numeroLocal,
      TxTelefone: form.telefone,
      NuTipo: form.tipoFrenteDeTrabalho,
      NuCapacidade: Number(form.capacidadeFuncionarios),
      TxNome: form.nomeFrenteDeTrabalho,
      TxNomeProprietario: form.proprietario,
      TxEmail: form.email,
      TxEndereco: form.rua,
      TxCidade: form.cidade,
      FuncionarioId: this.selectedEncarregado.funcionarioId,
      IdFrenteTrabalho: this.form.frenteTrabalhoId,
    };
    return body;
  }

  buscarCep() {
    this.loader = true;

    this.viaCepService.GetBuscarCep(this.form.cep, {
      onSuccess: (response: any) => {
        this.form.rua = response.logradouro;
        this.form.cidade = response.localidade;

        this.loader = false;
      },
    });
  }
}
