import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private permission: number = 0;

  setPermission(permission: number): void {
    this.permission = permission;
  }

  getPermission(): number {
    return this.permission;
  }
}
