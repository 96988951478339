import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppGuard } from '../../services/auth.guard';

const routes: Routes = [
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      title: 'Página não encontrada | BUILTUP',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class NotFoundRoutingModule {}
