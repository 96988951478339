import { Component, OnInit } from '@angular/core';

type IEtapas = 1 | 2 | 3;

@Component({
  selector: 'app-ponto',
  templateUrl: './ponto.component.html',
  styleUrls: ['./ponto.component.scss'],
})
export class PontoComponent implements OnInit {
  etapa: IEtapas = 1;
  maxEtapa: IEtapas = 3;
  estaAtrasado: boolean = false;
  dadosPessoa: any = {};

  receberEtapa(etapa: any) {
    if (this.etapa > 0 && this.etapa <= this.maxEtapa) {
      this.etapa = etapa;
    }
    return;
  }

  receberAtraso(estado: boolean) {
    this.estaAtrasado = estado;
  }

  receberDadosPessoa(dados: any) {
    this.dadosPessoa = dados;
  }

  constructor() {}

  ngOnInit() {}
}
