import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ILogin } from '../../../modules/login/login/login.model';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpService } from '../../../services/common/http/common.http.service';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
})
export class LoginLayoutComponent implements OnInit {
  @Output() loginEvent = new EventEmitter<ILogin>();
  loader: boolean = false;
  login: ILogin = {
    email: '',
    senha: '',
  };

  constructor(private httpService: HttpService, private router: Router) {}

  ngOnInit() {
    window.addEventListener('resize', this.updateCorLabel.bind(this));
  }

  loginSubmit() {
    this.loader = true;

    if (this.login.email === '' || this.login.senha === '') {
      this.loader = false;
      return;
    }

    const payload = {
      Email: this.login.email,
      Password: this.login.senha,
    };

    this.httpService.usePostFilter('Autenticar', payload).subscribe({
      next: (response: any) => {
        const data = response.DataSet;

        if (!response.IsSuccess) {
          Swal.fire({
            title: 'Atenção!',
            text: response.Message,
            icon: 'error',
            confirmButtonText: 'OK',
          });
          this.loader = false;
          return;
        }

        localStorage.setItem('Token', JSON.stringify(data.Token));
        localStorage.setItem(
          'GestaoAcesso',
          JSON.stringify(data.GestaoAcesso ?? '')
        );
        localStorage.setItem('Perfil', JSON.stringify(data.Perfil ?? ''));
        localStorage.setItem('Usuario', JSON.stringify(data.Usuario ?? ''));
        localStorage.setItem(
          'Funcionario',
          JSON.stringify(data.Funcionario ?? '')
        );
        localStorage.setItem('Frente', JSON.stringify(data.Frente ?? ''));
        this.router.navigate(['home']);

        this.loader = false;
      },
      error: (error) => {
        this.loader = false;
      },
    });
  }

  updateCorLabel(): string {
    return window.innerWidth < 940 ? 'white' : 'black';
  }
}
