import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from './localStorage.service'; // Certifique-se de que o caminho está correto

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('Token')?.replaceAll('"', ' ');
    let authReq = req;

    if (token) {
      if (token && !req.url.includes('viacep')) {
        authReq = req.clone({
          setHeaders: {
            'Access-Control-Allow-Origin': '*',
            type: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
      }
    } else {
      authReq = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          type: 'application/json',
        },
      });
    }

    return next.handle(authReq);
  }
}
