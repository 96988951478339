<div class="desktop">
  <div class="header">
    <img class="logo" src="assets/images/builtup.svg" />
  </div>
  <div class="body">
    <form #novaSenhaForm="ngForm" class="form">
      <app-input
        type="password"
        width="100%"
        label="Nova Senha"
        name="senha"
        placeholder="Digite a Senha"
        [(ngModel)]="form.senha"
        (ngModelChange)="validatePassword()"
      ></app-input>
      <app-input
        type="password"
        width="100%"
        label="Repetir nova Senha"
        name="senha"
        placeholder="Digite a nova senha"
        [(ngModel)]="form.novaSenha"
        (ngModelChange)="validatePassword()"
      ></app-input>
      <div class="validators">
        <p [ngClass]="{ valid: isLengthValid, invalid: !isLengthValid }">
          No mínimo 8 caracteres
        </p>
        <p [ngClass]="{ valid: hasNumber, invalid: !hasNumber }">
          Pelo menos um número
        </p>
        <p [ngClass]="{ valid: hasLowercase, invalid: !hasLowercase }">
          No mínimo 1 caractere minúsculo
        </p>
        <p [ngClass]="{ valid: hasUppercase, invalid: !hasUppercase }">
          No mínimo 1 caractere maiúsculo
        </p>
        <p [ngClass]="{ valid: hasSpecialChar, invalid: !hasSpecialChar }">
          No mínimo 1 caractere especial
        </p>
        <p [ngClass]="{ valid: passwordsMatch, invalid: !passwordsMatch }">
          As senhas devem ser iguais
        </p>
      </div>
      <app-botao
        width="100%"
        text="Definir senha"
        [disabled]="!isValid"
        (click)="definirNovaSenha()"
      ></app-botao>
    </form>
  </div>
</div>
