<p-dialog
  header="Extração de relatório"
  [(visible)]="visible"
  [style]="{
    width: '50rem',
    height: '30rem',
  }"
  [draggable]="false"
  (onHide)="this.inputClose.emit(true)"
>
  <div class="container">
    <h3>Selecione o Mês e Ano que deseja gerar o relatório</h3>
    <form #pessoa="ngForm">
      <div class="form-group">
        <app-input type="text" style="display: none"></app-input>
        <app-input
          label="Mês:"
          type="select"
          name="mes"
          [selectData]="mesOptions"
          [(ngModel)]="this.form.mes"
        ></app-input>
        <app-input
          label="Ano:"
          type="select"
          name="ano"
          [selectData]="anoOptions"
          [(ngModel)]="this.form.ano"
        ></app-input>
      </div>
      <app-botao
        text="Extrair relatório"
        (click)="extrairRelatorio()"
      ></app-botao>
    </form>
  </div>
</p-dialog>

<div *ngIf="visible" class="blur-overlay"></div>
<app-loader *ngIf="loader"></app-loader>
