<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo text="Cadastrar fornecedor"></app-titulo>
    </div>
    <div class="form">
      <app-input
        type="text"
        width="100%"
        label="Nome do fornecedor"
        name="nome"
        [(ngModel)]="form.nome"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Email"
        name="email"
        [(ngModel)]="form.email"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Telefone/Celular"
        name="telefone"
        [(ngModel)]="form.telefone"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Endereço"
        name="endereco"
        [(ngModel)]="form.endereco"
      ></app-input>
      <app-select-adicao
        label="Material fornecido"
        [selectData]="mockedFabricas"
        (items)="atualizarMateriais($event)"
      ></app-select-adicao>
      <div>
        <label *ngIf="this.form.materiais.length > 0"
          >Preço do material (Kg)</label
        >
        <ul class="materials">
          <li
            class="materials-div"
            *ngFor="let material of this.form.materiais"
          >
            <span>
              {{ material.name }}
            </span>
            <app-input type="money"></app-input>
          </li>
        </ul>
      </div>
      <app-input
        type="money"
        width="100%"
        label="Preço do frete"
        name="precoFrete"
        [(ngModel)]="form.precoFrete"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Previsão de entrega"
        name="previsaoEntrega"
        [(ngModel)]="form.previsaoEntrega"
      ></app-input>
      <app-input type="textarea" width="100%" label="Observações"></app-input>
    </div>
    <div class="form-button">
      <app-botao
        width="100%"
        text="Cadastrar fornecedor"
        (click)="associarPessoa()"
      ></app-botao>
    </div>
  </div>
</app-menu>
