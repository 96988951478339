import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionService } from '../../../services/permission.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../services/common/http/common.http.service';

type IOptions = {
  name: string;
  value: string | number;
};

@Component({
  selector: 'app-associar-epi-layout',
  templateUrl: './associar-epi-layout.component.html',
  styleUrls: ['./associar-epi-layout.component.scss'],
})
export class AssociarEpiLayoutComponent implements OnInit {
  permission: number = 2;
  totalEtapas: number = 2;
  loader: boolean = false;
  isMode: number = 0;
  idEpi: number = 0;
  pessoaList: any = [];

  form: any = {
    area: '',
    material: '',
    pessoas: [],
  };

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private http: HttpClient,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getPessoas();
    this.changeDetectorRef.detectChanges();
  }

  pessoaOptions: IOptions[] = [
    {
      name: '',
      value: '',
    },
  ];

  getPessoas() {
    this.loader = true;
    const NuStatus = 1;

    return this.httpService.useGet('PessoaByFilter', { NuStatus }).subscribe({
      next: (response: any) => {
        this.pessoaOptions = response.DataSet.map((x: any) => {
          return {
            name: x.TxNome,
            value: x.IdPessoa,
          };
        });
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
      complete: () => {
        this.activateRoute();
      },
    });
  }

  changeSelect(event: any) {
    let pessoa: any[] = [];

    event.forEach((element: any) => {
      pessoa.push(this.pessoaOptions.find((x) => x.value === Number(element)));
    });

    this.pessoaList = this.findNamePessoa(pessoa, event);

    this.form.pessoas = this.findValuePessoa(pessoa, event);
  }

  findNamePessoa(list: any[], value: any) {
    let nomePessoa: any[] = [];
    let foundItems;

    value.forEach((element: any) => {
      foundItems = list.filter((item) => item?.value === element);
      nomePessoa.push(...foundItems.map((item) => item.name));
    });

    return nomePessoa ? nomePessoa : null;
  }

  findValuePessoa(list: any[], value: any) {
    let idPessoa: any[] = [];
    let foundItems;

    value.forEach((element: any) => {
      foundItems = list.filter((item) => item?.value === element);

      idPessoa.push(...foundItems.map((item) => item.value));
    });

    return idPessoa ? idPessoa : null;
  }

  associarEpi() {
    this.loader = true;
    const payload = this.createAssociarPayload();
    const form = this.form;

    if (form.pessoa !== '0' && form.material) {
      this.httpService.usePost('AssociarEPI', payload).add(() => {
        this.router.navigate(['encarregado/listagem-epi']);
      });
    } else {
      this.loader = false;
      Swal.fire({
        title: 'Alerta!',
        text: 'Preencha todos os campos obrigatórios',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
  }

  createAssociarPayload() {
    const body = {
      PessoaIds: this.form.pessoas,
      EPIId: this.idEpi,
    };
    return body;
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((item) => {
      this.isMode = item['isMode'];
      if (item['isMode'] == 2) {
        this.idEpi = Number(item['idEpi']);
        this.form.material = item['equipamento'];
        this.form.area = item['areaEpi'];

        this.getAssociar(Number(item['idEpi']));
      }
    });
  }

  getAssociar(params: number) {
    const id = params;

    this.httpService.useGet('EPIWithPessoa', { id }).subscribe({
      next: (response: any) => {
        let reedCadastrados: number[] = [];
        reedCadastrados = response.Pessoas.map((x: any) => x.IdPessoa);

        this.changeSelect(reedCadastrados);
        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }
}
