import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  AfterViewInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import Swal from 'sweetalert2';
import { PermissionService } from '../../../services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../services/common/http/common.http.service';
import { Camera } from '@mediapipe/camera_utils';
import { FaceDetection } from '@mediapipe/face_detection';
import { getFuncionarioId } from '../../../utils/utility-functions';
import { compareDesc } from 'date-fns';

@Component({
  selector: 'app-ponto-layout',
  templateUrl: './ponto-layout.component.html',
  styleUrls: ['./ponto-layout.component.scss'],
})
export class PontoLayoutComponent implements OnInit, OnDestroy {
  permission: number = 2;
  @Output() mudarEtapa = new EventEmitter<number>();
  @Output() estaAtrasado = new EventEmitter<boolean>();
  @Output() dadosPessoa = new EventEmitter<any>();
  @Input() loader: boolean = false;
  pessoaData: any = [];
  NuFicha: string = '';
  habilitarCamera: boolean = false;
  tirarFotoAtivo: boolean = false;
  visibleSelecaoPessoa: boolean = false;
  funcionarioId: number = getFuncionarioId();

  @ViewChild('video', { static: true }) videoElement!: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElement!: ElementRef;
  @ViewChild('faceCanvas', { static: true })
  faceCanvas!: ElementRef<HTMLCanvasElement>;
  camera: any;
  faceDetection: any;
  feedbackMessages: string[] = [];

  checkFaceTimeout: any = null;
  fazerReconhecimentoFacial = {
    imagem: '',
  };

  private pontoCooldown: boolean = false;

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.permission = this.permissionService.getPermission();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.camera) {
      this.camera.stop();
    }
    clearTimeout(this.checkFaceTimeout);
  }

  initializeFaceDetection() {
    if (!this.videoElement || !this.canvasElement || !this.faceCanvas) {
      return;
    }

    this.faceDetection = new FaceDetection({
      locateFile: (file: any) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
    });

    this.faceDetection.setOptions({
      model: 'short',
      minDetectionConfidence: 0.5,
      maxNumFaces: 1,
    });

    this.faceDetection.onResults(this.onResults.bind(this));

    const videoEl = this.videoElement.nativeElement;
    const canvasEl = this.canvasElement.nativeElement;
    const faceCanvasEl = this.faceCanvas.nativeElement;

    videoEl.width = 480;
    videoEl.height = 480;
    canvasEl.width = 480;
    canvasEl.height = 480;
    faceCanvasEl.width = 480;
    faceCanvasEl.height = 480;

    this.camera = new Camera(videoEl, {
      onFrame: async () => {
        await this.faceDetection.send({ image: videoEl });
      },
      width: 480,
      height: 480,
    });
    this.camera.start();
  }

  onResults(results: any) {
    const canvasCtx = this.canvasElement.nativeElement.getContext('2d');
    if (!canvasCtx) return;

    canvasCtx.clearRect(
      0,
      0,
      this.canvasElement.nativeElement.width,
      this.canvasElement.nativeElement.height
    );
    canvasCtx.drawImage(
      this.videoElement.nativeElement,
      0,
      0,
      this.canvasElement.nativeElement.width,
      this.canvasElement.nativeElement.height
    );
    this.feedbackMessages = [];

    if (results.detections && results.detections.length > 0) {
      for (const detection of results.detections) {
        const boundingBox = detection.boundingBox;
        const confidence = detection.V[0].ga;
        const landmarks = detection.landmarks;

        const facePositionCorrect = this.checkFacePosition(
          boundingBox,
          confidence,
          landmarks
        );

        this.drawRectangleOrCircle(
          canvasCtx,
          boundingBox,
          confidence,
          facePositionCorrect
        );

        setTimeout(() => {
          if (facePositionCorrect && confidence >= 0.9) {
            if (!this.tirarFotoAtivo && !this.pontoCooldown) {
              this.tirarFotoAtivo = true;
              this.takePhoto();
              this.feedbackMessages = [];

              this.pontoCooldown = true;
              console.log('Ponto cooldown started', this.pontoCooldown);
              setTimeout(() => {
                this.pontoCooldown = false;
                console.log('Ponto cooldown finished', this.pontoCooldown);
              }, 5000);
            }
          } else if (!facePositionCorrect || confidence <= 0.9) {
            this.tirarFotoAtivo = false;
          }
        }, 1000);
      }
    } else {
      this.tirarFotoAtivo = false;
      clearTimeout(this.checkFaceTimeout);
      this.feedbackMessages.push(
        'Nenhum rosto detectado. Por favor, centralize seu rosto e verifique a iluminação.\n'
      );
    }
  }

  checkFacePosition(
    boundingBox: any,
    confidence: number,
    landmarks: any
  ): boolean {
    const xCenter = boundingBox.xCenter;
    const yCenter = boundingBox.yCenter;

    const xMin = 0.4;
    const xMax = 0.6;
    const yMin = 0.4;
    const yMax = 0.6;

    let positionCorrect = true;

    if (confidence < 0.6) {
      this.feedbackMessages.push(
        'A iluminação é insuficiente. Por favor, mova-se para um local mais iluminado.\n'
      );
      positionCorrect = false;
    }

    if (xCenter < xMin) {
      this.feedbackMessages.push('Mova-se mais para a direita.\n');
      positionCorrect = false;
    } else if (xCenter > xMax) {
      this.feedbackMessages.push('Mova-se mais para a esquerda.\n');
      positionCorrect = false;
    }

    if (yCenter < yMin) {
      this.feedbackMessages.push('Mova-se mais para baixo.\n');
      positionCorrect = false;
    } else if (yCenter > yMax) {
      this.feedbackMessages.push('Mova-se mais para cima.\n');
      positionCorrect = false;
    }

    const leftEye = landmarks[0];
    const rightEye = landmarks[1];
    const eyeDistance = Math.abs(leftEye.x - rightEye.x);

    if (eyeDistance < 0.1) {
      this.feedbackMessages.push('Por favor, vire seu rosto para frente.\n');
      positionCorrect = false;
    }

    return positionCorrect;
  }

  drawRectangleOrCircle(
    ctx: CanvasRenderingContext2D,
    boundingBox: any,
    confidence: number,
    facePositionCorrect: boolean
  ) {
    const x =
      boundingBox.xCenter * this.canvasElement.nativeElement.width -
      (boundingBox.width * this.canvasElement.nativeElement.width) / 2;
    const y =
      boundingBox.yCenter * this.canvasElement.nativeElement.height -
      (boundingBox.height * this.canvasElement.nativeElement.height) / 2;
    const width = boundingBox.width * this.canvasElement.nativeElement.width;
    const height = boundingBox.height * this.canvasElement.nativeElement.height;

    if (facePositionCorrect && confidence >= 0.9) {
      ctx.beginPath();
      ctx.ellipse(
        boundingBox.xCenter * this.canvasElement.nativeElement.width,
        boundingBox.yCenter * this.canvasElement.nativeElement.height,
        (boundingBox.width * this.canvasElement.nativeElement.width) / 2 + 10,
        (boundingBox.height * this.canvasElement.nativeElement.height) / 2 + 45,
        0,
        0,
        2 * Math.PI
      );
      ctx.lineWidth = 4;
      ctx.strokeStyle = 'green';
      ctx.stroke();
    } else {
      ctx.beginPath();
      ctx.ellipse(
        boundingBox.xCenter * this.canvasElement.nativeElement.width,
        boundingBox.yCenter * this.canvasElement.nativeElement.height,
        (boundingBox.width * this.canvasElement.nativeElement.width) / 2 + 10,
        (boundingBox.height * this.canvasElement.nativeElement.height) / 2 + 45,
        0,
        0,
        2 * Math.PI
      );
      ctx.lineWidth = 4;
      ctx.strokeStyle = 'red';
      ctx.stroke();
    }

    ctx.font = '18px Arial';
    ctx.fillStyle = facePositionCorrect ? 'green' : 'red';
    ctx.fillText(`${(confidence * 100).toFixed(2)}%`, x, y > 10 ? y - 5 : 10);
  }

  startCamera() {
    this.habilitarCamera = !this.habilitarCamera;
    if (this.habilitarCamera) {
      this.startVideo();
    } else {
      this.stopCamera();
      this.feedbackMessages = [];
    }
  }
  startVideo() {
    const constraints = { video: true };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.tirarFotoAtivo = true;
        this.videoElement.nativeElement.srcObject = stream;
        this.videoElement.nativeElement.onloadedmetadata = () => {
          this.videoElement.nativeElement.play();
        };
      })
      .catch((error) => {
        this.tirarFotoAtivo = false;
        Swal.fire({
          title: 'Atenção!',
          text: 'Não foi possível acessar a câmera! Ative a câmera para continuar.',
          icon: 'warning',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#37aaF7',
        });
      });

    this.initializeFaceDetection();
  }

  takePhoto() {
    if (this.pontoCooldown) {
      console.log("Ponto cooldown, can't take photo");
      return;
    }

    this.loader = true;
    this.tirarFotoAtivo = false;

    const context = this.canvasElement.nativeElement.getContext('2d');
    const videoWidth = this.videoElement.nativeElement.videoWidth;
    const videoHeight = this.videoElement.nativeElement.videoHeight;
    const canvasWidth = this.canvasElement.nativeElement.width;
    const canvasHeight = this.canvasElement.nativeElement.height;

    const cropWidth = videoWidth * 0.65;
    const cropHeight = videoHeight * 0.75;
    const xOffset = (videoWidth - cropWidth) / 2;
    const yOffset = (videoHeight - cropHeight) / 2;

    context.drawImage(
      this.videoElement.nativeElement,
      xOffset,
      yOffset,
      cropWidth,
      cropHeight,
      0,
      0,
      canvasWidth,
      canvasHeight
    );

    const imageData = this.canvasElement.nativeElement.toDataURL('image/jpeg');
    this.fazerReconhecimentoFacial.imagem = imageData.split(',')[1];
    this.clearCanvas();

    this.httpService
      .usePostFilter('Recognize', {
        Imagem64: this.fazerReconhecimentoFacial.imagem,
      })
      .subscribe({
        next: (response: any) => {
          this.NuFicha = response.DataSet;
          this.clearCanvas();

          if (!response.IsSuccess) {
            this.loader = false;
            Swal.fire({
              title: 'Atenção!',
              text: response.Message ?? 'Pessoa não reconhecida.',
              icon: 'warning',
              showConfirmButton: false,
              timer: 1000,
            });

            this.habilitarCamera = true;

            return;
          }

          Swal.fire({
            title: 'Reconhecimento efetuado!',
            text: `Apontamento realizado para ${response.Message}.`,
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
          }).then((result) => {
            this.baterPonto();
          });
        },
        error: (error) => {
          Swal.fire({
            title: 'Atenção!',
            text: 'Ocorreu uma falha, tente novamente.',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1000,
          });
          this.loader = false;

          this.habilitarCamera = true;
        },
        complete: () => {
          clearTimeout(this.checkFaceTimeout);
          this.loader = false;
        },
      });
  }

  baterPonto() {
    this.loader = true;

    this.httpService
      .usePostFilter('FaceTimekeeping', {
        ImageBase64: this.fazerReconhecimentoFacial.imagem,
        NuFicha: this.NuFicha,
        PessoaId: this.funcionarioId,
      })
      .subscribe({
        next: (response: any) => {
          const data = response.DataSet;

          if (!response.IsSuccess) {
            this.loader = false;
            return;
          }
        },
        error: (error) => {
          this.loader = false;
        },
        complete: () => {
          this.loader = false;

          this.clearCanvas();
        },
      });
  }

  switchCamera() {
    if (this.videoElement.nativeElement.srcObject) {
      this.videoElement.nativeElement.srcObject
        .getTracks()
        .forEach((track: any) => {
          track.stop();
        });
    }
    this.videoElement.nativeElement.play();
  }

  stopCamera() {
    if (this.videoElement.nativeElement.srcObject) {
      const tracks = this.videoElement.nativeElement.srcObject.getTracks();
      tracks.forEach((track: any) => {
        track.stop();
      });
      this.videoElement.nativeElement.srcObject = null;
    }
    this.tirarFotoAtivo = false;
  }

  clearCanvas() {
    if (this.canvasElement) {
      const context = this.canvasElement.nativeElement.getContext('2d');
      if (context) {
        context.clearRect(
          0,
          0,
          this.canvasElement.nativeElement.width,
          this.canvasElement.nativeElement.height
        );
      }
    }
  }

  setCanvasNull() {
    if (this.canvasElement) {
      const context = this.canvasElement.nativeElement.getContext('2d');
      if (context) {
        context.clearRect(
          0,
          0,
          this.canvasElement.nativeElement.width,
          this.canvasElement.nativeElement.height
        );
      }
    }
  }
}
