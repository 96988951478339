import { Injectable, inject } from '@angular/core';
import { IValidation, IScreen } from './validation.model';
import { z, ZodError } from 'zod';

const screenType: IScreen = {
  usuario: 'Usuário',
};

const inputName: any = {
  email: 'E-mail',
  perfil: 'Perfil',
  telefone: 'Telefone',
  nomeUsuario: 'Nome do Usuário',
};

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  validateFields(params: any) {
    const copiedParams = { ...params };

    const keys = this.validateValue(copiedParams);

    const schema = z.object(keys);

    try {
      return schema.parse(params);
    } catch (error) {
      const zodError = error as ZodError;
      const [issue] = zodError.issues;
      throw new Error(`${issue.message}`);
    }
  }

  validateValue(params: any) {
    const keys = params;
    for (const key in keys) {
      const typeValue = typeof keys[key];
      const message = `Preencha o campo ${inputName[key]}.`;

      if (key.includes('_')) {
        if (typeValue === 'string') {
          keys[key] = z.string().optional();
        }

        if (typeValue === 'number') {
          keys[key] = z.number().optional();
        }
      } else {
        if (typeValue === 'string') {
          keys[key] = z
            .string({ message: message })
            .refine((n: any) => n !== null && n !== '', {
              message: message,
            });
        }

        if (typeValue === 'number') {
          keys[key] = z
            .number({ message: message })
            .refine((n: any) => n !== null && n !== 0);
        }
      }
    }

    return keys;
  }
}
