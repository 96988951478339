<app-menu>
  <div class="desktop">
    <div class="header">
      <app-titulo text="Frentes de Trabalho"></app-titulo>
      <div class="header-buttons">
        <app-botao
          width="100%"
          text="Cadastrar Frente de Trabalho"
          icon="assets/images/add.svg"
          (click)="navegarCadastrarFrenteDeTrabalho()"
          *ngIf="this.permission === 0"
        ></app-botao>
      </div>
    </div>
    <div class="table-header">
      <h1 class="table-title">Listagem</h1>
      <div class="table-filters">
        <!-- <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          [(ngModel)]="pesquisaParams.TxNome"
          (ngModelChange)="onChangeFilter($event)"
        ></app-input> -->
      </div>
    </div>
    <app-tabela [data]="tableData" [columns]="tableColumns"></app-tabela>
    <div class="paginacao">
      <app-paginacao
        [pages]="pesquisaParams.MaxPages"
        (selectedPage)="mudarPagina($event)"
      ></app-paginacao>
    </div>
  </div>

  <div class="mobile">
    <app-titulo text="Pessoa"></app-titulo>
    <div class="listagem">
      <div
        class="menu-mobile"
        (click)="this.expandirCardsMobile = !this.expandirCardsMobile"
      >
        <app-titulo class="title" text="Listagem"></app-titulo>
        <img
          *ngIf="!expandirCardsMobile"
          src="assets/images/arrow-down.svg"
          class="expandir-icon"
        />
        <img
          *ngIf="expandirCardsMobile"
          src="assets/images/arrow-up.svg"
          class="expandir-icon"
        />
      </div>
      <div class="mobile-filters">
        <!-- <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          [(ngModel)]="pesquisaParams.TxNome"
          (ngModelChange)="onChangeFilter($event)"
        ></app-input> -->
        <app-botao
          width="100%"
          text="Cadastrar pessoa"
          icon="assets/images/add.svg"
          (click)="navegarCadastrarFrenteDeTrabalho()"
          *ngIf="this.permission === 0"
        ></app-botao>
      </div>
      <app-card-mobile
        [data]="tableData"
        [rows]="rowsMobile"
        [expand]="expandirCardsMobile"
      ></app-card-mobile>
      <app-paginacao [pages]="1"></app-paginacao>
      <app-botao
        type="secundario"
        text="Exportar Excel"
        width="100%"
        icon="assets/images/download-icon.svg"
      ></app-botao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
