import { NgModule } from '@angular/core';
import { LayoutModule } from '../../layout/layout.module';

import { ComponentsModule } from '../../components/components.module';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';

@NgModule({
  declarations: [HomeComponent],
  imports: [HomeRoutingModule, ComponentsModule, LayoutModule],
  exports: [],
})
export class HomeModule {}
