import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxFileDropModule } from 'ngx-file-drop';

import { BotaoComponent } from './botao/botao.component';
import { InputComponent } from './input/input.component';
import { LoaderComponent } from './loader/loader.component';
import { MenuComponent } from './menu/menu.component';
import { TituloComponent } from './titulo/titulo.component';
import { TituloMenuComponent } from './titulo-menu/titulo-menu.component';
import { TabelaComponent } from './tabela/tabela.component';
import { GraficoComponent } from './grafico/grafico.component';
import { DragDropComponent } from './drag-drop/drag-drop.component';
import { SelectAdicaoComponent } from './select-adicao/select-adicao.component';
import { PaginacaoComponent } from './paginacao/paginacao.component';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import { CardMobileComponent } from './card-mobile/card-mobile.component';
import { DragDropPdfComponent } from './drag-drop-pdf/drag-drop-pdf.component';
import { VoltarComponent } from './voltar/voltar.component';
import { SelectCheckComponent } from './select-check/select-check.component';
import { CardHorarioComponent } from './card-horario/card-horario.component';
import { TextoReconhecimentoComponent } from './texto-reconhecimento/texto-reconhecimento.component';

@NgModule({
  declarations: [
    BotaoComponent,
    InputComponent,
    LoaderComponent,
    MenuComponent,
    TituloComponent,
    TituloMenuComponent,
    TabelaComponent,
    GraficoComponent,
    DragDropComponent,
    SelectAdicaoComponent,
    PaginacaoComponent,
    CardMobileComponent,
    CabecalhoComponent,
    DragDropPdfComponent,
    VoltarComponent,
    SelectCheckComponent,
    CardHorarioComponent,
    TextoReconhecimentoComponent,
  ],
  imports: [CommonModule, FormsModule, NgxMaskDirective, NgxFileDropModule],
  exports: [
    BotaoComponent,
    InputComponent,
    LoaderComponent,
    MenuComponent,
    TituloComponent,
    TituloMenuComponent,
    TabelaComponent,
    GraficoComponent,
    DragDropComponent,
    SelectAdicaoComponent,
    PaginacaoComponent,
    CardMobileComponent,
    CabecalhoComponent,
    DragDropPdfComponent,
    VoltarComponent,
    SelectCheckComponent,
    CardHorarioComponent,
    TextoReconhecimentoComponent,
  ],
  providers: [provideEnvironmentNgxMask({ validation: false })],
})
export class ComponentsModule {}
