import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { PermissionService } from '../../../services/permission.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../services/common/http/common.http.service';
import { IGetListagemFrentesTrabalhoResponse } from './listagem-frente-trabalho.model';
import { formatTel } from '../../../utils/utility-functions';

@Component({
  selector: 'app-listagem-frente-trabalho-layout',
  templateUrl: './listagem-frente-trabalho-layout.component.html',
  styleUrls: ['./listagem-frente-trabalho-layout.component.scss'],
})
export class ListagemFrenteTrabalhoLayoutComponent implements OnInit {
  permission: number = 2;
  tableData: IGetListagemFrentesTrabalhoResponse[] = [];
  expandirCardsMobile: boolean = false;
  counter = 0;
  timer: ReturnType<typeof setTimeout> | null = null;
  loader: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private toast: ToastrService,
    private httpService: HttpService,
    private permissionService: PermissionService,
    private cdDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getFrentesTrabalho();
    this.cdDetectorRef.detectChanges();
  }

  pesquisaParams = {
    TxNome: '',
    TxCpf: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
  };

  tableColumns = [
    { header: 'Nome', key: 'Descricao', type: 'text' },
    { header: 'Proprietário', key: 'Proprietario', type: 'text' },
    { header: 'Encarregado', key: 'Encarregado', type: 'text' },
    { header: 'Capacidade', key: 'Capacidade', type: 'text' },
    { header: 'Qtd. Pessoas', key: 'QuantidadeReed', type: 'text' },
    { header: 'Cidade', key: 'Cidade', type: 'text' },
    { header: 'Telefone', key: 'Telefone', type: 'text' },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Associar',
          img: 'assets/images/user-plus.svg',
          action: (item: any) => this.associarFrenteDeTrabalho(item),
          condition: (item: any) => true,
        },
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          label: 'Deletar',
          img: 'assets/images/del.svg',
          action: (item: any) => this.deleteItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    { header: 'Nome', key: 'Descricao', type: 'text', default: true },
    {
      header: 'Proprietário',
      key: 'Proprietario',
      type: 'text',
      default: true,
    },
    { header: 'Encarregado', key: 'Encarregado', type: 'text' },
    { header: 'Capacidade', key: 'Capacidade', type: 'text' },
    { header: 'Qtd. Pessoas', key: 'QuantidadeReed', type: 'text' },
    { header: 'Cidade', key: 'Cidade', type: 'text' },
    { header: 'Telefone', key: 'Telefone', type: 'text' },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Associar',
          img: 'assets/images/user-plus.svg',
          action: (item: any) => this.associarFrenteDeTrabalho(item),
          condition: (item: any) => true,
        },
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          label: 'Deletar',
          img: 'assets/images/del.svg',
          action: (item: any) => this.deleteItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  getFrentesTrabalho() {
    this.httpService.useGet('FrenteTrabalhoByFilter').subscribe(
      (response: any) => {
        const data = response.DataSet;

        const area: any = {
          0: 'Elétrica',
          1: 'Construção',
          2: 'Alimentícia',
        };

        this.tableData = data.map(
          (item: IGetListagemFrentesTrabalhoResponse) =>
            ({
              Id: item.Id,
              Descricao: item.Descricao,
              Proprietario: item.Proprietario,
              Encarregado: item.Encarregado,
              Capacidade: item.Capacidade,
              QuantidadeReed: item.QuantidadeReed,
              Cidade: item.Cidade || 'Não informado',
              Telefone: formatTel(item.Telefone) || 'Não informado',
            } as any)
        );
      },
      (error) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      }
    );
  }

  editItem(item: any) {
    if (item) {
      this.router.navigate(['recursos-humanos/frente-de-trabalho/cadastrar'], {
        queryParams: {
          isMode: 2,
          IdFrenteTrabalho: item.Id,
        },
      });
    } else {
      Swal.fire({
        title: 'Atenção!',
        text: 'Pessoa selecionado não encontrado.',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }

  deleteItem(item: any) {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realizar a exclusão da frente de trabalho?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: 'green',
      cancelButtonText: 'Não',
      cancelButtonColor: 'red',
    }).then((result) => {
      if (result.isConfirmed) {
        const IdFrenteTrabalho = item.Id;

        this.httpService
          .useDelete('DeleteFrenteTrabalho', IdFrenteTrabalho)
          .add(() => {
            this.getFrentesTrabalho();
          });
      }
    });
  }

  associarFrenteDeTrabalho(item: any) {
    this.router.navigate(['recursos-humanos/frente-de-trabalho/associar'], {
      queryParams: {
        IdFrenteTrabalho: item.Id,
      },
    });
  }

  navegarCadastrarFrenteDeTrabalho() {
    this.router.navigate(['recursos-humanos/frente-de-trabalho/cadastrar']);
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getFrentesTrabalho();
  }

  onChangeFilter(event: any) {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.counter++;

      this.getFrentesTrabalho();
    }, 250);
  }
}
