<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo
        [text]="this.editando ? 'Editar licença' : 'Registrar licença'"
      ></app-titulo>
    </div>
    <form #cadastroDisciplina="ngForm" class="form">
      <app-input
        type="select"
        width="100%"
        label="Nome da pessoa"
        name="nomePessoa"
        [(ngModel)]="form.PessoaId"
        (ngModelChange)="onSelectChange($event, 'pessoa')"
        [selectData]="pessoaOptions"
        [disabled]="editando"
      ></app-input>
      <app-input
        type="select"
        width="100%"
        label="Tipo licença"
        name="tipoLicenca"
        [(ngModel)]="form.LicencaId"
        (ngModelChange)="onSelectChange($event, 'licenca')"
        [selectData]="licencaOptions"
        [disabled]="editando"
      ></app-input>
      <app-input
        type="date-hour"
        width="100%"
        label="Data inicial"
        name="dataInicial"
        [(ngModel)]="form.DtInicio"
        (ngModelChange)="setDiasLicenca(this.form.LicencaId)"
      ></app-input>
      <app-input
        type="date-hour"
        width="100%"
        label="Data de término"
        name="dataTermino"
        [(ngModel)]="form.DtFim"
        [disabled]="true"
      ></app-input>
      <app-drag-drop
        label="Documento da licença"
        text="Arraste para enviar a foto"
        name="anexarArquivo"
        class="grid-2fr"
        acceptedFileType=".jpg,image/jpeg"
        (fileDropped)="onFileDropped('fotoLicenca', $event)"
        *ngIf="!editando"
      ></app-drag-drop>
    </form>
    <div class="form-button">
      <app-botao
        width="100%"
        text="Salvar"
        (click)="cadastrarLicenca()"
      ></app-botao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
