import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gestao-perfis',
  templateUrl: './gestao-perfis.component.html',
  styleUrl: './gestao-perfis.component.scss',
})
export class GestaoPerfisComponent {
  ngOnInit(): void {}

  constructor(private router: Router, private toast: ToastrService) {}
}
