import { z, ZodError, ZodIssue } from "zod";

export const imagemProjetoDisciplinaSchema = z.object({
  IdImagemProjetoDisciplina: z.number().optional(),
  TxImagemProjetoDisciplina: z.string({ message: 'Insira a imagem das disciplinas' }).min(1, { message: 'Insira a imagem da disciplina' }),
  NmImagemProjetoDisciplina: z.string({ message: 'Insira o nome da imagem da disciplina.' }).min(1, { message: 'Insira o nome da imagem da disciplina.' }),
}, { message: 'Insira a imagem das disciplinas selecionadas.'})

export const schemaDisciplina = z.object({
  IdDisciplina: z.number({ message: 'Estranho, parece que está faltando alguma coisa na sua disciplina. '}),
  TxNomeDisciplina: z.string({ message: 'Preencha o nome da disciplina' }),
  TxResponsavelDisciplina: z.string({ message: 'Preencha o responsável da disciplina'}),
  ImagemDisciplina: imagemProjetoDisciplinaSchema.optional(),
});

export const imagemProjetoSchema = z.object({
  IdImagemProjeto: z.number().optional(),
  TxImagemProjeto: z.string({ message: 'Insira a imagem do projeto.' }).min(1, { message: 'Insira a imagem do projeto.' }),
  NmImagemProjeto: z.string({ message: 'Insira o nome da imagem do projeto.'}).min(1, { message: 'Insira o nome da imagem do projeto.'})
}, { message: 'Insira a imagem do projeto.'})

export const schema = z.object({
  ProjetoId: z.number().optional(),
  NmProjeto: z.string({ message: "Preencha o nome do projeto."}).min(1, { message: "Preencha o nome do projeto."}),
  FuncionarioId: z.number({ message: "Preencha o responsável pelo projeto."}).refine((n) => n !== 0, { message: "Preencha o responsável pelo projeto."}),
  ObraId: z.number().optional(),
  FrenteTrabalhoId: z.number().optional(),
  DtInicio: z.string({ message: "Preencha a data de inicio do projeto." }).min(6, { message: "Preencha a data de inicio do projeto." }),
  DtTermino: z.string({ message: "Preencha a data de termino do projeto." }).min(6, { message: "Preencha a data de termino do projeto." }),
  disciplinaList: z.array(schemaDisciplina, { message: 'Preencha pelo menos uma disciplina.'}).min(1, { message: 'Preencha pelo menos uma disciplina.' }),
  imagemProjeto: imagemProjetoSchema.optional(),
}).refine((data) => {
  if(data.FrenteTrabalhoId === 0 && data.ObraId === 0)
  {
    const issue: ZodIssue = {
      message: 'Preencha pelo menos a obra ou a fábrica',
      code: 'custom',
      path: ['']
    }
    throw new ZodError([issue]);
  } else {
    return data;
  }
})

export type IImagemProjetoDisciplina = z.infer<typeof imagemProjetoDisciplinaSchema>;

export type IImagemProjeto = z.infer<typeof imagemProjetoSchema>;

export type ICadastrarProjetoForm = z.infer<typeof schema>;

export type IDisciplinasList = z.infer<typeof schemaDisciplina>;

export interface ProjetoResponse {
    Projeto: Projeto
}

export interface Projeto {
  IdProjeto: number
  FuncionarioId: number
  ObraId?: number
  FrenteTrabalhoId?: number
  NmProjeto: string
  DtInicio: string
  DtTermino: string
  Disciplinas: Disciplina[]
  RevisoesProjeto: RevisoesProjeto[]
}

export interface Disciplina {
  IdProjetoDisciplina: number
  DisciplinaId: number
  RevisoesProjetoDisciplina: RevisoesProjetoDisciplina[]
}

export interface RevisoesProjetoDisciplina {
  IdRevisaoProjetoDisciplina: number
  NuRevisao: number
  TxSituacao: string
  DtRevisao?: Date
  FuncionarioRevsId?: number
  Imagem: ImagemDisciplina
}

export interface ImagemDisciplina {
  IdImagemRevisaoProjetoDisciplina: number
  RevisaoProjetoDisciplinaId: number
  TxImagemRevisaoProjetoDisciplina: string
  NmImagemRevisaoProjetoDisciplina: string
}

export interface RevisoesProjeto {
  IdRevisaoProjeto: number
  NuRevisao: number
  TxSituacao: string
  DtRevisao?: Date
  FuncionarioRevsId?: number
  Imagem: ImagemProjeto
  NmImagemRevisaoProjeto: string;
}

export interface ImagemProjeto {
  IdImagemRevisaoProjeto: number
  RevisaoProjetoId: number
  TxImagemRevisaoProjeto: string
  NmImagemRevisaoProjeto: string
}
