import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NovaSenhaComponent } from '../../layout/nova-senha/nova-senha/nova-senha.component';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login | BUILTUP' },
  },
  {
    path: 'novaSenha',
    component: NovaSenhaComponent,
    data: { title: 'Nova Senha | BUILTUP' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
