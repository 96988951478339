import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pedido-material',
  templateUrl: './pedido-material.component.html',
  styleUrls: ['./pedido-material.component.scss'],
})
export class PedidoMaterialComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
