import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { IExtracaoRelatorioForm } from './extracao-relatorio-layout.model';
import { ToastrService } from 'ngx-toastr';
import { AppExportBase64 } from '../../../services/exportBase64/exportBase64.service';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';
@Component({
  selector: 'app-extracao-relatorio-layout',
  templateUrl: './extracao-relatorio-layout.component.html',
  styleUrls: ['./extracao-relatorio-layout.component.scss'],
})
export class ExtracaoRelatorioLayoutComponent implements OnInit {
  @Output() inputClose = new EventEmitter<boolean>();
  visible: boolean = true;
  loader: boolean = false;

  constructor(
    private httpService: HttpService,
    private exportBase64Service: AppExportBase64,
    private toast: ToastrService,
    private permissionService: PermissionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permissionService.getPermission();
    this.populateAnoOptions();
    this.visible = true;
    this.changeDetectorRef.detectChanges();
  }

  form: IExtracaoRelatorioForm = {
    mes: 0,
    ano: 0,
  };

  mesOptions = [
    { name: 'Janeiro', value: 1 },
    { name: 'Fevereiro', value: 2 },
    { name: 'Março', value: 3 },
    { name: 'Abril', value: 4 },
    { name: 'Maio', value: 5 },
    { name: 'Junho', value: 6 },
    { name: 'Julho', value: 7 },
    { name: 'Agosto', value: 8 },
    { name: 'Setembro', value: 9 },
    { name: 'Outubro', value: 10 },
    { name: 'Novembro', value: 11 },
    { name: 'Dezembro', value: 12 },
  ];

  anoOptions: any[] = [];

  populateAnoOptions() {
    const startYear = 2024;
    const currentYear = new Date().getFullYear();
    for (let year = startYear; year <= currentYear; year++) {
      this.anoOptions.push({ name: year.toString(), value: year });
    }
  }

  extrairRelatorio() {
    this.loader = true;
    const body = {
      Mes: this.form.mes,
      Ano: this.form.ano,
    };

    this.httpService.usePostFilter('ApontamentoMensalXlsx', body).subscribe({
      next: (response: any) => {
        this.exportBase64Service.downloadExcel(
          response.DataSet,
          'apontamento-hora'
        );

        this.loader = false;
        this.inputClose.emit(true);
      },
      error: (_error) => {
        this.toast.error('Ocorreu um erro ao exportar excel. Tente novamente!');

        this.loader = false;
      },
    });
  }
}
