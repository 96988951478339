import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

type TituloTypes = 'principal';

@Component({
  selector: 'app-titulo',
  templateUrl: './titulo.component.html',
  styleUrl: './titulo.component.scss',
})
export class TituloComponent implements OnInit {
  @Input() type: TituloTypes = 'principal';
  @Input() text: string = '';
  @Input() fontSize: string = '';
  @Input() fontWeight: string = '';

  ngOnInit(): void {}
  constructor(private router: Router) {}
}
