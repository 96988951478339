import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { LoginRoutingModule } from './login-routing.module';
import { LayoutModule } from '../../layout/layout.module';

@NgModule({
  declarations: [LoginComponent],
  imports: [LoginRoutingModule, LayoutModule],
  exports: [],
})
export class LoginModule {}
