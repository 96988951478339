<header>
  <div class="user">
    <div class="user-photo">
      <img src="assets/images/profile.svg" />
    </div>
    <div class="user-info">
      <span class="user-name">{{ getNome() }}</span>
      <div class="user-sub-info">
        <span class="user-role">{{ getCargo() }}</span>
        <span class="logout" (click)="logout()">
          <img src="assets/images/logout-v2.svg" />
          <span>Sair</span>
        </span>
      </div>
    </div>
  </div>
</header>
