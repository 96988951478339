import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listagem-epi',
  templateUrl: './listagem-epi.component.html',
  styleUrls: ['./listagem-epi.component.scss'],
})
export class ListagemEpiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
