<app-menu>
  <div class="desktop">
    <div class="header">
      <app-titulo text="Acréscimo"></app-titulo>
    </div>
    <div class="table-header">
      <h1 class="table-title">Acréscimo</h1>
    </div>
    <app-tabela [data]="tableData" [columns]="tableColumns"></app-tabela>
    <app-paginacao
      [pages]="pesquisaParams.MaxPages"
      (selectedPage)="mudarPagina($event)"
    ></app-paginacao>
  </div>

  <div class="mobile">
    <app-titulo text="Acréscimo"></app-titulo>
    <div class="listagem">
      <div
        class="menu-mobile"
        (click)="expandirCardsMobile = !expandirCardsMobile"
      >
        <app-titulo class="title" text="Acréscimo"></app-titulo>
        <img
          class="expandir-icon"
          src="assets/images/arrow-down.svg"
          *ngIf="!expandirCardsMobile"
        />
        <img
          class="expandir-icon"
          src="assets/images/arrow-up.svg"
          *ngIf="expandirCardsMobile"
        />
      </div>
      <app-card-mobile
        [data]="tableData"
        [rows]="rowsMobile"
        [expand]="expandirCardsMobile"
      ></app-card-mobile>
      <app-paginacao
        [pages]="pesquisaParams.MaxPages"
        (selectedPage)="mudarPagina($event)"
      ></app-paginacao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="this.loader"></app-loader>
