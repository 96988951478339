<div class="graph">
  <div class="circle">
    <p>{{ this.totalValue }}</p>
    <span>{{ this.totalText }}</span>
  </div>
  <div class="texts">
    <p class="title">{{ title }}</p>
    <div class="description">
      <p>
        {{ this.description }}: <span>{{ this.descriptionValue }}</span>
      </p>
    </div>
    <div *ngIf="this.description2" class="description">
      <p>
        {{ this.description2 }}: <span>{{ this.description2Value }}</span>
      </p>
    </div>
  </div>
</div>
