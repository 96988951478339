import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent {
  openMenu: boolean = false;
  listMenu: any = [
    {
      modulo: 'Recursos Humanos',
      open: false,
      src: 'assets/images/gestao.svg',
      listSubmenu: [
        {
          menu: 'Dashboard',
          src: 'bx bxs-dashboard',
          route: 'recursos-humanos/dashboard',
          selecionado: false,
          manutencao: false,
        },
        {
          menu: 'Pessoas',
          src: 'bx bx-user',
          route: 'recursos-humanos/pessoas',
          manutencao: false,
          selecionado: false,
        },
        {
          menu: 'Frentes de Trabalho',
          src: 'bx bx-building',
          route: 'recursos-humanos/frente-de-trabalho',
          selecionado: false,
          manutencao: false,
        },
        {
          menu: 'Licenças',
          src: 'bx bx-detail',
          route: 'recursos-humanos/licencas',
          selecionado: false,
          manutencao: false,
        },
        {
          menu: 'Acréscimo',
          src: 'bx bx-time',
          route: 'recursos-humanos/acrescimo',
          selecionado: false,
          manutencao: false,
        },
        {
          menu: 'Acessos',
          src: 'bx bx-check-shield',
          route: 'recursos-humanos/gestao-de-acesso',
          selecionado: false,
        },
        {
          menu: 'Perfis',
          src: 'bx bx-user-circle',
          route: 'recursos-humanos/gestao-de-perfis',
          selecionado: false,
        },
      ],
    },
    {
      modulo: 'Encarregado',
      open: false,
      src: 'assets/images/engenharia.svg',
      listSubmenu: [
        {
          menu: 'Dashboard',
          src: 'bx bxs-dashboard',
          route: 'encarregado/dashboard',
          selecionado: false,
        },
        {
          menu: 'Apontamentos',
          src: 'bx bx-calendar-check',
          route: 'encarregado/apontamentos',
          selecionado: false,
        },
        {
          menu: 'Ponto',
          src: 'bx bx-camera',
          route: 'encarregado/ponto',
          selecionado: false,
        },
        {
          menu: 'EPIs',
          src: 'bx bx-hard-hat',
          route: 'encarregado/listagem-epi',
          selecionado: false,
          manutencao: false,
        },
      ],
    },
    {
      modulo: 'Engenharia',
      open: false,
      src: 'assets/images/gear.svg',
      listSubmenu: [
        {
          menu: 'Engenharia',
          src: 'bx bx-hard-hat',
          route: 'engenharia',
          selecionado: false,
        },
        {
          menu: 'Projetos',
          src: 'bx bx-book-content',
          route: 'engenharia/projetos',
          selecionado: false,
        },
        {
          menu: 'Disciplinas',
          src: 'bx bx-briefcase-alt',
          route: 'engenharia/disciplinas',
          selecionado: false,
        },
      ],
    },
    {
      modulo: 'LOGs',
      open: false,
      src: 'assets/images/logs.svg',
      listSubmenu: [
        {
          menu: 'Apontamentos',
          src: 'bx bx-calendar-check',
          route: 'logs/apontamentos',
          selecionado: false,
        },
      ],
    },
    // {
    //   modulo: 'Suprimentos',
    //   open: false,
    //   src: 'assets/images/suprimento.svg',
    //   listSubmenu: [
    //     {
    //       menu: 'Suprimentos',
    //       src: 'bx bx-box',
    //       route: 'suprimentos',
    //       selecionado: false,
    //     },
    //     {
    //       menu: 'Fornecedor',
    //       src: 'bx bx-store-alt',
    //       route: 'suprimentos/fornecedor',
    //       selecionado: false,
    //     },
    //     {
    //       menu: 'Pedido',
    //       src: 'bx bx-cart',
    //       route: 'suprimentos/pedido',
    //       selecionado: false,
    //     },
    //   ],
    // },
    // {
    //   modulo: 'Planejamento',
    //   open: false,
    //   src: 'assets/images/planning.svg',
    //   listSubmenu: [
    //     {
    //       menu: 'Planejamento',
    //       src: 'bx bx-notepad',
    //       route: 'planejamento',
    //       selecionado: false,
    //     },
    //     {
    //       menu: 'Diário de Obra',
    //       src: 'bx bx-book',
    //       route: 'planejamento/diario',
    //       selecionado: false,
    //     },
    //   ],
    // },
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.verificaRota();
  }

  verificaRota() {
    const urlAtual = this.router.url;
    if (urlAtual != '/manutencao') {
      this.listMenu?.map((y: any) => {
        y?.listSubmenu.map((x: any) => {
          if (x.route == urlAtual) {
            x.selecionado = true;
            y.open = true;
          }
        });
      });
    }
  }

  selecionarMenu(event: any) {
    this.listMenu?.map((x: any) => {
      if (x.modulo == event.modulo) {
        x.open = !x.open;
      } else {
        x.open = false;
      }
    });
  }

  navegar(event: any) {
    this.listMenu?.map((y: any) => {
      y.listSubmenu?.map((x: any) => {
        if (x.menu == event.menu) {
          x.selecionado = true;
        } else {
          x.selecionado = false;
        }
      });
    });
    this.router.navigate([event.route]);
  }

  openAndCloseMenu() {
    this.openMenu = !this.openMenu;
    if (this.openMenu == false) {
      this.listMenu.map((x: any) => {
        x.open = false;
      });
    }
  }

  navegarHome() {
    this.router.navigate(['home']);
  }
}
