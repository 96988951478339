import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-associar-frente-trabalho',
  templateUrl: './associar-frente-trabalho.component.html',
  styleUrls: ['./associar-frente-trabalho.component.scss'],
})
export class AssociarFrenteTrabalhoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
