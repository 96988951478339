import { NgModule } from '@angular/core';
import { LayoutModule } from '../../layout/layout.module';
import { ComponentsModule } from '../../components/components.module';
import { LogsRoutingModule } from './logs-routing.module';
import { LogApontamentosComponent } from './log-apontamentos/log-apontamentos/log-apontamentos.component';

@NgModule({
  declarations: [LogApontamentosComponent],
  imports: [LogsRoutingModule, ComponentsModule, LayoutModule],
  exports: [],
})
export class LogsModule {}
