import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suprimentos',
  templateUrl: './suprimentos.component.html',
  styleUrls: ['./suprimentos.component.scss'],
})
export class SuprimentosComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
