import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { HttpService } from '../../../services/common/http/common.http.service';
import { PermissionService } from '../../../services/permission.service';
import { IAcrescimo } from './acrescimo-layout.model';

@Component({
  selector: 'app-acrescimo-layout',
  templateUrl: './acrescimo-layout.component.html',
  styleUrls: ['./acrescimo-layout.component.scss'],
})
export class AcrescimoLayoutComponent implements OnInit {
  tableData: IAcrescimo[] = [];
  expandirCardsMobile: boolean = false;
  loader: boolean = false;

  pesquisaParams = {
    TxPesquisar: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
  };

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permissionService.getPermission();
    this.getAcrescimos();
    this.changeDetectorRef.detectChanges();
  }

  tableColumns = [
    { header: 'Tipo de acréscimo', key: 'TxDescricao', type: 'text' },
    { header: 'Percentual', key: 'VlPercentual', type: 'text' },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    { header: 'Tipo de acréscimo', key: 'TxDescricao', type: 'text' },
    { header: 'Percentual', key: 'VlPercentual', type: 'text' },
    {
      header: 'Ações',
      key: 'acoes',
      type: 'buttons',
      buttons: [
        {
          img: 'assets/images/edit.svg',
          label: 'Editar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  getAcrescimos(page?: number) {
    this.loader = true;

    this.httpService.useGet('Acrescimo').subscribe({
      next: (response: any) => {
        const data = response;

        this.tableData = data.map((item: IAcrescimo) => {
          return {
            IdAcrescimo: item.IdAcrescimo,
            TxDescricao: item.TxDescricao,
            VlPercentual: item.VlPercentual + '%',
          };
        });

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error(
          'Não foi possível buscar as disciplinas. Tente novamente!'
        );
      },
    });

    this.loader = false;
  }

  onChangeSearch(search: string) {
    this.pesquisaParams.TxPesquisar = search;
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getAcrescimos();
  }

  editItem(item: any) {
    const acrescimoItem = this.tableData.find(
      (x: any) => x.IdAcrescimo === item.IdAcrescimo
    );

    if (acrescimoItem) {
      this.router.navigate(['recursos-humanos/editar-acrescimo'], {
        queryParams: {
          IdAcrescimo: acrescimoItem.IdAcrescimo,
          Editando: true,
        },
      });
    } else {
      Swal.fire({
        title: 'Atenção!',
        text: 'Acréscimo não encontrado.',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }

  deleteItem(item: any) {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realizar a exclusão da disciplina?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: 'green',
      confirmButtonText: 'Sim',
      cancelButtonColor: 'red',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loader = true;
        const idDisciplina = item.IdDisciplina;

        this.httpService
          .useDelete('DisciplinaDelete', { idDisciplina })
          .add(() => {
            this.getAcrescimos();
            this.loader = false;
          });
      }
    });
  }

  navegarRegistrarLicenca() {
    this.router.navigate(['recursos-humanos/registrar-licenca']);
  }
}
