import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadastrar-disciplina',
  templateUrl: './cadastrar-disciplina.component.html',
  styleUrls: ['./cadastrar-disciplina.component.scss'],
})
export class CadastrarDisciplinaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
