<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo text="Validar ponto"></app-titulo>
      <h2 class="subtitle">Escaneie seu rosto para validar o ponto</h2>
    </div>
    <div class="escanear">
      <img src="assets/images/pessoa-face.png" *ngIf="!habilitarCamera" />
      <div
        [ngStyle]="{ display: habilitarCamera ? 'flex' : 'none' }"
        class="video-container"
      >
        <div class="video-wrapper">
          <video #video playsinline autoplay></video>
          <canvas #canvas></canvas>
          <canvas #faceCanvas id="faceCanvas" class="face-canvas"></canvas>
        </div>
        <img class="overlay" src="assets/images/borda-reconhecimento.png" />
        <app-texto-reconhecimento
          [textos]="feedbackMessages"
        ></app-texto-reconhecimento>
      </div>
      <p class="text-main">
        Coloque seu rosto no centro da câmera para validar o registro do ponto.
      </p>

      <div class="buttons">
        <div *ngIf="!this.habilitarCamera">
          <app-botao
            [icon]="
              habilitarCamera
                ? 'assets/images/block-regular-36.png'
                : 'assets/images/webcam-regular-36.png'
            "
            text="Habilitar Câmera"
            (click)="startCamera()"
            width="500px"
            *ngIf="this.permission === 0"
          >
          </app-botao>
        </div>
      </div>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader" />
