import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ViaCepService {
  constructor(private http: HttpClient) {}

  public GetBuscarCep(cep: string, { onSuccess, onError }: any) {
    this.http.get(`https://viacep.com.br/ws/${cep}/json/`).subscribe({
      next: (response) => {
        return onSuccess(response);
      },
      error: onError,
    });
  }
}
