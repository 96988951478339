import { z } from "zod";

export const DisiciplinaSchema = z.object({
  IdDisciplina: z.number().optional(),
  NomeDisciplina: z.string({ message: 'Preencha o nome da disciplina.' }).min(4, { message: 'Preencha o nome da disciplina.' }),
  FuncionarioId: z.number({ message: 'Preencha o responsável pela disciplina.' }).refine(n => n !== 0, { message: 'Preencha o responsável pela disciplina.'}),
  Nome: z.string().optional(),
  TxTelefone: z.string().optional(),
});

export type ICadastrarDisciplinaForm = z.infer<typeof DisiciplinaSchema>

export interface IResponsavelOptions {
  value: number;
  name: string;
  telefone?: string;
}
