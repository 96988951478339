<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo text="Cadastrar EPI"></app-titulo>
    </div>
    <form class="form">
      <app-input
        type="text"
        label="Equipamento"
        name="equipamento"
        required="true"
        [(ngModel)]="form.equipamento"
        [requiredInput]="true"
      />
      <app-input
        label="Área"
        type="select"
        name="area"
        required="true"
        [selectData]="mockedSelectData2"
        [(ngModel)]="form.area"
        [requiredInput]="true"
      />
      <app-input
        type="number"
        label="Prazo de troca (dias)"
        name="prazoTroca"
        required="true"
        [(ngModel)]="form.prazoTroca"
        [requiredInput]="true"
      ></app-input>
      <app-input
        label="Quantidade"
        name="quantidade"
        required="true"
        [(ngModel)]="form.quantidade"
        [requiredInput]="true"
      ></app-input>
    </form>
    <app-botao
      type="primario"
      text="Cadastrar EPI"
      (click)="registrarEpi()"
      *ngIf="this.permission === 0"
    ></app-botao>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
