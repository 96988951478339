import { Component, OnInit } from '@angular/core';
import { IFornecedorForm } from './pedido-material-layout.model';

@Component({
  selector: 'app-pedido-material-layout',
  templateUrl: './pedido-material-layout.component.html',
  styleUrls: ['./pedido-material-layout.component.scss'],
})
export class PedidoMaterialLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  form: IFornecedorForm = {
    nome: '',
    email: '',
    telefone: '',
    endereco: '',
    precoFrete: 0,
    previsaoEntrega: '',
    materiais: [],
  };

  mockedUsuarios = [
    {
      name: 'Higor Santos',
      value: '1',
      cpf: '000.000.000-00',
    },
    {
      name: 'Matheus Batista',
      value: '2',
      cpf: '111.111.111-11',
    },
  ];

  mockedFabricas = [
    {
      name: 'Fábrica 1',
      value: '1',
    },
    {
      name: 'Fábrica 2',
      value: '2',
    },
  ];

  mockedTurnos = [
    {
      name: 'Manhã',
      value: 'manha',
      horario: '07h às 16h',
    },
    {
      name: 'Tarde',
      value: 'tarde',
      horario: '12h às 21h',
    },
  ];

  escolherPessoa(value: any) {
    const usuario = this.mockedUsuarios.find(
      (usuario) => usuario?.value === value
    );
  }

  escolherTurno(value: any) {
    const turno = this.mockedTurnos.find((turno) => turno?.value === value);
  }

  associarPessoa() {}

  atualizarMateriais(materiais: any) {
    this.form.materiais = materiais;
  }
}
