<app-menu>
  <div class="desktop">
    <div class="header">
      <app-titulo text="Diário de obra"></app-titulo>
    </div>
    <div class="form">
      <app-input
        type="select"
        width="100%"
        label="Obra"
        name="obra"
        [(ngModel)]="form.obra"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Endereço"
        name="endereco"
        [(ngModel)]="form.endereco"
      ></app-input>
      <app-input
        type="date"
        width="100%"
        label="Início"
        name="inicio"
        [(ngModel)]="form.inicio"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Prazo"
        name="prazo"
        [(ngModel)]="form.prazo"
      ></app-input>
      <app-input
        type="select"
        width="100%"
        label="Responsável"
        name="responsavel"
        [(ngModel)]="form.responsavel"
      ></app-input>
      <app-input
        type="date"
        width="100%"
        placeholder="Data do diário"
        name="dataDiario"
        [(ngModel)]="form.dataDiario"
      ></app-input>
      <app-input
        type="textarea"
        width="100%"
        class="grid-2fr"
        label="Anotações do dia"
        name="anotacoes"
        [(ngModel)]="form.anotacoes"
      ></app-input>
      <app-drag-drop
        label="Envio de fotos"
        text="Arraste ou clique para enviar a foto"
      ></app-drag-drop>
    </div>
    <div class="form-button">
      <app-botao
        width="100%"
        text="Cadastrar fornecedor"
        (click)="associarPessoa()"
      ></app-botao>
    </div>
  </div>
</app-menu>
