<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo text="Associar EPI"></app-titulo>
    </div>
    <form #frenteDeTrabalhoForm="ngForm">
      <app-input
        type="text"
        label="Equipamento"
        name="material"
        [(ngModel)]="form.material"
        [disabled]="true"
      ></app-input>
      <app-input
        type="text"
        label="Área"
        name="area"
        [(ngModel)]="form.area"
        [disabled]="true"
      ></app-input>
      <app-select-check
        label="Pessoa(s)"
        name="pessoa"
        [selectData]="pessoaOptions"
        [(ngModel)]="form.pessoas"
        (ngModelChange)="changeSelect($event)"
        [requiredInput]="true"
      ></app-select-check>
      <div>
        <label *ngIf="this.form.pessoas.length === 1"
          >Pessoa selecionado ({{ this.form.pessoas.length }})</label
        >
        <label *ngIf="this.form.pessoas.length > 1"
          >Pessoas selecionados ({{ this.form.pessoas.length }})</label
        >
        <ul class="pessoa-list">
          <li *ngFor="let pessoa of pessoaList">
            {{ pessoa }}
          </li>
        </ul>
      </div>
    </form>
    <app-botao
      text="Associar"
      class="grid-2fr"
      (click)="associarEpi()"
      *ngIf="this.permission === 0"
    ></app-botao>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
