<app-menu>
  <div class="desktop">
    <div class="header">
      <app-titulo text="Termos de risco"></app-titulo>
    </div>
    <div class="body">
      <div class="pdf">
        <pdf-viewer
          [src]="pdfSrc"
          [rotation]="0"
          [original-size]="false"
          [show-all]="true"
          [fit-to-page]="false"
          [zoom]="1"
          [zoom-scale]="'page-width'"
          [stick-to-page]="false"
          [render-text]="true"
          [external-link-target]="'blank'"
          [autoresize]="true"
          [show-borders]="false"
        ></pdf-viewer>
      </div>
      <div class="terms-acceptance">
        <input
          type="checkbox"
          id="acceptTerms"
          [(ngModel)]="this.termosAceitos"
        />
        <label for="acceptTerms">Estou ciente e aceito os termos</label>
      </div>
      <app-botao
        width="100%"
        text="Aceitar"
        [disabled]="!termosAceitos"
        (click)="aceitarTermos()"
      ></app-botao>
    </div>
  </div>
</app-menu>
