import { Component } from '@angular/core';
import { ILogin } from './login.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  authService: any;
  ngOnInit(): void {}

  constructor(
    private router: Router,
    private toast: ToastrService,
    private http: HttpClient
  ) {}

  login(ILogin: ILogin) {}
}
