import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadastrar-pessoa',
  templateUrl: './cadastrar-pessoa.component.html',
  styleUrls: ['./cadastrar-pessoa.component.scss'],
})
export class CadastrarPessoaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
