import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import {
  DataSet,
  IGetProjetosResponse,
  ProjetoGrid,
} from './projetos-layout.model';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';

@Component({
  selector: 'app-projetos-layout',
  templateUrl: './projetos-layout.component.html',
  styleUrls: ['./projetos-layout.component.scss'],
})
export class ProjetosLayoutComponent implements OnInit {
  tableData: ProjetoGrid[] = [];
  funcionarios: any = [];
  expandirCardsMobile: boolean = false;
  loader: boolean = false;

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permissionService.getPermission();
    this.getProjetos();
    this.changeDetectorRef.detectChanges();
  }

  pesquisaParams = {
    TxNomeProjeto: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
    FlValidar: false,
  };

  tableColumns = [
    { header: 'Nome do projeto', key: 'NmProjeto', type: 'text' },
    { header: 'Responsável', key: 'NmResponsavel', type: 'text' },
    {
      header: 'Qtd. Revisões',
      key: 'QuantidadeRevisoes',
      type: 'text',
    },
    {
      header: 'Revisar Projeto',
      key: 'revisoes',
      type: 'buttons',
      buttons: [
        {
          label: 'Revisar',
          action: (item: ProjetoGrid) => this.revisarProjeto(item),
          condition: (item: ProjetoGrid) => this.revisaoNavigate(item),
        },
      ],
    },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Editar',
          img: '/assets/images/edit.svg',
          action: (item: ProjetoGrid) => this.editItem(item),
          condition: (item: ProjetoGrid) => true,
        },
        {
          label: 'Deletar',
          img: '/assets/images/del.svg',
          action: (item: ProjetoGrid) => this.deleteItem(item),
          condition: (item: ProjetoGrid) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    {
      header: 'Nome do projeto',
      key: 'NmProjeto',
      type: 'text',
      default: true,
    },
    {
      header: 'Responsável',
      key: 'NmResponsavel',
      type: 'text',
      default: true,
    },
    {
      header: 'Qtd. Revisões',
      key: 'QuantidadeRevisoes',
      type: 'text',
    },
    {
      header: 'Revisões',
      key: 'revisoes',
      type: 'buttons',
      buttons: [
        {
          label: 'Revisar',
          action: (item: any) => this.revisarProjeto(item),
          condition: (item: any) => true,
        },
      ],
    },
    {
      header: 'Ações',
      key: 'acoes',
      type: 'buttons',
      buttons: [
        {
          img: 'assets/images/edit.svg',
          label: 'Editar',
          action: (item: ProjetoGrid) => this.editItem(item),
          condition: (item: ProjetoGrid) => true,
        },
        {
          img: 'assets/images/del.svg',
          label: 'Deletar',
          action: (item: ProjetoGrid) => this.deleteItem(item),
          condition: (item: ProjetoGrid) => true,
        },
      ],
    },
  ];

  getProjetos() {
    this.loader = true;
    const params = this.pesquisaParams;

    this.httpService.useGet('ProjetoByFilter', params).subscribe({
      next: (response: IGetProjetosResponse) => {
        this.tableData = response.DataSet.map((item: DataSet) => {
          return {
            DtInicio: item.Projeto.DtInicio,
            DtTermino: item.Projeto.DtTermino,
            FrenteTrabalhoId: item.Projeto.FrenteTrabalhoId,
            FuncionarioId: item.Funcionario.IdFuncionario,
            IdProjeto: item.Projeto.IdProjeto,
            ObraId: item.Projeto.ObraId,
            QuantidadeRevisoes: item.QuantidadeRevisoes,
            NmProjeto: item.Projeto.NmProjeto ?? 'Não Informado',
            NmResponsavel:
              item.Funcionario.TxNomeFuncionario ?? 'Não Informado',
            StatusProjeto: item.TxSituacao,
          } as ProjetoGrid;
        });
        this.pesquisaParams.MaxPages =
          Math.ceil(response.Total / this.pesquisaParams.TamanhoPagina) || 1;

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error(
          'Não foi possível buscar os projetos. Tente novamente!'
        );
      },
    });
  }

  revisaoNavigate(item: ProjetoGrid): boolean {
    if (!item.StatusProjeto.includes('RECUSADO')) {
      return false;
    }
    return true;
  }

  revisarProjeto(item: ProjetoGrid) {
    this.router.navigate(['engenharia/revisao'], {
      queryParams: {
        IdProjeto: item.IdProjeto,
      },
    });
  }

  editItem(item: ProjetoGrid) {
    const projetoItem = this.tableData.find(
      (x: any) => x.IdProjeto === item.IdProjeto
    );

    if (projetoItem) {
      this.router.navigate(['engenharia/cadastrar-projeto'], {
        queryParams: {
          FuncionarioId: projetoItem.FuncionarioId,
          IdProjeto: projetoItem.IdProjeto,
          NmProjeto: projetoItem.NmProjeto,
          FrenteTrabalhoId: projetoItem.FrenteTrabalhoId,
          ObraId: projetoItem.ObraId,
          DtInicio: projetoItem.DtInicio,
          DtTermino: projetoItem.DtTermino,
          Editando: true,
        },
      });
    } else {
      Swal.fire({
        title: 'Atenção!',
        text: 'Projeto não encontrado.',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }

  deleteItem(item: ProjetoGrid) {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realizar a exclusão do projeto?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: 'green',
      confirmButtonText: 'Sim',
      cancelButtonColor: 'red',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loader = true;

        const idProjeto = item.IdProjeto;
        this.httpService.useDelete('ProjetoDelete', { idProjeto }).add(() => {
          this.getProjetos();
          this.loader = false;
        });
      }
    });
  }

  navegarValidarProjetos() {
    this.router.navigate(['engenharia']);
  }

  navegarCadastrarProjeto() {
    this.router.navigate(['engenharia/cadastrar-projeto']);
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getProjetos();
  }
}
