<div class="container">
  <label>{{ label }}</label>
  <ngx-file-drop (onFileDrop)="dropped($event)">
    <ng-template ngx-file-drop-content-tmp>
      <div class="file-drop-zone" (click)="fileInput.click()">
        <img src="assets/images/upload.svg" />
        <p>{{ text }}</p>
      </div>
    </ng-template>
  </ngx-file-drop>
  <input
    #fileInput
    type="file"
    [accept]="acceptedFileType"
    style="display: none"
    (change)="onFileSelected($event)"
  />
  <div *ngIf="file" class="file-info-container">
    <div class="file-info">
      <img [src]="fileSrc" *ngIf="isImage" />
      <p>{{ file.name }}</p>
      <button (click)="removeFile()">&#10060;</button>
    </div>
  </div>
</div>
