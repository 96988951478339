import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../services/common/http/common.http.service';

@Component({
  selector: 'app-nova-senha',
  templateUrl: './nova-senha.component.html',
  styleUrl: './nova-senha.component.scss',
})
export class NovaSenhaComponent {
  form = {
    senha: '',
    novaSenha: '',
  };
  isLengthValid = false;
  hasNumber = false;
  hasLowercase = false;
  hasUppercase = false;
  hasSpecialChar = false;
  passwordsMatch = false;
  isValid = false;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private toast: ToastrService
  ) {}

  validatePassword() {
    const senha = this.form.senha;
    const novaSenha = this.form.novaSenha;

    this.isLengthValid = senha.length >= 8;
    this.hasNumber = /\d/.test(senha);
    this.hasLowercase = /[a-z]/.test(senha);
    this.hasUppercase = /[A-Z]/.test(senha);
    this.hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(senha);
    this.passwordsMatch = senha === novaSenha;

    if (
      this.isLengthValid &&
      this.hasNumber &&
      this.hasLowercase &&
      this.hasUppercase &&
      this.hasSpecialChar &&
      this.passwordsMatch
    ) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  definirNovaSenha() {
    if (this.isValid) {
      const payload = this.createPayload(this.form);

      this.httpService.usePut('AtualizarSenha', payload).add(() => {
        this.toast.success('Senha definida com sucesso.');
        this.router.navigate(['/login']);
      });
    }
  }

  createPayload(form: any) {
    const url = this.router.url;

    const [usuarioIdPart, tokenPart] = url.split(',');
    const usuarioId = usuarioIdPart.split(':')[1];
    const token = tokenPart.split(':')[1];

    localStorage.setItem('Token', String(`"${token}"`));

    const body = {
      IdUsuario: Number(usuarioId),
      Password: form.senha,
    };
    return body;
  }
}
