import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diario-obra',
  templateUrl: './diario-obra.component.html',
  styleUrls: ['./diario-obra.component.scss'],
})
export class DiarioObraComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
