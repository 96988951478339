import { RevisoesProjetoDisciplina } from './../cadastrar-projeto-layout/cadastrar-projeto-layout.model';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IRevisaoForm,
  IRevisoesProjetoDisciplina,
} from './revisao-layout.model';
import { PermissionService } from '../../../services/permission.service';
import { FileRead } from '../../../components/drag-drop-pdf/drag-drop-pdf.component.model';
import {
  Disciplina,
  ProjetoResponse,
  RevisoesProjeto,
} from '../cadastrar-projeto-layout/cadastrar-projeto-layout.model';
import { HttpService } from '../../../services/common/http/common.http.service';
import { AppExportBase64 } from '../../../services/exportBase64/exportBase64.service';

type IEtapas = 1 | 2;

@Component({
  selector: 'app-revisao-layout',
  templateUrl: './revisao-layout.component.html',
  styleUrls: ['./revisao-layout.component.scss'],
})
export class RevisaoLayoutComponent implements OnInit {
  etapa: IEtapas = 1;
  totalEtapas: number = 2;
  loader: boolean = false;
  title = 'Revisão de Projeto';

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private exportBase64Service: AppExportBase64,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permissionService.getPermission();
    this.activateRoute();
    this.getImagensProjeto();
    this.changeDetectorRef.detectChanges();
  }

  form: IRevisaoForm = {
    ProjetoId: null,
    ImagemProjeto: [],
    ImagensDisciplina: [],
    Imagens: {
      anexoDisciplinas: [],
    },
  };

  getImagensProjeto() {
    this.loader = true;
    const form = this.form;
    const params = {
      IdProjeto: form.ProjetoId,
      FlArquivo: true,
    };

    this.httpService.useGet('ProjetoGet', params).subscribe({
      next: ({ Projeto: data }: ProjetoResponse) => {
        data.RevisoesProjeto.sort((a, b) => b.NuRevisao - a.NuRevisao).forEach(
          (revs: RevisoesProjeto) => {
            this.form.ImagemProjeto?.push({
              IdImagemProjeto: revs.Imagem.IdImagemRevisaoProjeto,
              ProjetoId: data.IdProjeto,
              NuRevisao: revs.NuRevisao,
              TxSituacao: revs.TxSituacao,
              TxImagemProjeto: revs.Imagem.TxImagemRevisaoProjeto,
              NmProjeto: revs.Imagem.NmImagemRevisaoProjeto,
            });
          }
        );

        data.Disciplinas.map((disciplina: any) => {
          const novaImagemDisciplina = {
            IdImagemProjetoDisciplina:
              disciplina.IdImagemRevisaoProjetoDisciplina,
            ProjetoDisciplinaId: disciplina.IdProjetoDisciplina,
            NuRevisao: disciplina.NuRevisao,
            TxSituacao: disciplina.TxSituacao,
            TxImagemProjetoDisciplina:
              disciplina.TxImagemRevisaoProjetoDisciplina,
            RevisoesProjetoDisciplina: disciplina.RevisoesProjetoDisciplina.map(
              (revisao: IRevisoesProjetoDisciplina) => {
                return {
                  Imagem: {
                    NmImagemRevisaoProjetoDisciplina:
                      revisao.Imagem.NmImagemRevisaoProjetoDisciplina,
                    TxImagemRevisaoProjetoDisciplina:
                      revisao.Imagem.TxImagemRevisaoProjetoDisciplina,
                  },
                  NuRevisao: revisao.NuRevisao,
                  TxSituacao: revisao.TxSituacao,
                } as IRevisoesProjetoDisciplina;
              }
            ),
          };
          this.form.ImagensDisciplina?.push(novaImagemDisciplina);
        });

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
      },
    });
  }

  confirmarRevisao() {
    const payload = this.form.Imagens;

    this.httpService.usePost('ProjetoReview', payload);
  }

  proximaEtapa() {
    if (this.etapa < this.totalEtapas) {
      this.etapa++;
      this.changeDetectorRef.detectChanges();
    }
  }

  etapaAnterior() {
    if (this.etapa > 1) {
      this.etapa--;
      this.changeDetectorRef.detectChanges();
    }
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.form.ProjetoId = +params['IdProjeto'];
    });
  }

  downloadArquivo(item: any, type: string) {
    if (type === 'projeto') {
      const arquivo = item.TxImagemProjeto;
      this.exportBase64Service.downloadPDF(arquivo, 'Projeto');
    }

    if (type === 'disciplina') {
      const arquivo = item.TxImagemRevisaoProjetoDisciplina;
      this.exportBase64Service.downloadPDF(arquivo, 'Disciplina');
    }
  }

  onFileDropped(field: string, id: any, file: FileRead) {
    const formattedBase64 = file.base64.replace(
      /^data:application\/pdf;base64,/,
      ''
    );

    if (field === 'projeto') {
      this.form.Imagens = {
        ...this.form.Imagens,
        IdProjeto: this.form.ProjetoId || 0,
        anexoProjeto: {
          IdImagemProjeto: id,
          NmImagemProjeto: file.name,
          TxImagemProjeto: formattedBase64,
        },
      };
    }

    if (field === 'disciplina') {
      const indexDisciplina = this.form.Imagens.anexoDisciplinas.findIndex(
        (disciplina) => disciplina.IdDisciplina === id
      );

      if (indexDisciplina !== -1) {
        if (
          indexDisciplina >= 0 &&
          this.form.Imagens.anexoDisciplinas[indexDisciplina]
        ) {
          this.form.Imagens.anexoDisciplinas[indexDisciplina].ImagemDisciplina =
            {
              NmImagemRevisaoProjetoDisciplina: file.name,
              TxImagemRevisaoProjetoDisciplina: formattedBase64,
            };
        }
        return;
      }

      this.form.Imagens.anexoDisciplinas.push({
        IdDisciplina: id,
        ImagemDisciplina: {
          NmImagemRevisaoProjetoDisciplina: file.name,
          TxImagemRevisaoProjetoDisciplina: formattedBase64,
        },
      });
    }
  }
}
