import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppGuard } from '../../services/auth.guard';
import { LogApontamentosComponent } from './log-apontamentos/log-apontamentos/log-apontamentos.component';

const routes: Routes = [
  {
    path: 'logs/apontamentos',
    component: LogApontamentosComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuAdmin'],
      title: 'Log - Apontamentos | BUILTUP',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class LogsRoutingModule {}
