import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private http: HttpClient) {}

  funcionario = JSON.parse(localStorage.getItem('Funcionario') || '{}');

  atualizar(): Promise<void> {
    const token = localStorage.getItem('Token');

    return Promise.resolve();
  }
}
