import { z } from 'zod';

export const schema = z.object({
  motivoAdvertencia: z
    .number({ message: 'Preencha o campo motivo da advertência.' })
    .refine((n: any) => n !== null && n !== 0, {
      message: 'Preencha o campo motivo da advertência.',
    }),
  apontamentoId: z.number().refine((n: any) => n !== null && n !== 0),
  advertenciaId: z.number().optional(),
  turno: z
    .number({ message: 'Preencha o campo turno.' })
    .refine((n: any) => n !== null && n !== 0, {
      message: 'Preencha o campo turno.',
    }),
  descricaoAdvertencia: z.string().optional(),
  valorAdvertencia: z.number().optional(),
});

export interface IForms {
  nome: string;
  cpf: string;
  frenteTrabalho: string;
  horario: string;
  turno: number;
  motivoAdvertencia: number;
  descricaoAdvertencia: string;
  apontamentoId: number;
  advertenciaId: number;
  valorAdvertencia: number;
}
