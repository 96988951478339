<app-menu>
  <div class="desktop">
    <app-titulo text="Dashboard"></app-titulo>
    <app-titulo
      text="Recursos Humanos"
      fontSize="24px"
      fontWeight="600"
    ></app-titulo>
    <div class="graphs-cards">
      <app-grafico
        *ngFor="let graph of mockedGraphsData"
        [title]="graph.title"
        [description]="graph.description"
        [descriptionValue]="graph.descriptionValue"
        [description2]="graph.description2"
        [description2Value]="graph.description2Value"
        [totalValue]="graph.totalValue"
      ></app-grafico>
    </div>
    <div class="buttons">
      <app-botao
        width="100%"
        text="Cadastrar pessoa"
        icon="assets/images/add.svg"
        (click)="navegarCadastrarPessoa()"
        *ngIf="this.permission === 0"
      ></app-botao>
      <app-botao
        width="100%"
        text="Associar pessoa"
        icon="assets/images/add.svg"
        (click)="navegarAssociarPessoa()"
        *ngIf="this.permission === 0"
      ></app-botao>
      <app-botao
        width="100%"
        text="Visualizar EPIs"
        icon="assets/images/epi.svg"
        (click)="navegarVisualizarEpis()"
        *ngIf="this.permission === 0"
      ></app-botao>
      <app-botao
        type="secundario"
        width="100%"
        text="Exportar Excel"
        icon="assets/images/download-icon.svg"
        (click)="exportarExcel()"
      ></app-botao>
    </div>
    <div class="table-header">
      <h1 class="table-title">Listagem</h1>
      <div class="table-filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          [(ngModel)]="pesquisaParams.TxPesquisa"
          (ngModelChange)="onChangeFilter($event)"
        ></app-input>
        <app-input
          type="date"
          placeholder="Data inicial"
          [(ngModel)]="pesquisaParams.DtInicio"
          (ngModelChange)="onChangeFilter($event)"
        ></app-input>
        <app-input
          type="date"
          placeholder="Data final"
          [(ngModel)]="pesquisaParams.DtFim"
          (ngModelChange)="onChangeFilter($event)"
        ></app-input>
      </div>
    </div>
    <app-tabela [data]="tableData" [columns]="tableColumns"></app-tabela>
    <div class="paginacao">
      <app-paginacao
        [pages]="pesquisaParams.MaxPages"
        (selectedPage)="mudarPagina($event)"
      ></app-paginacao>
    </div>
  </div>

  <div class="mobile">
    <app-titulo text="Dashboard"></app-titulo>
    <div class="metricas">
      <div class="menu-mobile">
        <app-titulo text="Métricas"></app-titulo>
        <img
          *ngIf="!expandirMetricasMobile"
          (click)="this.expandirMetricasMobile = !this.expandirMetricasMobile"
          src="assets/images/arrow-down.svg"
          class="expandir-icon"
        />
        <img
          *ngIf="expandirMetricasMobile"
          (click)="this.expandirMetricasMobile = !this.expandirMetricasMobile"
          src="assets/images/arrow-up.svg"
          class="expandir-icon"
        />
      </div>
      <app-grafico
        *ngIf="!this.expandirMetricasMobile"
        [title]="mockedGraphsData[0].title"
        [description]="mockedGraphsData[0].description"
        [descriptionValue]="mockedGraphsData[0].descriptionValue"
        [description2]="mockedGraphsData[0].description2"
        [description2Value]="mockedGraphsData[0].description2Value"
        [totalValue]="mockedGraphsData[0].totalValue"
      ></app-grafico>
      <div class="graphs-cards" *ngIf="this.expandirMetricasMobile">
        <app-grafico
          *ngFor="let graph of mockedGraphsData"
          [title]="graph.title"
          [description]="graph.description"
          [descriptionValue]="graph.descriptionValue"
          [description2]="graph.description2"
          [description2Value]="graph.description2Value"
          [totalValue]="graph.totalValue"
        ></app-grafico>
      </div>
      <div class="buttons" *ngIf="this.expandirMetricasMobile">
        <app-botao
          width="100%"
          text="Cadastrar pessoa"
          icon="assets/images/add.svg"
          (click)="navegarCadastrarPessoa()"
          *ngIf="this.permission === 0"
        ></app-botao>
        <app-botao
          width="100%"
          text="Associar pessoa"
          icon="assets/images/add.svg"
          (click)="navegarAssociarPessoa()"
          *ngIf="this.permission === 0"
        ></app-botao>
        <app-botao
          width="100%"
          text="Visualizar EPIs"
          icon="assets/images/epi.svg"
          (click)="navegarVisualizarEpis()"
          *ngIf="this.permission === 0"
        ></app-botao>
      </div>
    </div>
    <div class="listagem">
      <div class="menu-mobile">
        <app-titulo text="Listagem"></app-titulo>
        <img
          *ngIf="!expandirCardsMobile"
          (click)="this.expandirCardsMobile = !this.expandirCardsMobile"
          src="assets/images/arrow-down.svg"
          class="expandir-icon"
        />
        <img
          *ngIf="expandirCardsMobile"
          (click)="this.expandirCardsMobile = !this.expandirCardsMobile"
          src="assets/images/arrow-up.svg"
          class="expandir-icon"
        />
      </div>
      <div class="table-filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          [(ngModel)]="pesquisaParams.TxPesquisa"
          (ngModelChange)="onChangeFilter($event)"
        ></app-input>
        <app-input
          *ngIf="this.expandirCardsMobile"
          placeholder="Data inicial"
          type="date"
          [(ngModel)]="pesquisaParams.DtInicio"
          (ngModelChange)="onChangeFilter($event)"
        ></app-input>
        <app-input
          *ngIf="this.expandirCardsMobile"
          placeholder="Data final"
          type="date"
          [(ngModel)]="pesquisaParams.DtFim"
          (ngModelChange)="onChangeFilter($event)"
        ></app-input>
      </div>
      <app-card-mobile
        [data]="tableData"
        [rows]="rowsMobile"
        [expand]="expandirCardsMobile"
      ></app-card-mobile>
      <app-paginacao
        [pages]="pesquisaParams.MaxPages"
        (selectedPage)="mudarPagina($event)"
      ></app-paginacao>
      <app-botao
        type="secundario"
        width="100%"
        text="Exportar Excel"
        icon="assets/images/download-icon.svg"
        (click)="exportarExcel()"
      ></app-botao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
