import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  IGetApontamentoResponse,
  IPessoaData,
  ITableData,
} from './apontamento-layout.model';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { AppExportBase64 } from '../../../services/exportBase64/exportBase64.service';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';
import { formatCpf } from '../../../utils/utility-functions';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-apontamento-layout',
  templateUrl: './apontamento-layout.component.html',
  styleUrls: ['./apontamento-layout.component.scss'],
})
export class ApontamentoLayoutComponent implements OnInit {
  permission: number = 2;
  tableData: ITableData[] | Array<any> = [];
  pessoaData: IPessoaData = {};
  visibleHorariosApontados: boolean = false;
  visibleExtracaoRelatorio: boolean = false;
  expandirCardsMobile: boolean = false;
  loader: boolean = false;

  pesquisaParams = {
    TxPesquisar: '',
    DtInicio: '',
    DtFim: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
  };

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private toast: ToastrService,
    private exportBase64Service: AppExportBase64,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getApontamentos();
    this.changeDetectorRef.detectChanges();
  }
  tableColumns = [
    {
      header: 'Nome e CPF',
      key: '',
      keyArray: ['nome', 'cpf'],
      type: 'textArray',
    },
    { header: 'Tipo de Contratação', key: 'tipoContratacao', type: 'text' },
    { header: 'Alocação', key: 'alocacao', type: 'text' },
    { header: 'Data', key: 'data', type: 'text' },
    {
      header: 'Atraso',
      key: 'atraso',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.visualizarAtraso(item),
          condition: (item: any) => this.advertenciaAtraso(item),
        },
      ],
    },
    {
      header: 'Advertência',
      key: 'advertencia',
      type: 'buttons',
      buttons: [
        {
          label: 'Cadastrar',
          secondaryLabel: 'Editar',
          conditionSecLabel: (item: any) =>
            item?.Advertencia['IdAdvertencia'] == 0 ? false : true,
          action: (item: any) => this.visualizarAdvertencia(item),
          condition: (item: any) => this.advertenciaMotivo(item),
        },
      ],
    },
    {
      header: 'Ações',
      key: 'acoes',
      type: 'buttons',
      buttons: [
        {
          img: 'assets/images/info-circle.svg',
          label: 'Horários',
          action: (item: any) => this.verHorarios(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    { header: 'Nome', key: 'nome', type: 'text', default: true },
    {
      header: 'CPF',
      key: 'cpf',
      type: 'text',
      default: true,
      style: 'blue',
    },
    { header: 'Alocação', key: 'alocacao', type: 'text' },
    { header: 'Data', key: 'data', type: 'text' },
    {
      header: 'Atraso',
      key: 'atraso',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.visualizarAtraso(item),
          condition: (item: any) => this.advertenciaAtraso(item),
        },
      ],
    },
    {
      header: 'Advertência',
      key: 'advertencia',
      type: 'buttons',
      buttons: [
        {
          label: 'Cadastrar',
          secondaryLabel: 'Editar',
          conditionSecLabel: (item: any) =>
            item?.Advertencia['IdAdvertencia'] == 0 ? false : true,
          action: (item: any) => this.visualizarAdvertencia(item),
          condition: (item: any) => this.advertenciaMotivo(item),
        },
      ],
    },
    {
      header: 'Ações',
      key: 'acoes',
      type: 'buttons',
      buttons: [
        {
          img: 'assets/images/info-circle.svg',
          label: 'Horários',
          action: (item: any) => this.verHorarios(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  advertenciaAtraso(item?: any): any {
    if (!item.atraso) {
      return false;
    }
    return true;
  }

  advertenciaMotivo(item?: any): any {
    if (!item.apontamento) {
      return false;
    }
    return true;
  }

  visualizarAdvertencia(item: any) {
    let status = 1;
    status = item.Advertencia['IdAdvertencia'] == 0 ? 1 : 2;

    this.router.navigate(['encarregado/advertencia'], {
      queryParams: {
        idAdvertencia: item.Advertencia['IdAdvertencia'],
        idPessoa: item.idPessoa,
        ApontamentoHoraId: item['apontamento'],
        isMode: status,
      },
    });
  }

  visualizarAtraso(item: any) {
    this.router.navigate(['encarregado/divergencia'], {
      queryParams: {
        idAdvertencia: item.justificativa,
        idPessoa: item.idPessoa,
        ApontamentoHoraId: item.apontamento,
        isMode: 0,
      },
    });
  }

  navegarPonto() {
    this.router.navigate(['encarregado/ponto']);
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getApontamentos();
  }

  formatarCampos(campo: string | number | Date): string {
    return campo === null || campo === '' ? 'Não Informado' : String(campo);
  }

  getHours(date: Date) {
    if (date !== null) {
      return moment(date).format('HH:mm');
    }
    return '--';
  }

  getDate(date: Date) {
    if (date !== null) {
      return moment(date).format('DD/MM/YYYY');
    }
    return '--';
  }

  getApontamentos() {
    this.loader = true;
    const params = this.pesquisaParams;

    this.httpService.useGet('ApontamentosByFilters', params).subscribe({
      next: (response: IGetApontamentoResponse) => {
        this.loader = false;
        this.tableData = response.DataSet.map((item) => {
          return {
            id: item.ApontamentoId ?? 0,
            nome: item.TxNome,
            cpf: formatCpf(item.TxCPF),
            tipoContratacao: item.TipoPessoaId == 1 ? 'CLT' : 'Reeducando',
            alocacao: this.formatarCampos(item.NmFrente),
            data: this.getDate(item.DtCriacao),
            entrada: this.getHours(item.DtEntrada),
            pausa: this.getHours(item.DtSaidaAlmoco),
            volta: this.getHours(item.DtRetornoAlmoco),
            saida: this.getHours(item.DtSaida),
            idPessoa: item.IdPessoa,
            advertencia: item.Atraso,
            divergencia: item.DivergenciaId,
            apontamento: item.ApontamentoId,
            Advertencia: {
              IdAdvertencia: item.Advertencia.IdAdvertencia,
              NuMotivoAdvertencia: item.Advertencia.NuMotivoAdvertencia,
              NuTurnoAdvertencia: item.Advertencia.NuTurnoAdvertencia,
              TxDescricaoAdvertencia: item.Advertencia.TxDescricaoAdvertencia,
            },
          };
        });
        this.pesquisaParams.MaxPages =
          Math.ceil(response.Total / this.pesquisaParams.TamanhoPagina) || 1;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error(
          'Não foi possível buscar apontamentos. Tente novamente!'
        );
      },
    });
  }

  extracaoAdvertencia() {
    this.loader = true;
    const body = {
      TxPesquisar: this.pesquisaParams.TxPesquisar.toLowerCase(),
    };

    this.httpService.usePostFilter('AdvertenciaXslx', body).subscribe({
      next: (response: any) => {
        this.exportBase64Service.downloadExcel(
          response.DataSet,
          'relatorio-advertencia'
        );
        this.loader = false;
      },
      error: (_error) => {
        this.toast.error('Ocorreu um erro ao exportar excel. Tente novamente!');
        this.loader = false;
      },
    });
  }

  apontamentoDiario() {
    this.loader = true;
    const body = {
      TxPesquisar: this.pesquisaParams.TxPesquisar.toLowerCase(),
    };

    this.httpService.usePostFilter('ApontamentoHoraXlsx', body).subscribe({
      next: (response: any) => {
        this.exportBase64Service.downloadExcel(
          response.DataSet,
          'apontamento-hora'
        );
        this.loader = false;
      },
      error: (_error) => {
        this.toast.error('Ocorreu um erro ao exportar excel. Tente novamente!');
        this.loader = false;
      },
    });
  }

  extracaoRelatorio() {
    this.visibleExtracaoRelatorio = !this.visibleExtracaoRelatorio;
  }

  verHorarios(item?: any) {
    this.pessoaData = item;

    this.visibleHorariosApontados = !this.visibleHorariosApontados;
  }
}
