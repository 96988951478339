import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ViaCepService } from '../../../services/viacep/viacep.service';
import { HttpService } from '../../../services/common/http/common.http.service';
import { PermissionService } from '../../../services/permission.service';
import { IAssociarFrenteDeTrabalhoForm } from './associar-frente-trabalho.model';

type IOptions = {
  name: string;
  value: number | string;
  idPerfil?: number;
  email?: string;
  telefone?: string;
  funcionarioId?: number;
};

@Component({
  selector: 'app-associar-frente-trabalho-layout',
  templateUrl: './associar-frente-trabalho-layout.component.html',
  styleUrls: ['./associar-frente-trabalho-layout.component.scss'],
})
export class AssociarFrenteTrabalhoLayoutComponent
  implements OnInit, AfterViewInit
{
  permission: number = 2;
  editando: boolean = false;
  pessoaOptions: IOptions[] = [];
  loader: boolean = false;
  pessoaList: any = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private toast: ToastrService,
    private activatedRoute: ActivatedRoute,
    private viaCepService: ViaCepService,
    private httpService: HttpService,
    private permissionService: PermissionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.activateRoute();
    this.getPessoas();
    this.changeDetectorRef.detectChanges();
  }

  form: IAssociarFrenteDeTrabalhoForm = {
    PessoaIds: [],
    FrenteTrabalhoId: 0,
    Proprietario: '',
    Encarregado: '',
    NomeFrenteTrabalho: '',
    NuCapacidade: 0,
  };

  ngAfterViewInit(): void {
    this.activateRoute();
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      const IdFrenteTrabalho = params['IdFrenteTrabalho'];

      this.httpService
        .useGet('FrenteTrabalhoByFilter', { IdFrenteTrabalho })
        .subscribe({
          next: (_response: any) => {
            const valueForm = _response.DataSet[0];

            this.form.FrenteTrabalhoId = valueForm.Id;
            this.form.Proprietario = valueForm.Proprietario ?? '';
            this.form.Encarregado = valueForm.Encarregado ?? '';
            this.form.NomeFrenteTrabalho = valueForm.Descricao ?? '';
            this.form.NuCapacidade = valueForm.Capacidade ?? 0;

            this.getAssociar(Number(params['IdFrenteTrabalho']));
          },
        });
    });
  }

  associarFrenteDeTrabalho() {
    const payload = this.createPayload();
    const form = this.form;

    if (form.PessoaIds.length > 0) {
      this.httpService.usePost('AssociarFrenteTrabalho', payload).add(
        this.httpService.useGet('FrenteTrabalhoByFilter').subscribe({
          next: (response) => {
            this.loader = false;
          },
          error: (_error: any) => {
            this.loader = false;
          },
        })
      );

      this.router.navigate(['recursos-humanos/frente-de-trabalho']);
    } else {
      Swal.fire({
        title: 'Atenção!',
        text: 'Preencha os campos obrigatórios antes de efetivar a associação.',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
  }

  getPessoas() {
    this.loader = true;

    const NuStatus = 1;

    this.httpService.useGet('PessoaByFilter', { NuStatus }).subscribe({
      next: (response: any) => {
        this.pessoaOptions = response.DataSet.map((x: any) => {
          return {
            name: x.TxNome,
            value: x.IdPessoa,
          };
        });
        this.changeDetectorRef.detectChanges();
        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }

  createPayload() {
    const body = {
      PessoaIds: this.form.PessoaIds,
      FrenteTrabalhoId: this.form.FrenteTrabalhoId,
    };

    return body;
  }

  getAssociados(params: number) {
    const id = params;
    this.httpService.useGet('FrenteTrabalhoWithPessoa', { id }).subscribe({
      next: (response: any) => {
        let pessoaList: number[] = [];
        this.pessoaOptions = response.Pessoas.map((x: any) => {
          return {
            name: x.TxNome,
            value: x.IdPessoa,
          };
        });

        this.pessoaOptions.forEach((x) => {
          pessoaList.push(Number(x.value));
        });
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }

  changeSelect(event: any) {
    if (event.length <= this.form.NuCapacidade) {
      this.form.PessoaIds = event;

      this.pessoaList = this.pessoaOptions
        .filter((x) => this.form.PessoaIds.includes(Number(x.value)))
        .map((x) => x.name);

      this.form.PessoaIds = this.form.PessoaIds.slice(
        0,
        this.form.NuCapacidade
      );

      this.changeDetectorRef.detectChanges();
    } else {
      Swal.fire({
        title: 'Atenção!',
        text: 'O número de pessoas selecionados ultrapassa a capacidade da frente de trabalho.',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });

      this.form.PessoaIds = this.form.PessoaIds.slice(
        0,
        this.form.NuCapacidade
      );
    }
  }

  findNamePessoa(list: any[], value: any) {
    let nomePessoa: any[] = [];
    let foundItems;

    value.forEach((element: any) => {
      foundItems = list.filter((item) => item.value === element);
      nomePessoa.push(...foundItems.map((item) => item.name));
    });

    return nomePessoa ? nomePessoa : null;
  }

  findValuePessoa(list: any[], value: any): number[] {
    let idPessoa: any[] = [];
    let foundItems;

    value.forEach((element: any) => {
      foundItems = list.filter((item) => item.value === element);

      idPessoa.push(...foundItems.map((item) => item.value));
    });

    return idPessoa ? idPessoa : [];
  }

  getAssociar(params: number) {
    const Id = params;
    this.httpService.useGet('FrenteTrabalhoWithPessoa', { Id }).subscribe({
      next: (response: any) => {
        let reedCadastrados: number[] = [];
        reedCadastrados = response.Pessoas.map((x: any) => x.IdPessoa);

        this.changeSelect(reedCadastrados);
        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }
}
