import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voltar',
  templateUrl: './voltar.component.html',
  styleUrls: ['./voltar.component.scss'],
})
export class VoltarComponent implements OnInit {
  constructor(private location: Location) {}

  ngOnInit() {}

  voltar() {
    this.location.back();
  }
}
