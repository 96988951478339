<div class="input">
  <label [style.color]="labelColor" class="main-label">
    {{ label }} <span *ngIf="requiredInput" class="required">*</span>
  </label>
  <div class="custom-select-container" (click)="$event.stopPropagation()">
    <input
      type="text"
      class="main-input"
      placeholder="Selecione"
      [style.width]="width"
      [(ngModel)]="searchTerm"
      (ngModelChange)="filterOptions($event)"
      (focus)="showOptions = true"
      [disabled]="disabled"
    />
    <ul *ngIf="showOptions && filteredOptions.length" class="custom-options">
      <li *ngFor="let option of filteredOptions">
        <label class="custom-options-label">
          <input
            type="checkbox"
            class="checkbox"
            [checked]="isSelected(option)"
            (change)="toggleSelection(option)"
          />
          <span class="name">
            {{ option.name }}
          </span>
        </label>
      </li>
    </ul>
  </div>
</div>
