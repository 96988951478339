import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  DisiciplinaSchema,
  ICadastrarDisciplinaForm,
  IResponsavelOptions,
} from './cadastrar-disciplina-layout.model';
import { z } from 'zod';
import { validateSchema } from '../../../services/zod/zod.service';

@Component({
  selector: 'app-cadastrar-disciplina-layout',
  templateUrl: './cadastrar-disciplina-layout.component.html',
  styleUrls: ['./cadastrar-disciplina-layout.component.scss'],
})
export class CadastrarDisciplinaLayoutComponent implements OnInit {
  responsavelOptions: IResponsavelOptions[] = [];
  editando: boolean = false;
  loader: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getFuncionarios();
    this.changeDetectorRef.detectChanges();
  }

  form: ICadastrarDisciplinaForm = {
    IdDisciplina: 0,
    NomeDisciplina: '',
    FuncionarioId: 0,
    Nome: '',
    TxTelefone: '',
  };

  setForm(params: ICadastrarDisciplinaForm) {
    this.form = {
      ...this.form,
      ...params
    }

    this.onSelectChange(this.form.FuncionarioId)
  }

  cadastrarDisciplina() {
    try {
      const payload = validateSchema(DisiciplinaSchema, this.form);

      this.loader = true;

      const request = this.editando
        ? this.http.put(environment.url + '/v1/Disciplina', payload)
        : this.http.post(environment.url + '/v1/Disciplina', payload);

      request.subscribe({
        next: (_response) => {
          this.loader = false;
          Swal.fire({
            title: 'Sucesso!',
            text: `Disciplina ${this.editando ? 'editada' : 'cadastrada'
              } com sucesso!`,
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: 'green',
          });
          this.router.navigate(['engenharia/disciplinas']);
        },
        error: (_error) => {
          this.loader = false;
          Swal.fire({
            title: 'Erro!',
            text: `Ocorreu um erro ao tentar ${this.editando ? 'editar' : 'cadastrar'
              } a disciplina. Tente novamente!`,
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: 'green',
          });
        },
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Alerta',
        text: error?.message?.toLowerCase() === 'invalid input' ? 'Preencha todos os campos.' : error?.message,
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
  }

  getFuncionarios() {
    this.loader = true;
    const url = '/v1/Funcionario/ByFilter';

    this.http.get(environment.url + url).subscribe({
      next: (response: any) => {
        this.responsavelOptions = response.DataSet.map((x: any) => ({
          value: x.FuncionarioId,
          name: x.TxNome,
          telefone: x.TxTeleFuncionario,
        }));
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error(
          'Não foi possível buscar a listagem. Tente novamente!'
        );
      },
      complete: () => {
        this.activateRoute();
        this.loader = false;
      }
    });
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['Editando']) {
        this.editando = params['Editando'];

        this.setForm({
          FuncionarioId: Number(params['FuncionarioId']),
          NomeDisciplina: params['NomeDisciplina'] || '',
          IdDisciplina: Number(params['IdDisciplina']) || 0
        })
      }
    });
  }

  onSelectChange(funcionarioId: number) {
    const user = this.responsavelOptions.find((resp) => resp.value == funcionarioId);
    
    this.form.FuncionarioId = user?.value || 0;
    this.form.TxTelefone = user?.telefone || '';
    this.form.Nome = user?.name || '';
    this.changeDetectorRef.detectChanges();
  }
}
