import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-mobile',
  templateUrl: './card-mobile.component.html',
  styleUrls: ['./card-mobile.component.scss'],
})
export class CardMobileComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() rows: any[] = [];
  @Input() expand: boolean = false;

  constructor() {}

  ngOnInit() {}

  getEstilo(item: any) {
    return item.saida === '--' ? 'red' : null;
  }
}
