<div class="container">
  <div class="div-img">
    <img src="assets/images/builtup.svg" />
  </div>
  <div class="login">
    <h1>Bem-vindo</h1>
    <div class="login-inputs">
      <!-- <span *ngIf="loginInvalido != ''">{{ loginInvalido }} </span> -->

      <app-input
        type="email"
        label="E-mail"
        class="input-login input-email"
        [labelColor]="updateCorLabel()"
        [(ngModel)]="login.email"
        ngDefaultControls
      ></app-input>

      <app-input
        type="password"
        label="Senha"
        class="input input-login"
        [labelColor]="updateCorLabel()"
        [(ngModel)]="login.senha"
        ngDefaultControl
      ></app-input>

      <app-botao
        (click)="loginSubmit()"
        class="input-login"
        text="Entrar"
        [disabled]="!login.email || !login.email.includes('@') || !login.senha"
      ></app-botao>
    </div>
  </div>
</div>

<app-loader *ngIf="loader"></app-loader>
