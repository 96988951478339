import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppExportBase64 {
  constructor() {}

  public downloadPDF(pdfBase64: string, title: string) {
    const base64Regex = /^data:application\/pdf;base64,/;
    if (!pdfBase64.match(base64Regex)) {
      pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;
    }

    const downloadLink = document.createElement('a');
    const fileName = this.generateTitle(title) + '.pdf';

    downloadLink.href = pdfBase64;
    downloadLink.download = fileName;
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  public downloadExcel(excelBase64: string, title: string) {
    const base64Regex =
      /^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,/;
    if (!excelBase64.match(base64Regex)) {
      excelBase64 = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelBase64}`;
    }

    const downloadLink = document.createElement('a');
    const fileName = this.generateTitle(title) + '.xlsx';

    downloadLink.href = excelBase64;
    downloadLink.download = fileName;
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  public downloadJPG(jpgBase64: string, title: string) {
    const base64Regex = /^data:image\/jpg;base64,/;
    if (!jpgBase64.match(base64Regex)) {
      jpgBase64 = `data:image/jpg;base64,${jpgBase64}`;
    }

    const downloadLink = document.createElement('a');
    const fileName = this.generateTitle(title) + '.jpg';

    downloadLink.href = jpgBase64;
    downloadLink.download = fileName;
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private generateTitle(nome: string): string {
    const hoje = new Date();
    const ano = hoje.getFullYear();
    const mes = String(hoje.getMonth() + 1).padStart(2, '0');
    const dia = String(hoje.getDate()).padStart(2, '0');
    return `${ano}${mes}${dia} - ${nome}`;
  }
}
