<p-dialog
  header="Horários de Apontamento"
  [(visible)]="visible"
  [style]="{
    width: '50rem',
    height: '22.5rem',
  }"
  [draggable]="false"
  (onHide)="this.inputClose.emit(true)"
>
  <div class="container">
    <h1 class="name">{{ pessoaData.nome }}</h1>
    <h2 class="date">{{ pessoaData.data }}</h2>
    <div class="cards">
      <app-card-horario
        type="entrada"
        [hour]="pessoaData.entrada || 'Não registrado'"
      ></app-card-horario>
      <app-card-horario
        type="saida"
        [hour]="pessoaData.pausa || 'Não registrado'"
      ></app-card-horario>
      <app-card-horario
        type="entrada"
        [hour]="pessoaData.volta || 'Não registrado'"
      ></app-card-horario>
      <app-card-horario
        type="saida"
        [hour]="pessoaData.saida || 'Não registrado'"
      ></app-card-horario>
    </div>
  </div>
</p-dialog>

<div *ngIf="visible" class="blur-overlay"></div>
<app-loader *ngIf="loader"></app-loader>
