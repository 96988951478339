import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuprimentosComponent } from './suprimentos/suprimentos/suprimentos.component';
import { FornecedorComponent } from './fornecedor/fornecedor/fornecedor.component';
import { PedidoMaterialComponent } from './pedido-material/pedido-material/pedido-material.component';
import { AppGuard } from '../../services/auth.guard';

const routes: Routes = [
  {
    path: 'suprimentos',
    component: SuprimentosComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuAdmin'] },
  },
  {
    path: 'suprimentos/fornecedor',
    component: FornecedorComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuAdmin'] },
  },
  {
    path: 'suprimentos/pedido',
    component: PedidoMaterialComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuAdmin'] },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class SuprimentosRoutingModule {}
