import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-suprimentos-layout',
  templateUrl: './suprimentos-layout.component.html',
  styleUrls: ['./suprimentos-layout.component.scss'],
})
export class SuprimentosLayoutComponent implements OnInit {
  expandirCardsMobile: boolean = false;
  mockedData = [
    {
      numeroPedido: '0000001',
      item: 'Alumínio',
      precoKg: 'R$ 5,36',
      quantidade: '110 Kg',
      dataPedido: '02/01/2024',
      precoTotal: 'R$ 589,60',
    },
    {
      numeroPedido: '0000002',
      item: 'Cobre',
      precoKg: 'R$ 9,10',
      quantidade: '90 Kg',
      dataPedido: '02/01/2024',
      precoTotal: 'R$ 819,00',
    },
    {
      numeroPedido: '0000003',
      item: 'Ferro',
      precoKg: 'R$ 6,21',
      quantidade: '100 Kg',
      dataPedido: '02/01/2024',
      precoTotal: 'R$ 621,00',
    },
  ];

  tableColumns = [
    { header: 'Nº Pedido', key: 'numeroPedido', type: 'text' },
    { header: 'Item', key: 'item', type: 'text' },
    { header: 'Preço Kg', key: 'precoKg', type: 'text' },
    { header: 'Quantidade', key: 'quantidade', type: 'text' },
    { header: 'Data do pedido', key: 'dataPedido', type: 'text' },
    { header: 'Preço Total', key: 'precoTotal', type: 'text' },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          img: 'assets/images/visualizar.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
          label: 'Editar',
        },
      ],
    },
  ];

  rowsMobile = [
    { header: 'Nº Pedido', key: 'numeroPedido', type: 'text', default: true },
    { header: 'Item', key: 'item', type: 'text' },
    { header: 'Preço Kg', key: 'precoKg', type: 'text' },
    { header: 'Quantidade', key: 'quantidade', type: 'text' },
    { header: 'Data do pedido', key: 'dataPedido', type: 'text' },
    {
      header: 'Preço Total',
      key: 'precoTotal',
      type: 'text',
      default: true,
    },
    {
      header: 'Ações',
      key: 'acoes',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          img: 'assets/images/eye-blue.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  editItem(item: any) {}

  navegarCriarPedido() {
    this.router.navigate(['suprimentos/pedido']);
  }

  navegarCadastrarFornecedor() {
    this.router.navigate(['suprimentos/fornecedor']);
  }

  constructor(private router: Router) {}

  ngOnInit() {}
}
