import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  ICommonHttp,
  IHttpDelete,
  IHttpGet,
  IHttpPost,
  IHttpPut,
  IHttpPatch,
  INavigateRouter,
  IMessageError,
} from './common.http.model';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

let API = environment.url;

let APICommon: ICommonHttp = {
  Autenticar: `${API}/v1/Login/Autenticar`,
  Advertencia: `${API}/v1/Advertencia`,
  ApontamentoHora: `${API}/v1/ApontamentoHora`,
  DashboardRH: `${API}/v1/Dashboard/RH`,
  DashboardEncarregado: `${API}/v1/Dashboard/Encarregado`,
  Divergencia: `${API}/v1/Divergencia`,
  DocumentoPessoa: `${API}/v1/DocumentoPessoa`,
  EPI: `${API}/v1/EPI`,
  EPI_Reed: `${API}/v1/EPI_Reed`,
  FrenteTrabalho: `${API}/v1/FrenteTrabalho`,
  Funcionario: `${API}/v1/Funcionario`,
  Usuario: `${API}/v1/Usuario`,
  Perfil: `${API}/v1/Perfil`,
  PerfilNGestaAcesso: `${API}/v1/Perfil/PerfilNGestaAcesso`,
  GestaoAcesso: `${API}/v1/GestaoAcesso`,
  ImagemJustificativa: `${API}/v1/ImagemJustificativa`,
  ImagemProjeto: `${API}/v1/ImagemProjeto`,
  ImagemProjetoDisciplina: `${API}/v1/ImagemProjetoDisciplina`,
  ImagemPessoa: `${API}/v1/ImagemPessoa`,
  Projeto: `${API}/v1/Projeto`,
  ProjetoDisciplina: `${API}/v1/ProjetoDisciplina`,
  Pessoa: `${API}/v1/Pessoa`,
  PessoaWithDocuments: `${API}/v1/Pessoa/PessoaWithDocuments`,
  Disciplina: `${API}/v1/Disciplina`,
  Acrescimo: `${API}/v1/Acrescimo`,
};

let APIGet: IHttpGet | any = {
  ...APICommon,
  DashboardRH: `${API}/v1/Dashboard/RH`,
  PessoaByFilter: `${API}/v1/Pessoa/ByFilter`,
  AdvertenciaById: `${API}/v1/Advertencia/ById`,
  DivergenciaById: `${API}/v1/Divergencia/ById`,
  FrenteTrabalhoByFilter: `${API}/v1/FrenteTrabalho/GetByFilter`,
  EPIByFilter: `${API}/v1/EPI/ByFilter`,
  EPIWithPessoa: `${API}/v1/EPI/WithPessoa`,
  FrenteTrabalhoWithPessoa: `${API}/v1/FrenteTrabalho/WithPessoa`,
  ApontamentosByFilters: `${API}/v1/ApontamentoHora/ByFilters`,
  FuncionarioByFilter: `${API}/v1/Funcionario/ByFilter`,
  FuncionarioByFilterXlsx: `${API}/v1/Funcionario/ByFilter/ExportXlsx`,
  PerfilByFilter: `${API}/v1/Perfil/ByFilter`,
  ProjetoByFilter: `${API}/v1/Projeto/GetByFilter`,
  ProjetoGet: `${API}/v1/Projeto/Get`,
  DisciplinaByFilter: `${API}/v1/Disciplina/DisciplinaByFilter`,
  TipoPessoa: `${API}/v1/Pessoa/TipoPessoa`,
  Licencas: `${API}/v1/Licenca/Licencas`,
  LicencasCriadas: `${API}/v1/Licenca/LicencasCriadas`,
  ApontamentoLog: `${API}/v1/ApontamentoLog/Logs`,
  ApontamentoLogImagem: `${API}/v1/ApontamentoLog/Imagem`,
};

let APIPost: IHttpPost | any = {
  ...APICommon,
  AssociateEPIPessoa: `${API}/v1/EPI_REED/AssociateEPIPessoa`,
  EPIByFilter: `${API}/v1/EPI/ByFilter`,
  DashboardRHExcel: `${API}/v1/Dashboard/RH/ExportXlsx`,
  TermoAceite: `${API}/v1/ApontamentoHora/AceitarTermos`,
  CreateFrenteTrabalho: `${API}/v1/FrenteTrabalho/CreateFrenteTrabalho`,
  AssociarEPI: `${API}/v1/EPI/AssociateEPIPessoa`,
  AssociarFrenteTrabalho: `${API}/v1/FrenteTrabalho/AssociarFrenteTrabalhoPessoa`,
  UsuarioNFuncionario: `${API}/v1/Usuario/UsuarioNFuncionario`,
  ApontamentoHoraXlsx: `${API}/v1/ApontamentoHora/ApontamentoHora/ExportXlsx`,
  AdvertenciaXslx: `${API}/v1/ApontamentoHora/ApontamentoHoraAdvertencia/ExportXlsx`,
  ApontamentoMensalXlsx: `${API}/v1/ApontamentoHora/ApontamentoHora/ExportXlsxByMonth`,
  EncarregadoXlsx: `${API}/v1/Dashboard/Encarregado/ExportXlsx`,
  PerfilXlsx: `${API}/v1/Perfil/ByFilter/ExportXlsx`,
  HorarioDivergente: `${API}/v1/Divergencia/HorarioDivergente`,
  Recognize: `${API}/v1/ReconhecimentoFacial/Recognize`,
  FaceTimekeeping: `${API}/v1/ApontamentoHora/FaceTimekeeping`,
  DashboardRHXlsx: `${API}/v1/Dashboard/RH/ExportXlsx`,
  ProjetoValidate: `${API}/v1/Projeto/Validate`,
  ProjetoReview: `${API}/v1/Projeto/Review`,
  CreateApontamentoHoraManual: `${API}/v1/ApontamentoHora/CreateApontamentoHoraManual`,
  LicencaCriar: `${API}/v1/Licenca/Criar`,
};

let APIPut: IHttpPut | any = {
  ...APICommon,
  AtualizarSenha: `${API}/v1/Usuario/AtualizarSenha`,
  UpdateFrenteTrabalho: `${API}/v1/FrenteTrabalho/UpdateFrenteTrabalho`,
  AssociatePessoaFrenteTrabalho: `${API}/v1/Pessoa/AssociatePessoaFrenteTrabalho`,
  UpdateApontamentoHoraManual: `${API}/v1/ApontamentoHora/UpdateApontamentoHoraManual`,
};

let APIDelete: IHttpDelete | any = {
  ...APICommon,
  DeleteFrenteTrabalho: `${API}/v1/FrenteTrabalho/DeleteFrenteTrabalho`,
  PessoaDesativar: `${API}/v1/Pessoa/Desativar`,
  DisciplinaDelete: `${API}/v1/Disciplina/Delete`,
  ProjetoDelete: `${API}/v1/Projeto/Delete`,
};

let APIPatch: IHttpPatch | any = {
  ...APICommon,
  PessoaDesativarAtivar: `${API}/v1/Pessoa/DesativarAtivar`,
  TempoLicenca: `${API}/v1/Licenca/TempoLicenca`,
};

let messageResponse: IMessageError = {
  Advertencia: 'Advertência',
  ApontamentoHora: 'Apontamento Hora',
  DashboardRH: 'Dashboard RH',
  DashboardEncarregado: 'Dashboard Encarregado',
  Divergencia: 'Divergência',
  DocumentoPessoa: 'Documento Pessoa',
  EPI: 'EPI',
  EPI_Reed: 'EPI Pessoa',
  FrenteTrabalho: 'Frente de Trabalho',
  Funcionario: 'Funcionário',
  GestaoAcesso: 'Gestão Acesso',
  ImagemJustificativa: 'Justificativa',
  ImagemProjeto: 'Projeto',
  ImagemProjetoDisciplina: 'Disciplina',
  ImagemPessoa: 'Pessoa',
  Perfil: 'Perfil',
  Projeto: 'Projeto',
  ProjetoDisciplina: 'Projeto Disciplina',
  Pessoa: 'Pessoa',
  Usuario: 'Usuário',
  AssociateEPIPessoa: 'Associação EPI',
  DeleteFrenteTrabalho: 'Frente de Trabalho',
  CreateFrenteTrabalho: 'Frente de Trabalho',
  UpdateFrenteTrabalho: 'Frente de Trabalho',
  AssociarEPI: 'Associação de EPI',
  AssociarFrenteTrabalho: 'Associação de Frente de Trabalho',
  UsuarioNFuncionario: 'Usuário',
  PessoaWithDocuments: 'Pessoa',
  DisciplinaDelete: 'Disciplina',
  ProjetoDelete: 'Projeto',
  ProjetoReview: 'Projeto',
  CreateApontamentoHoraManual: 'Apontamento Manual',
  UpdateApontamentoHoraManual: 'Apontamento Manual',
  Acrescimo: 'Acréscimo',
  LicencaCriar: 'Licença',
};

let navigateRouter: INavigateRouter | any = {
  CreateFrenteTrabalho: 'recursos-humanos/frente-de-trabalho',
  PessoaWithDocuments: 'recursos-humanos/pessoas',
  ProjetoReview: 'engenharia/projetos',
  CreateApontamentoHoraManual: 'encarregado/apontamentos',
  UpdateApontamentoHoraManual: 'encarregado/apontamentos',
};

let errorMessage: IMessageError | any = {
  CreateFrenteTrabalho: 'FrenteTralho',
  PessoaWithDocuments: 'Pessoa',
  Advertencia: 'Advertencia',
  ApontamentoHora: 'ApontamentoHora',
  DashboardRH: 'DashboardRH',
  DashboardEncarregado: 'DashboardEncarregado',
  Divergencia: 'Divergência',
  DocumentoPessoa: 'DocumentoPessoa',
  EPI: 'EPI',
  EPI_Reed: 'EPI Pessoa',
  FrenteTrabalho: 'FrenteTrabalho',
  Funcionario: 'Funcionario',
  GestaoAcesso: 'GestaoAcesso',
  ImagemJustificativa: 'Justificativa',
  ImagemProjeto: 'Projeto',
  ImagemProjetoDisciplina: 'Disciplina',
  ImagemPessoa: 'Pessoa',
  Perfil: 'Perfil',
  Projeto: 'Projeto',
  ProjetoDisciplina: 'ProjetoDisciplina',
  Pessoa: 'Pessoa',
  Usuario: 'Usuario',
  AssociateEPIPessoa: 'AssociacaoEPI',
  DeleteFrenteTrabalho: 'FrenteTrabalho',
  UpdateFrenteTrabalho: 'FrenteTrabalho',
  AssociarEPI: 'AssociacaoEPI',
  AssociarFrenteTrabalho: 'AssociacaoFrenteTrabalho',
  UsuarioNFuncionario: 'Usuario',
  AdvertenciaXslx: 'Advertencia',
  ApontamentoHoraXlsx: 'ApontamentoHora',
  ApontamentoMensalXlsx: 'ApontamentoHora',
  AssociatePessoaFrenteTrabalho: 'AssociacaoFrenteTrabalho',
  AtualizarSenha: 'AtualizarSenha',
  Autenticar: 'Autenticar',
  DashboardRHExcel: 'DashboardRH',
  DashboardRHXlsx: 'DashboardRH',
  EncarregadoXlsx: 'Encarregado',
  EPIByFilter: 'EPI',
  FaceTimekeeping: 'FaceTimekeeping',
  HorarioDivergente: 'Divergencia',
  PerfilNGestaAcesso: 'PerfilGestaoAcesso',
  PerfilXlsx: 'Perfil',
  Recognize: 'Recognize',
  PessoaDesativar: 'Pessoa',
  PessoaDesativarAtivar: 'Pessoa',
  TermoAceite: 'TermoAceite',
  CreateApontamentoHoraManual: 'ApontamentoHora',
  UpdateApontamentoHoraManual: 'ApontamentoHora',
  Acrescimo: 'Acréscimo',
};

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  loader: boolean = false;

  constructor(private http: HttpClient, private router: Router) {}

  usePost(url: keyof IHttpPost, body: any, message: string = ''): Subscription {
    return this.http.post<any>(APIPost[url], body).subscribe({
      next: (response: any) => {
        this.loader = false;

        if (response?.IsSuccess == false) {
          this.message(false, url, response.Message);
          return;
        }

        this.message(
          true,
          url,
          message ? message : `${messageResponse[url]} cadastrada com sucesso!`
        );
        this.router.navigate([navigateRouter[url]]);
        return;
      },
      error: (error: any) => {
        this.loader = false;

        this.message(false, url, undefined, error);
        return;

        this.message(false, url, undefined, error);
        return;
      },
      complete: () => {
        this.loader = false;
      },
    });
  }

  usePut(url: keyof IHttpPut, body: any, params?: any): Subscription {
    return this.http
      .put<any>(APIPut[url], body, {
        params,
      })
      .subscribe({
        next: (response: any) => {
          if (response?.IsSuccess == false) {
            this.message(false, url, response.Message);
            return;
          }

          this.message(
            true,
            url,
            `${messageResponse[url]} editado com sucesso!`
          );
          this.router.navigate([navigateRouter[url]]);
          return;
        },
        error: (error: any) => {
          this.message(false, url, undefined, error);
          return;
        },
      });
  }

  useDelete(url: keyof IHttpDelete, params: any): Subscription {
    return this.http.delete<any>(APIDelete[url], { params }).subscribe({
      next: (response: any) => {
        if (response?.IsSuccess == false) {
          this.message(false, url, response.Message);
          return;
        }

        this.message(
          true,
          url,
          `${messageResponse[url]} excluído com sucesso!`
        );
        this.router.navigate([navigateRouter[url]]);
        return;
      },
      error: (error: any) => {
        this.message(false, url, undefined, error);
        return;
      },
    });
  }

  useGet(url: keyof IHttpGet, params?: any): Observable<any> {
    return this.http.get<any>(APIGet[url], { params });
  }

  usePostFilter(url: keyof IHttpPost, body: any): Observable<any> {
    return this.http.post<any>(APIPost[url], body);
  }

  usePatch(url: keyof IHttpPatch, body: any, params?: any): Observable<any> {
    return this.http.patch<any>(APIPatch[url] + '?id=' + params, body);
  }

  private message(
    isSuccess: boolean,
    url: string,
    message?: string,
    response?: string | string[] | any
  ) {
    if (isSuccess) {
      return Swal.fire({
        title: 'Sucesso!',
        text: message,
        icon: 'success',
        confirmButtonColor: 'green',
        confirmButtonText: 'Ok',
      });
    } else {
      let errorMessageResponse = '<ul>';

      try {
        if (response.error.Error[errorMessage[url]].length > 0) {
          response.error.Error[errorMessage[url]].forEach(
            (x: any, index: number) => {
              if (index > 0) {
                errorMessageResponse += '<br>';
              }
              errorMessageResponse += `• ${x}`;
            }
          );

          errorMessageResponse += '</ul>';

          return Swal.fire({
            title: 'Alerta!',
            html: errorMessageResponse,
            icon: 'warning',
            confirmButtonColor: 'green',
            confirmButtonText: 'Ok',
          });
        } else {
          return Swal.fire({
            title: 'Alerta!',
            text: 'Ocorreu um problema inesperado ao tentar realizar está ação.',
            icon: 'warning',
            confirmButtonColor: 'green',
            confirmButtonText: 'Ok',
          });
        }
      } catch {
        return Swal.fire({
          title: 'Alerta!',
          text: response.error.Message,
          icon: 'warning',
          confirmButtonColor: 'green',
          confirmButtonText: 'Ok',
        });
      }
    }
  }
}
