import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ITableData,
  IGetDashboardResponse,
  IGraphsData,
  IDashboardItens,
  IGetDashboardResponsePagina,
} from './dashboard-rh-layout.model';
import { ToastrService } from 'ngx-toastr';
import { AppExportBase64 } from '../../../services/exportBase64/exportBase64.service';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';
import { formatCpf, formatDate } from '../../../utils/utility-functions';

@Component({
  selector: 'app-dashboard-rh-layout',
  templateUrl: './dashboard-rh-layout.component.html',
  styleUrls: ['./dashboard-rh-layout.component.scss'],
})
export class DashboardRhLayoutComponent implements OnInit {
  permission: number = 2;
  loader: boolean = false;
  tableData: ITableData[] = [];
  graphsData: IGraphsData[] = [];
  expandirMetricasMobile: boolean = false;
  expandirCardsMobile: boolean = false;
  counter = 0;
  title: string = 'Visualizar';
  timer: ReturnType<typeof setTimeout> | null = null;
  pesquisaParams = {
    TxPesquisa: '',
    DtInicio: '',
    DtFim: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
  };
  permissionView: boolean = false;

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private toast: ToastrService,
    private exportBase64Service: AppExportBase64,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getDashboard();
    this.changeDetectorRef.detectChanges();
  }

  graphPessoasCadastradas: IGraphsData = {
    title: 'Pessoas cadastradas',
    description: 'Quantidade',
    descriptionValue: '',
    description2: '',
    description2Value: '',
    totalValue: '',
  };

  graphPessoasAssociadas: IGraphsData = {
    title: 'Pessoas associadas',
    description: 'Associados',
    descriptionValue: '',
    description2: 'Não associados',
    description2Value: '',
    totalValue: '',
  };

  graphEpisAssociados: IGraphsData = {
    title: 'EPIs associados',
    description: 'Associados',
    descriptionValue: '',
    description2: 'Não associados',
    description2Value: '',
    totalValue: '',
  };

  mockedGraphsData = [
    this.graphPessoasCadastradas,
    this.graphPessoasAssociadas,
    this.graphEpisAssociados,
  ];

  tableColumns = [
    {
      header: 'Nome e CPF',
      key: '',
      keyArray: ['nome', 'cpf'],
      type: 'textArray',
    },
    { header: 'Tipo de Contratação', key: 'tipoContratacao', type: 'text' },
    { header: 'Alocação', key: 'alocacao', type: 'text' },
    { header: 'Cidade', key: 'cidadeUf', type: 'text' },
    { header: 'Turno', key: 'turno', type: 'text' },
    { header: 'Data e Horário', key: 'dataHorario', type: 'text' },
    {
      header: 'Motivo',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: this.title,
          action: (item: any) => this.visualizarMotivo(item),
          condition: (item: boolean) => this.advertenciaMotivo(item),
        },
      ],
    },
    {
      header: 'Justificativa',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: this.title,
          action: (item: any) => this.visualizarJustificativa(item),
          condition: (item: boolean) => this.advertenciaJustificativa(item),
        },
      ],
    },
  ];

  rowsMobile = [
    { header: 'Nome', key: 'nome', type: 'text', default: true },
    {
      header: 'CPF',
      key: 'cpf',
      type: 'text',
      default: true,
      style: 'blue',
    },
    { header: 'Alocação', key: 'alocacao', type: 'text' },
    { header: 'Cidade', key: 'cidadeUf', type: 'text' },
    { header: 'Turno', key: 'turno', type: 'text' },
    { header: 'Data e Horário', key: 'dataHorario', type: 'text' },
    {
      header: 'Motivo',
      key: 'motivo',
      type: 'buttons',
      buttons: [
        {
          label: this.title,
          action: (item: any) => this.visualizarMotivo(item),
          condition: (item: any) => this.advertenciaMotivo(item),
        },
      ],
    },
    {
      header: 'Justificativa',
      key: 'advertencia',
      type: 'buttons',
      buttons: [
        {
          label: this.title,
          action: (item: any) => this.visualizarJustificativa(item),
          condition: (item: any) => this.advertenciaJustificativa(item),
        },
      ],
    },
  ];

  advertenciaMotivo(item?: any): any {
    if (!item.motivo) {
      return false;
    }

    return true;
  }

  advertenciaJustificativa(item?: any): any {
    if (!item.justificativa) {
      return false;
    }
    return true;
  }

  visualizarMotivo(item: any) {
    this.router.navigate(['encarregado/advertencia'], {
      queryParams: {
        idAdvertencia: item.motivo,
        idPessoa: item.pessoa,
        ApontamentoHoraId: item.apontamento,
        isMode: 0,
      },
    });
  }

  visualizarJustificativa(item: any) {
    this.router.navigate(['encarregado/divergencia'], {
      queryParams: {
        idAdvertencia: item.justificativa,
        IdPessoa: item.pessoa,
        idApontamento: item.apontamento,
        isMode: 0,
      },
    });
  }

  navegarCadastrarPessoa() {
    this.router.navigate(['recursos-humanos/cadastrar-pessoa']);
  }

  navegarAssociarPessoa() {
    this.router.navigate(['recursos-humanos/frente-de-trabalho']);
  }

  navegarVisualizarEpis() {
    this.router.navigate(['encarregado/listagem-epi']);
  }

  calcularTotal(
    primeiroValor: string | number,
    segundoValor: string | number
  ): string {
    const valor1 =
      typeof primeiroValor === 'string'
        ? parseInt(primeiroValor, 10) || 0
        : primeiroValor;
    const valor2 =
      typeof segundoValor === 'string'
        ? parseInt(segundoValor, 10) || 0
        : segundoValor;
    const total = valor1 + valor2;
    return total.toString();
  }

  exportarExcel() {
    this.loader = true;
    const body = {
      TxPesquisar: this.pesquisaParams.TxPesquisa,
      DtInicio: this.pesquisaParams.DtInicio,
      DtFim: this.pesquisaParams.DtFim,
    };

    this.httpService.usePostFilter('DashboardRHExcel', body).subscribe({
      next: (response: any) => {
        this.exportBase64Service.downloadExcel(
          response.DataSet,
          'dashboard-rh'
        );
        this.loader = false;
      },
      error: (_error) => {
        this.toast.error('Ocorreu um erro ao exportar excel. Tente novamente!');
        this.loader = false;
      },
    });
  }

  getDashboard() {
    this.loader = true;
    const params = this.pesquisaParams;

    this.httpService.useGet('DashboardRH', params).subscribe({
      next: (response: any) => {
        const data = response.DataSet;

        const enumTurno = {
          0: 'Manhã',
          1: 'Tarde',
          2: 'Noite',
        };

        this.tableData = data.DashboardItens.map(
          (item: IDashboardItens) =>
            ({
              nome: item.TxNome ?? 'Não Informado',
              tipoContratacao: item.TipoPessoaId == 1 ? 'CLT' : 'Reeducando',
              cpf: formatCpf(item.TxCPF),
              alocacao: item.NomeFrente ?? 'Não Informado',
              cidadeUf: item.TxCidade ?? 'Não Informado',
              turno: enumTurno[item.NuTurno] ?? 'Não Informado',
              motivo: item.AdvertenciaId,
              apontamento: item.ApontamentoId,
              justificativa: item.DivergenciaId,
              pessoa: item.IdPessoa,
              dataHorario: formatDate(item.DtUltimoApontamento),
            } as ITableData)
        );

        this.setGraphs(data);

        this.pesquisaParams.MaxPages =
          Math.ceil(response.Total / this.pesquisaParams.TamanhoPagina) || 1;

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }

  onChangeFilter(event: any) {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.counter++;

      this.getDashboard();
    }, 250);
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getDashboard();
  }

  setGraphs(data: any) {
    this.graphPessoasCadastradas.descriptionValue = data.PessoasCadastrados;
    this.graphPessoasCadastradas.totalValue = data.PessoasCadastrados;

    this.graphPessoasAssociadas.descriptionValue = data.PessoasAssociados;
    this.graphPessoasAssociadas.description2Value = data.PessoasNaoAssociados;
    this.graphPessoasAssociadas.totalValue = data.PessoasCadastrados;

    this.graphEpisAssociados.descriptionValue = data.EPIsAssociados;
    this.graphEpisAssociados.description2Value = data.EPIsNaoAssociados;
    this.graphEpisAssociados.totalValue = this.calcularTotal(
      data.EPIsAssociados,
      data.EPIsNaoAssociados
    );
  }
}
