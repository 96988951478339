import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registrar-licenca',
  templateUrl: './registrar-licenca.component.html',
  styleUrls: ['./registrar-licenca.component.scss'],
})
export class RegistrarLicencaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
