<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo
        text="{{
          this.isMode == 0 ? 'Visualizar advertência' : 'Cadastrar advertência'
        }}"
      ></app-titulo>
    </div>
    <div class="form">
      <app-input
        type="text"
        width="100%"
        label="Nome do pessoa"
        name="nome"
        [(ngModel)]="form.nome"
        (ngModelChange)="escolherPessoa($event)"
        [selectData]="this.pessoaOptions"
        [disabled]="true"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="CPF"
        name="cpf"
        [(ngModel)]="form.cpf"
        [disabled]="true"
      ></app-input>
      <app-input
        type="select"
        width="100%"
        label="Turno"
        name="turno"
        [(ngModel)]="form.turno"
        (ngModelChange)="escolherTurno($event)"
        [selectData]="mockedTurnos"
        [disabled]="this.isMode == 0 ? true : false"
      ></app-input>
      <app-input
        type="select"
        width="100%"
        label="Motivo da advertência"
        name="motivoAdvertencia"
        [(ngModel)]="form.motivoAdvertencia"
        (ngModelChange)="escolherAdvertencia($event)"
        [selectData]="mockedMotivo"
        [disabled]="this.isMode == 0 ? true : false"
      ></app-input>
      <app-input
        type="money"
        width="100%"
        label="Valor da advertência"
        name="valorAdvertencia"
        [(ngModel)]="form.valorAdvertencia"
        [disabled]="this.isMode == 0 ? true : false"
      ></app-input>
      <app-input
        type="textarea"
        label="Descrição da advertência"
        name="descricaoAdvertencia"
        maxLength="150"
        class="grid-2fr"
        [(ngModel)]="form.descricaoAdvertencia"
        [disabled]="this.isMode == 0 ? true : false"
      ></app-input>
    </div>
    <div class="form-button">
      <app-botao
        width="100%"
        text="Salvar advertência"
        (click)="salvarAdvertencia()"
        *ngIf="this.isMode == 0 ? false : true"
      ></app-botao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
