import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  ITipoPessoa,
  PessoaForm,
  PessoaPost,
  PessoaPut,
} from './cadastrar-pessoa-layout.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ViaCepService } from '../../../services/viacep/viacep.service';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';
import { FileRead } from '../../../components/drag-drop/drag-drop.model';

type IEtapas = 1 | 2 | 3 | 4 | 5 | 6;

@Component({
  selector: 'app-cadastrar-pessoa-layout',
  templateUrl: './cadastrar-pessoa-layout.component.html',
  styleUrls: ['./cadastrar-pessoa-layout.component.scss'],
})
export class CadastrarPessoaLayoutComponent implements OnInit {
  permission: number = 2;
  etapa: IEtapas = 1;
  totalEtapas: number = 6;
  isMode: boolean = false;
  loader: boolean = false;

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private viaCepService: ViaCepService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.activateRoute();
    this.getTipoPessoas();
    this.changeDetectorRef.detectChanges();
  }

  form: PessoaForm = {
    numeroFichaRegistro: '',
    tipoContratacao: 0,
    nomeCompleto: '',
    nomeMae: '',
    nomePai: '',
    rg: '',
    cpf: '',
    dataNascimento: '',
    localNascimento: '',
    documentoMilitar: '',
    ctps: '',
    cor: 0,
    sexo: 0,
    grauInstrucao: 0,
    cep: '',
    endereco: '',
    numeroResidencia: 0,
    cidade: '',
    estado: '',
    telefone: '',
    email: '',
    cnh: '',
    categoriaCnh: 0,
    tituloEleitor: '',
    numeroReservista: '',
    numeroCarteiraTrabalho: '',
    numeroPisPasep: '',
    dataAdmissao: '',
    dataDemissao: '',
    localTrabalho: '',
    estadoCivil: 0,
    valorHora: 0,
    cargaHoraria: 0,
    valorSalario: 0,
    valorComissao: 0,
    possuiDeficiencia: false,
    deficiencia: '',
    titularBancario: '',
    tipoConta: 0,
    banco: '',
    agencia: 0,
    numeroConta: 0,
    digitoConta: 0,
    fotoPessoa: '',
    fotoPessoaNm: '',
    atestadoEscolaridade: '',
    exameAdmissional: '',
    carteiraTrabalho: '',
    previdenciaSocial: '',
    certidaoNascimento: '',
    certidaoCasamento: '',
    valeTransporte: '',
    servicoMilitar: '',
    descricaoSalario: '',
  };

  tipoContratacaoOptions = [];

  mockedSelectData = [
    {
      name: 'Alimentícia',
      value: 'alimenticia',
    },
    {
      name: 'Construção',
      value: 'construcao',
    },
  ];

  categoriaCnhOptions = [
    {
      name: 'A',
      value: 0,
    },
    {
      name: 'B',
      value: 1,
    },
    {
      name: 'AB',
      value: 2,
    },
    {
      name: 'C',
      value: 3,
    },
    {
      name: 'D',
      value: 4,
    },
    {
      name: 'E',
      value: 5,
    },
  ];

  grauInstituicaoOptions = [
    {
      name: 'Fundamental incompleto',
      value: 0,
    },
    {
      name: 'Fundamental completo',
      value: 1,
    },
    {
      name: 'Médio incompleto',
      value: 2,
    },
    {
      name: 'Médio completo',
      value: 3,
    },
    {
      name: 'Superior incompleto',
      value: 4,
    },
    {
      name: 'Superior Completo',
      value: 5,
    },
    {
      name: 'Pós graduação incompleta',
      value: 6,
    },
    {
      name: 'Pós graduação completa',
      value: 7,
    },
  ];

  sexoOptions = [
    {
      name: 'Masculino',
      value: 0,
    },
    {
      name: 'Feminino',
      value: 1,
    },
    {
      name: 'Outro',
      value: 2,
    },
  ];

  tipoContaOptions = [
    {
      name: 'Depósito',
      value: 0,
    },
    {
      name: 'Pagamento',
      value: 1,
    },
    {
      name: 'Corrente',
      value: 2,
    },
    {
      name: 'Poupança',
      value: 3,
    },
    {
      name: 'Salário',
      value: 4,
    },
    {
      name: 'Digital',
      value: 5,
    },
  ];

  estadoCivilOptions = [
    {
      name: 'Solteiro',
      value: 0,
    },
    {
      name: 'Casado',
      value: 1,
    },
    {
      name: 'Viúvo',
      value: 2,
    },
    {
      name: 'Divorciado',
      value: 3,
    },
    {
      name: 'União Estável',
      value: 4,
    },
  ];

  possuiDeficienciaOptions = [
    {
      name: 'Sim',
      value: true,
    },
    {
      name: 'Não',
      value: false,
    },
  ];

  corOptions = [
    {
      name: 'Preto',
      value: 0,
    },
    {
      name: 'Branca',
      value: 1,
    },
    {
      name: 'Pardo',
      value: 2,
    },
    {
      name: 'Indígena',
      value: 3,
    },
    {
      name: 'Outro',
      value: 4,
    },
  ];

  editando: boolean = false;

  idPessoa: number = 0;

  proximaEtapa() {
    if (this.etapa < this.totalEtapas) {
      this.etapa++;
    }
  }

  etapaAnterior() {
    if (this.etapa > 0) {
      this.etapa--;
    }
  }

  cadastrarPessoa() {
    this.loader = true;
    const payload = this.createPayload();

    if (!this.editando) {
      this.httpService.usePost('PessoaWithDocuments', payload).add(() => {
        this.loader = false;
      });
    } else {
      payload.ImagemPessoa = {
        ...payload.ImagemPessoa,
        PessoaId: this.idPessoa,
      };

      const payloadPut: PessoaPut = {
        ...payload,
        IdPessoa: this.idPessoa,
      };

      this.httpService.usePut('PessoaWithDocuments', payloadPut).add(() => {
        this.loader = false;
      });
    }
  }

  valorHora() {
    const valorHora =
      this.form.cargaHoraria == 0
        ? 0
        : Number(this.form.valorSalario) / Number(this.form.cargaHoraria);
    this.form.valorHora = Math.round(valorHora * 100) / 100;
  }

  createPayload(): PessoaPost | PessoaPut {
    console.log(
      this.form.dataAdmissao,
      this.form.dataDemissao,
      this.form.dataNascimento
    );

    return {
      IdPessoa: 1,
      TxFichaRegistro: this.form.numeroFichaRegistro,
      TxNome: this.form.nomeCompleto,
      TipoPessoaId: this.form.tipoContratacao,
      TxNomeMae: this.form.nomeMae,
      TxNomePai: this.form.nomePai,
      TxRG: String(this.form.rg),
      TxCPF: String(this.form.cpf),
      DtNascimento: String(this.form.dataNascimento ?? ''),
      TxLocalNascimento: this.form.localNascimento,
      TxDocumentoMilitar: this.form.documentoMilitar,
      TxCTPS: this.form.ctps,
      NuCor: Number(this.form.cor),
      TxSexo: Number(this.form.sexo),
      TxEndereco: this.form.endereco,
      NuResidencia: Number(this.form.numeroResidencia),
      TxCidade: this.form.cidade,
      TxEstado: this.form.estado,
      TxTelefone: this.form.telefone,
      TxEmail: this.form.email,
      TxCNH: this.form.cnh,
      NuCategoria: Number(this.form.categoriaCnh),
      TxTitulo: this.form.titularBancario,
      TxReservista: this.form.numeroReservista,
      TxCarteiraTrabalho: this.form.numeroCarteiraTrabalho,
      TxPISPASEP: this.form.numeroPisPasep,
      DtAdmissao: String(this.form.dataAdmissao ?? ''),
      DtDemissao: String(this.form.dataDemissao ?? ''),
      TxLocalTrabalho: this.form.localTrabalho,
      NuEstadoCivil: Number(this.form.estadoCivil),
      NuValorHora: Number(this.form.valorHora),
      NuCargaHora: Number(this.form.cargaHoraria),
      NuSalario: Number(this.form.valorSalario),
      NuComissao: Number(this.form.valorComissao),
      TxDescricaoSalario: this.form.descricaoSalario,
      FlDeficiencia: Boolean(this.form.possuiDeficiencia),
      TxTipoDeficiencia: this.form.deficiencia,
      TxTituloBancario: this.form.titularBancario,
      NuTipoContaBanco: Number(this.form.tipoConta),
      TxBanco: this.form.banco,
      TxAgenciaBanco: String(this.form.agencia),
      NuContaBanco: Number(this.form.numeroConta),
      NuDigitoContaBanco: Number(this.form.digitoConta),
      NuEscolaridade: Number(this.form.grauInstrucao),
      ImagemPessoa: {
        TxImagemPessoa: this.form.fotoPessoa,
        NmImagemPessoa: String(this.form.fotoPessoaNm),
      },
      DocumentosPessoa: {
        TxImagemEscolaridade: this.form.atestadoEscolaridade,
        TxImagemCarteiraTrabalho: this.form.carteiraTrabalho,
        TxImagemCTPS: this.form.previdenciaSocial,
        TxImagemCertificadoNascimento: this.form.certidaoNascimento,
        TxImagemCertificadoCasamento: this.form.certidaoCasamento,
        TxImagemDeclaracaoTransporte: this.form.valeTransporte,
        TxImagemServicoMilitar: this.form.servicoMilitar,
        TxImagemDeclaracaoSaude: this.form.exameAdmissional,
      },
    };
  }

  getTipoPessoas() {
    this.loader = true;

    this.httpService.useGet('TipoPessoa').subscribe({
      next: (response: any) => {
        this.loader = false;

        this.tipoContratacaoOptions = response.DataSet.map(
          (item: ITipoPessoa) => {
            return {
              value: item.IdTipoPessoa,
              name: item.TxDenominacao,
            };
          }
        );
      },
      error: (_error: any) => {
        this.loader = false;
      },
    });
  }

  onFileDropped(field: string, file: FileRead) {
    const formattedBase64 = file.base64.replace(
      /^data:image\/jpeg;base64,/,
      ''
    );

    if (field == 'fotoPessoa') {
      this.form.fotoPessoa = formattedBase64;
      this.form.fotoPessoaNm = file.name;
    }
    if (field == 'atestadoEscolaridade') {
      this.form.atestadoEscolaridade = formattedBase64;
    }
    if (field == 'exameAdmissional') {
      this.form.exameAdmissional = formattedBase64;
    }
    if (field == 'carteiraTrabalho') {
      this.form.carteiraTrabalho = formattedBase64;
    }
    if (field == 'previdenciaSocial') {
      this.form.previdenciaSocial = formattedBase64;
    }
    if (field == 'certidaoNascimento') {
      this.form.certidaoNascimento = formattedBase64;
    }
    if (field == 'certidaoCasamento') {
      this.form.certidaoCasamento = formattedBase64;
    }
    if (field == 'valeTransporte') {
      this.form.valeTransporte = formattedBase64;
    }
    if (field == 'servicoMilitar') {
      this.form.servicoMilitar = formattedBase64;
    }
  }

  buscarCep() {
    this.loader = true;

    this.viaCepService.GetBuscarCep(this.form.cep, {
      onSuccess: (response: any) => {
        this.form.endereco = response.logradouro + ' - ' + response.bairro;
        this.form.cidade = response.localidade;
        this.form.estado = response.uf;

        this.loader = false;
      },
    });
  }

  verificacaoEtapa1() {
    if (!this.isMode) {
      if (
        this.form.numeroFichaRegistro.length === 9 &&
        this.form.nomeCompleto.length >= 3 &&
        (this.form.nomeMae.length >= 2 || this.form.cpf.length === 11)
      ) {
        return true;
      }
      return false;
    }
    return true;
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['editando']) {
        this.editando = params['editando'] === 'true';

        this.isMode = true;
        const IdPessoa = (this.idPessoa = Number(params['idPessoa']));

        this.httpService
          .useGet('PessoaByFilter', { IdPessoa })
          .subscribe((response) => {
            const valueForm = response.DataSet[0];
            (this.form.tipoContratacao = valueForm.TipoPessoaId),
              (this.form.numeroFichaRegistro = valueForm.TxFichaRegistro),
              (this.form.nomeCompleto = valueForm.TxNome),
              (this.form.nomeMae = valueForm.TxNomeMae ?? ''),
              (this.form.nomePai = valueForm.TxNomePai ?? ''),
              (this.form.rg = valueForm.TxRG),
              (this.form.cpf = valueForm.TxCPF ?? ''),
              (this.form.dataNascimento = valueForm.DtNascimento ?? ''),
              (this.form.localNascimento = valueForm.TxLocalNascimento ?? ''),
              (this.form.documentoMilitar = valueForm.TxDocumentoMilitar ?? ''),
              (this.form.ctps = valueForm.TxCTPS ?? ''),
              (this.form.cor = valueForm.NuCor ?? 0),
              (this.form.sexo = valueForm.TxSexo ?? 0),
              (this.form.grauInstrucao = valueForm.NuEscolaridade ?? 0),
              (this.form.cep = valueForm.NuCEP ?? ''),
              (this.form.endereco = valueForm.TxEndereco ?? ''),
              (this.form.numeroResidencia = valueForm.NuResidencia ?? 0),
              (this.form.cidade = valueForm.TxCidade ?? ''),
              (this.form.estado = valueForm.TxEstado ?? ''),
              (this.form.telefone = valueForm.TxTelefone ?? ''),
              (this.form.email = valueForm.TxEmail ?? ''),
              (this.form.cnh = valueForm.TxCNH ?? ''),
              (this.form.categoriaCnh = valueForm.NuCategoria ?? 0),
              (this.form.tituloEleitor = valueForm.TxTitulo ?? ''),
              (this.form.numeroReservista = valueForm.TxReservista ?? ''),
              (this.form.numeroCarteiraTrabalho =
                valueForm.TxCarteiraTrabalho ?? ''),
              (this.form.numeroPisPasep = valueForm.TxPISPASEP ?? ''),
              (this.form.dataAdmissao = valueForm.DtAdmissao ?? ''),
              (this.form.dataDemissao = valueForm.DtDemissao ?? ''),
              (this.form.localTrabalho = valueForm.TxLocalTrabalho ?? ''),
              (this.form.estadoCivil = valueForm.NuEstadoCivil ?? 0),
              (this.form.valorHora = valueForm.NuValorHora ?? 0),
              (this.form.cargaHoraria = valueForm.NuCargaHora ?? 0),
              (this.form.valorSalario = valueForm.NuSalario ?? 0),
              (this.form.valorComissao = valueForm.NuComissao ?? 0),
              (this.form.possuiDeficiencia = valueForm.FlDeficiencia ?? false),
              (this.form.deficiencia = valueForm.TxTipoDeficiencia ?? ''),
              (this.form.titularBancario = valueForm.TxTituloBancario ?? ''),
              (this.form.tipoConta = valueForm.NuTipoContaBanco ?? 0),
              (this.form.banco = valueForm.TxBanco ?? ''),
              (this.form.agencia = valueForm.TxAgenciaBanco ?? 0),
              (this.form.numeroConta = valueForm.NuContaBanco ?? 0),
              (this.form.digitoConta = valueForm.NuDigitoContaBanco ?? 0),
              (this.form.descricaoSalario = valueForm.TxDescricaoSalario ?? '');
          });
      }
    });
  }
}
