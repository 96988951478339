import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ApontamentoComponent } from './apontamento/apontamento/apontamento.component';
import { PontoComponent } from './ponto/ponto/ponto.component';
import { DashboardEncarregadoComponent } from './dashboard-encarregado/dashboard-encarregado/dashboard-encarregado.component';
import { AdvertenciaComponent } from './advertencia/advertencia/advertencia.component';
import { CadastrarEpiComponent } from './epis/cadastrar-epi/cadastrar-epi.component';
import { ListagemEpiComponent } from './epis/listagem-epi/listagem-epi.component';
import { AppGuard } from '../../services/auth.guard';
import { AssociarEpiComponent } from './epis/associar-epi/associar-epi.component';
import { HorarioDivergenteLayoutComponent } from '../../layout/ponto/horario-divergente-layout/horario-divergente-layout.component';
import { CriarApontamentoComponent } from './apontamento/criar-apontamento/criar-apontamento.component';

const routes: Routes = [
  {
    path: 'encarregado/apontamentos',
    component: ApontamentoComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuApontamentoHora', 'NuAdmin'],
      title: 'Apontamentos | BUILTUP',
    },
  },
  {
    path: 'encarregado/ponto',
    component: PontoComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuApontamentoHora', 'NuAdmin'],
      title: 'Ponto | BUILTUP',
    },
  },
  {
    path: 'encarregado/dashboard',
    component: DashboardEncarregadoComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuDashboardEncarregado', 'NuAdmin'],
      title: 'Dashboard - Encarregado | BUILTUP',
    },
  },
  {
    path: 'encarregado/advertencia',
    component: AdvertenciaComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuAdvertencia', 'NuAdmin'],
      title: 'Advertência | BUILTUP',
    },
  },
  {
    path: 'encarregado/listagem-epi',
    component: ListagemEpiComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuEPI', 'NuAdmin'], title: `EPIs | BUILTUP` },
  },
  {
    path: 'encarregado/divergencia',
    component: HorarioDivergenteLayoutComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuDivergencia', 'NuAdmin'],
      title: 'Divergência | BUILTUP',
    },
  },
  {
    path: 'encarregado/cadastrar-epi',
    component: CadastrarEpiComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuEPI', 'NuAdmin'],
      title: 'Cadastrar EPI | BUILTUP',
    },
  },
  {
    path: 'encarregado/associar-epi',
    component: AssociarEpiComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuEPI', 'NuAdmin'],
      title: 'Associar EPI | BUILTUP',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class EncarregadoRoutingModule {}
