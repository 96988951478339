<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo
        [text]="isEditMode ? 'Editar usuário' : 'Cadastrar usuário'"
      ></app-titulo>
    </div>
    <form #criarUsuarioForm="ngForm" class="form">
      <app-input
        type="text"
        width="100%"
        label="Nome do usuário"
        name="nomeUsuario"
        [(ngModel)]="form.nomeUsuario"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Email"
        name="email"
        [(ngModel)]="form.email"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Telefone/Celular"
        name="telefone"
        mask="(00) 00000-0000"
        [(ngModel)]="form._telefone"
      ></app-input>
      <app-input
        label="Perfil"
        type="select"
        name="perfil"
        [selectData]="perfilOptions"
        [(ngModel)]="form.perfil"
        (ngModelChange)="onChangeSelect($event)"
      ></app-input>
    </form>
    <div class="form-button">
      <app-botao
        width="100%"
        [text]="isEditMode ? 'Editar' : 'Criar'"
        (click)="cadastrarOuEditarUsuario()"
        *ngIf="this.permission === 0"
      ></app-botao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
