<div *ngIf="textos.length > 0; else defaultText">
  <p *ngFor="let texto of textos">
    {{ texto }}
  </p>
</div>
<ng-template #defaultText>
  <p>
    Aguarde<span class="dot">.</span><span class="dot">.</span
    ><span class="dot">.</span>
  </p>
</ng-template>
