import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundComponent {
  authService: any;
  ngOnInit(): void {}

  constructor(
    private router: Router,
    private toast: ToastrService,
    private http: HttpClient
  ) {}
}
