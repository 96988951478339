<app-menu>
  <div class="desktop">
    <div class="header">
      <app-titulo text="Licenças"></app-titulo>
      <div class="header-buttons">
        <app-botao
          type="primario"
          text="Adicionar licença"
          width="100%"
          icon="assets/images/add.svg"
          (click)="navegarRegistrarLicenca()"
        ></app-botao>
        <app-botao
          type="secundario"
          text="Exportar Excel"
          width="100%"
          icon="assets/images/download-icon.svg"
        ></app-botao>
      </div>
    </div>
    <div class="table-header">
      <h1 class="table-title">Licenças</h1>
      <div class="table-filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          name="web-search"
          [(ngModel)]="pesquisaParams.Pesquisa"
          (ngModelChange)="getLicencas()"
        ></app-input>
        <app-input
          type="date"
          placeholder="Data inicial"
          [(ngModel)]="pesquisaParams.DtInicio"
          (blurEvent)="getLicencas()"
        ></app-input>
        <app-input
          type="date"
          placeholder="Data final"
          [(ngModel)]="pesquisaParams.DtFim"
          (blurEvent)="getLicencas()"
        ></app-input>
      </div>
    </div>
    <app-tabela [data]="tableData" [columns]="tableColumns"></app-tabela>
    <app-paginacao
      [pages]="pesquisaParams.MaxPages"
      (selectedPage)="mudarPagina($event)"
    ></app-paginacao>
  </div>

  <div class="mobile">
    <app-titulo text="Licenças"></app-titulo>
    <div class="listagem">
      <div
        class="menu-mobile"
        (click)="expandirCardsMobile = !expandirCardsMobile"
      >
        <app-titulo class="title" text="Licenças"></app-titulo>
        <img
          class="expandir-icon"
          src="assets/images/arrow-down.svg"
          *ngIf="!expandirCardsMobile"
        />
        <img
          class="expandir-icon"
          src="assets/images/arrow-up.svg"
          *ngIf="expandirCardsMobile"
        />
      </div>
      <div class="mobile-filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          name="web-search"
          [(ngModel)]="pesquisaParams.Pesquisa"
          (ngModelChange)="getLicencas()"
        ></app-input>
        <app-input
          type="date"
          placeholder="Data inicial"
          [(ngModel)]="pesquisaParams.DtInicio"
          (blurEvent)="getLicencas()"
        ></app-input>
        <app-input
          type="date"
          placeholder="Data final"
          [(ngModel)]="pesquisaParams.DtFim"
          (blurEvent)="getLicencas()"
        ></app-input>
        <app-botao
          type="primario"
          text="Cadastrar disciplina"
          width="100%"
          icon="assets/images/add.svg"
          (click)="navegarRegistrarLicenca()"
        ></app-botao>
      </div>
      <app-card-mobile
        [data]="tableData"
        [rows]="rowsMobile"
        [expand]="expandirCardsMobile"
      ></app-card-mobile>
      <app-paginacao
        [pages]="pesquisaParams.MaxPages"
        (selectedPage)="mudarPagina($event)"
      ></app-paginacao>
      <app-botao
        type="secundario"
        text="Exportar Excel"
        width="100%"
        icon="assets/images/download-icon.svg"
      ></app-botao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="this.loader"></app-loader>
