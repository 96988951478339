import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { FileRead } from './drag-drop.model';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrls: ['./drag-drop.component.scss'],
})
export class DragDropComponent implements OnInit {
  @Input() label: string = '';
  @Input() text: string = '';
  @Input() acceptedFileType: string = '';
  @Output() fileDropped = new EventEmitter<FileRead>();
  @ViewChild('fileInput') fileInput: any;

  files: NgxFileDropEntry[] = [];
  file: File | null = null;
  fileSrc: string | ArrayBuffer | null = null;
  isImage: boolean = false;

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.isFileTypeAllowed(file)) {
            this.file = file;
            this.isImage = this.file.type.startsWith('image/');
            if (this.isImage) {
              const reader = new FileReader();
              reader.onload = (e) => {
                this.fileSrc = reader.result;
                this.fileDropped.emit({
                  base64: reader.result as string,
                  name: file.name,
                });
              };
              reader.readAsDataURL(this.file);
            } else {
              this.fileSrc = '';
            }
          } else {
            this.toast.error(
              'Tipo de arquivo não permitido. Apenas arquivos no formato .jpg ou .jpeg são aceitos.'
            );
          }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (this.isFileTypeAllowed(file)) {
      this.file = file;
      this.isImage = this.file.type.startsWith('image/');
      if (this.isImage) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileSrc = reader.result;
          this.fileDropped.emit({
            base64: reader.result as string,
            name: file.name,
          });
        };
        reader.readAsDataURL(this.file);
      } else {
        this.fileSrc = null;
      }
    } else {
      this.toast.error(
        'Tipo de arquivo não permitido. Apenas arquivos no formato .jpg ou .jpeg são aceitos.'
      );
    }
  }

  isFileTypeAllowed(file: File): boolean {
    const fileExtension = String(file.name.split('.').pop()?.toLowerCase());
    return ['jpg', 'jpeg'].includes(fileExtension);
  }

  removeFile() {
    this.file = null;
    this.fileSrc = '';
    this.isImage = false;

    this.fileDropped.emit({
      base64: '',
      name: '',
    });
  }

  constructor(private toast: ToastrService) {}

  ngOnInit() {}
}
