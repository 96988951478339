import { NgModule } from '@angular/core';
import { LayoutModule } from '../../layout/layout.module';
import { ComponentsModule } from '../../components/components.module';
import { EngenhariaRoutingModule } from './engenharia-routing.module';
import { ProjetosComponent } from './projetos/projetos/projetos.component';
import { CadastrarProjetoComponent } from './projetos/cadastrar-projeto/cadastrar-projeto.component';
import { DisciplinaComponent } from './disciplinas/disciplinas/disciplinas.component';
import { CadastrarDisciplinaComponent } from './disciplinas/cadastrar-area/cadastrar-disciplina.component';
import { ValidarProjetoComponent } from './engenharia/validar-projeto/validar-projeto.component';
import { RevisaoComponent } from './projetos/revisao/revisao.component';
import { EngenhariaComponent } from './engenharia/engenharia/engenharia.component';

@NgModule({
  declarations: [
    DisciplinaComponent,
    CadastrarDisciplinaComponent,
    EngenhariaComponent,
    ProjetosComponent,
    CadastrarProjetoComponent,
    ValidarProjetoComponent,
    RevisaoComponent,
  ],
  imports: [EngenhariaRoutingModule, ComponentsModule, LayoutModule],
  exports: [],
})
export class EngenhariaModule {}
