import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IGetUsuarios } from './gestao-acesso-layout.model';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import moment from 'moment';
import { AppExportBase64 } from '../../../services/exportBase64/exportBase64.service';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';
import { formatTel } from '../../../utils/utility-functions';
@Component({
  selector: 'app-gestao-acesso-layout',
  templateUrl: './gestao-acesso-layout.component.html',
  styleUrls: ['./gestao-acesso-layout.component.scss'],
})
export class GestaoAcessoLayoutComponent implements OnInit {
  permission: number = 2;
  usuarios: any = [];
  perfilData = [];
  pesquisaParam: string = '';
  loader: boolean = false;
  expandirCardsMobile: boolean = false;

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private toast: ToastrService,
    private exportBase64Service: AppExportBase64,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getPerfils();
    this.getUsuarios();
    this.changeDetectorRef.detectChanges();
  }

  form = {
    pesquisar: '',
  };

  tableColumns = [
    {
      header: 'Nome e Email',
      key: '',
      keyArray: ['nome', 'email'],
      type: 'textArray',
    },
    { header: 'Telefone', key: 'telefone', type: 'text' },
    { header: 'Perfil', key: 'perfil', type: 'text' },
    { header: 'Data de Criação', key: 'dataCriacao', type: 'text' },
    { header: 'Status', key: 'status', type: 'text' },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          label: 'Deletar',
          img: 'assets/images/del.svg',
          action: (item: any) => this.deleteItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    { header: 'Nome', key: 'nome', type: 'text', default: true },
    {
      header: 'Email',
      key: 'email',
      type: 'text',
      default: true,
      style: 'blue',
    },
    { header: 'Perfil', key: 'perfil', type: 'text', default: true },
    { header: 'Telefone', key: 'telefone', type: 'text' },
    { header: 'Data de Criação', key: 'dataCriacao', type: 'text' },
    { header: 'Status', key: 'status', type: 'text' },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          label: 'Deletar',
          img: 'assets/images/del.svg',
          action: (item: any) => this.deleteItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  editItem(item: any) {
    this.router.navigate(['recursos-humanos/gestao-de-acesso/editar-usuario'], {
      queryParams: item,
    });
  }

  getPerfils() {
    this.loader = true;

    this.httpService.useGet('Perfil').subscribe({
      next: (response: any) => {
        this.perfilData = response.map((item: any) => ({
          name: item.TxNomePerfil,
          value: item.IdPerfil,
        }));

        this.loader = false;
      },
      error: () => {},
      complete: () => {},
    });
  }

  deleteItem(item: any) {
    this.loader = true;

    this.httpService.useDelete('Funcionario', item.idFuncionario).add(() => {
      Swal.fire({
        title: 'Sucesso!',
        text: 'Funcionário deletado com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok',
      });

      this.getUsuarios();
      this.loader = false;
    });
  }

  criarUsuario() {
    this.router.navigate(['recursos-humanos/gestao-de-acesso/criar-usuario']);
  }

  pesquisar(value: string) {
    this.pesquisaParam = `?txPesquisar=${value}`;
    this.getUsuarios();
  }

  exportarExcel() {
    this.loader = true;
    const body = {};

    this.httpService.useGet('FuncionarioByFilterXlsx', body).subscribe({
      next: (response: any) => {
        this.exportBase64Service.downloadExcel(
          response.DataSet.XlsxBase64,
          'gestao-acesso'
        );
        this.loader = false;
      },
      error: (_error) => {
        this.toast.success(
          'Ocorreu um erro ao exportar excel. Tente novamente!'
        );
        this.loader = false;
      },
    });
  }

  getDate(date: Date) {
    if (date !== null) {
      return moment(date).format('DD/MM/YYYY');
    }
    return '--';
  }

  getUsuarios() {
    const url = '/v1/Funcionario/ByFilter';

    this.httpService
      .useGet('FuncionarioByFilter', this.pesquisaParam)
      .subscribe({
        next: (response: any) => {
          this.usuarios = response.DataSet.map((item: IGetUsuarios) => {
            const perfil: any = this.perfilData.find(
              (perfil: any) => perfil.value === item.Perfil.IdPerfil
            );
            return {
              idFuncionario: item.FuncionarioId,
              idUsuario: item.IdUsuario,
              idPerfil: item.Perfil.IdPerfil,
              nome: item.TxNome,
              email: item.TxEmail,
              telefone: formatTel(item.TxTeleFuncionario),
              perfil: perfil?.name ?? '--',
              dataCriacao: this.getDate(item.DtCriacao),
              bloqueio: item.DtExclusao === null ? 'Desbloqueado' : 'Bloqueado',
              status:
                item.FlSituacaoFuncionario == true ? 'Ativo' : 'Bloqueado',
            };
          });
          this.router.navigate(['recursos-humanos/gestao-de-acesso']);
        },
        error: () => {},
        complete: () => {},
      });
  }
}
