import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadastrar-projeto',
  templateUrl: './cadastrar-projeto.component.html',
  styleUrls: ['./cadastrar-projeto.component.scss'],
})
export class CadastrarProjetoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
