export function formatTel(telefone: string): string {
  if (telefone) {
    telefone = telefone.replace(/\D/g, '');
    if (telefone === '' || telefone.length < 2) {
      return telefone;
    }
    return telefone.replace(/^(\d{0,2})(\d{0,5})(\d{0,4})$/, '($1) $2-$3');
  }
  return 'Não Informado';
}

export function formatCpf(cpf: string | null): string {
  if (cpf && cpf.length === 11) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  return 'Não Informado';
}

export function formatDate(data: Date): String {
  if (data) {
    const dataFormatada = new Date(data).toLocaleString('pt-br');
    return dataFormatada.toString().replace(',', ' ');
  }
  return 'Não informado';
}

export function getFuncionarioId() {
  const funcionario = JSON.parse(localStorage.getItem('Funcionario') ?? '');
  if (funcionario) {
    console.log(funcionario.IdFuncionario);
    return funcionario.IdFuncionario;
  }
  return;
}
