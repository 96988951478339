import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-planejamento-layout',
  templateUrl: './planejamento-layout.component.html',
  styleUrls: ['./planejamento-layout.component.scss'],
})
export class PlanejamentoLayoutComponent implements OnInit {
  expandirCardsMobile: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {}

  mockedData = [
    {
      nomeObra: 'Obra 1',
      local: 'Santos/SP',
      conclusao: '33% concluída',
    },
    {
      nomeObra: 'Obra 2',
      local: 'Praia Grande/SP',
      conclusao: '66% concluída',
    },
    {
      nomeObra: 'Obra 3',
      local: 'São Paulo/SP',
      conclusao: '100% concluída',
    },
  ];

  tableColumns = [
    { header: 'Nome da obra', key: 'nomeObra', type: 'text' },
    { header: 'Local', key: 'local', type: 'text' },
    {
      header: 'Diários',
      key: 'diarios',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
    {
      header: 'Materiais',
      key: 'materiais',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
    { header: 'Conclusão', key: 'conclusao', type: 'text' },
    {
      header: 'Fotos',
      key: 'fotos',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          label: 'Deletar',
          img: 'assets/images/del.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    { header: 'Nome da obra', key: 'nomeObra', type: 'text', default: true },
    { header: 'Local', key: 'local', type: 'text' },
    {
      header: 'Diários',
      key: 'diarios',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
    {
      header: 'Materiais',
      key: 'materiais',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
    { header: 'Conclusão', key: 'conclusao', type: 'text', default: true },
    {
      header: 'Fotos',
      key: 'fotos',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
    {
      header: 'Ações',
      key: 'acoes',
      type: 'buttons',
      buttons: [
        {
          img: 'assets/images/edit.svg',
          label: 'Editar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          img: 'assets/images/del.svg',
          label: 'Deletar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  editItem(item: any) {}

  navegarRegistrarDiario() {
    this.router.navigate(['planejamento/diario']);
  }
}
