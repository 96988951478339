<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo text="Validar projeto"></app-titulo>
      <h1>{{ form.NmProjeto }}</h1>
    </div>
    <div class="body">
      <div class="pdf">
        <pdf-viewer
          [src]="form.TxImagemProjeto"
          [rotation]="0"
          [original-size]="false"
          [show-all]="true"
          [fit-to-page]="false"
          [zoom]="1"
          [zoom-scale]="'page-width'"
          [stick-to-page]="false"
          [render-text]="true"
          [external-link-target]="'blank'"
          [autoresize]="true"
          [show-borders]="false"
        ></pdf-viewer>
      </div>
      <div class="buttons">
        <button class="btn-red" (click)="reprovarProjeto()">Reprovar</button>
        <button class="btn-green" (click)="aprovarProjeto()">Aprovar</button>
      </div>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
