import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLoggedIn: boolean = false;

  constructor(private router: Router) {}

  login(response: any) {
    this.isLoggedIn = true;
  }

  logout() {
    this.isLoggedIn = false;
    localStorage.clear();
    this.signin();
  }

  isAuthenticated(): boolean {
    return this.isLoggedIn;
  }

  signin = () => this.router.navigate(['/login']);

  signout() {
    localStorage.clear();
    this.signin();
  }

  isAuthenticatedAndTokenValid() {
    const token = this.token();
    if (!token) {
      return false;
    }

    const tokenData = this.getTokenData(token);
    const expiration = new Date(tokenData.exp * 1000);

    return expiration > new Date();
  }

  token = () => localStorage.getItem('Token');

  private getTokenData(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const decodedData = JSON.parse(atob(base64));
    return decodedData;
  }
}
