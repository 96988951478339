<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo
        [text]="editando ? 'Editar Perfil' : 'Cadastrar perfil'"
      ></app-titulo>
    </div>
    <div class="form">
      <app-input
        type="text"
        width="100%"
        label="Nome do perfil"
        [(ngModel)]="form.nomePerfil"
        [disabled]="editando ? true : false"
      ></app-input>
      <app-tabela [data]="mockedData" [columns]="tableColumns"></app-tabela>
      <app-botao
        width="100%"
        [text]="editando ? 'Editar Perfil' : 'Criar perfil'"
        (click)="editando ? editarPerfil() : cadastrarPerfil()"
        *ngIf="this.permission === 0"
      ></app-botao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
