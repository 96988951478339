import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  Disciplina,
  ICadastrarProjetoForm,
  IDisciplinasList,
  imagemProjetoDisciplinaSchema,
  imagemProjetoSchema,
  Projeto,
  ProjetoResponse,
  schema,
} from './cadastrar-projeto-layout.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { validateSchema } from '../../../services/zod/zod.service';
import { FileRead } from '../../../components/drag-drop-pdf/drag-drop-pdf.component.model';
import moment from 'moment';

type IEtapas = 1 | 2 | 3;

@Component({
  selector: 'app-cadastrar-projeto-layout',
  templateUrl: './cadastrar-projeto-layout.component.html',
  styleUrls: ['./cadastrar-projeto-layout.component.scss'],
})
export class CadastrarProjetoLayoutComponent implements OnInit {
  etapa: IEtapas = 1;
  totalEtapas: number = 3;
  editando: boolean = false;
  loader: boolean = false;
  responsavelOptions: any[] = [];
  obrasOptions: any[] = [];
  disciplinaOptions: any[] = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.activateRoute();
    this.getResponsaveis();
    this.getObras();
    this.getDisciplinas();
    this.changeDetectorRef.detectChanges();
  }

  form: ICadastrarProjetoForm = {
    NmProjeto: '',
    FuncionarioId: 0,
    ObraId: 0,
    FrenteTrabalhoId: 0,
    DtInicio: '',
    DtTermino: '',
    imagemProjeto: undefined,
    disciplinaList: [],
  };

  proximaEtapa() {
    try {
      switch (this.etapa) {
        case 1:
          validateSchema(schema, this.form);
          break;
        case 2:
          validateSchema(imagemProjetoSchema, this.form.imagemProjeto);
          break;
      }
      if (this.etapa < this.totalEtapas) {
        this.etapa++;
      }
    } catch (error: any) {
      Swal.fire({
        title: 'Alerta!',
        text: `${error.message}`,
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  etapaAnterior() {
    if (this.etapa > 0) {
      this.etapa--;
    }
    this.changeDetectorRef.detectChanges();
  }

  validarCadastroFinal() {
    this.form.disciplinaList.forEach((dscp) =>
      validateSchema(imagemProjetoDisciplinaSchema, dscp.ImagemDisciplina)
    );
    validateSchema(schema, this.form);
  }

  atualizarProjeto() {
    try {
      validateSchema(schema, this.form);

      this.loader = true;
      const payload = {
        FuncionarioId: +this.form.FuncionarioId,
        ObraId: Number(this.form.ObraId),
        FrenteTrabalhoId: Number(this.form.FrenteTrabalhoId),
        NmProjeto: this.form.NmProjeto,
        DtInicio: this.formatAAAAMMDD(this.form.DtInicio),
        DtTermino: this.formatAAAAMMDD(this.form.DtTermino),
        ProjetoId: Number(this.form.ProjetoId),
      };

      const request = this.http.patch(
        environment.url + '/v1/Projeto/Update',
        payload
      );

      request.subscribe({
        next: (response) => {
          this.loader = false;
          Swal.fire({
            title: 'Sucesso!',
            text: `Projeto ${
              this.editando ? 'editado' : 'cadastrado'
            } com sucesso!`,
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: 'green',
          });
          this.router.navigate(['engenharia/projetos']);
        },
        error: (_error) => {
          this.loader = false;
          Swal.fire({
            title: 'Erro!',
            text: `Ocorreu um erro ao tentar ${
              this.editando ? 'editar' : 'cadastrar'
            } o projeto. Tente novamente!`,
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: 'green',
          });
        },
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Alerta.',
        text: `${error.message}`,
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
  }

  formatAAAAMMDD(date: string) {
    if (moment(date).isValid()) return date;

    const dateSplit = date.split('/');
    const dia = dateSplit[0];
    const mes = dateSplit[1];
    const ano = dateSplit[2];

    return `${ano}-${mes}-${dia}`;
  }

  cadastrarProjeto() {
    try {
      this.validarCadastroFinal();
      this.loader = true;
      const payload = this.form;
      const request = this.http.post(
        environment.url + '/v1/Projeto/Create',
        payload
      );

      request.subscribe({
        next: (response) => {
          this.loader = false;
          Swal.fire({
            title: 'Sucesso!',
            text: `Projeto ${
              this.editando ? 'editado' : 'cadastrado'
            } com sucesso!`,
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: 'green',
          });
          this.router.navigate(['engenharia/projetos']);
        },
        error: (_error) => {
          this.loader = false;
          Swal.fire({
            title: 'Erro!',
            text: `Ocorreu um erro ao tentar ${
              this.editando ? 'editar' : 'cadastrar'
            } o projeto. Tente novamente!`,
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: 'green',
          });
        },
      });
    } catch (error: any) {
      Swal.fire({
        title: 'Alerta.',
        text: `${error.message}`,
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
  }

  getResponsaveis() {
    this.loader = true;
    const url = '/v1/Funcionario/ByFilter';

    this.http.get(environment.url + url).subscribe({
      next: (response: any) => {
        this.responsavelOptions = response.DataSet.map((x: any) => ({
          value: x.FuncionarioId,
          name: x.TxNome,
          idUsuario: x.IdUsuario,
        }));

        this.loader = false;

        if (this.editando && this.form.FuncionarioId) {
          this.getImagensProjeto();

          const responsavel: any = this.responsavelOptions.find(
            (x: any) => x.idUsuario === this.form.FuncionarioId
          );

          if (responsavel) {
            this.form.FuncionarioId = responsavel.idUsuario;
          }
        }
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error(
          'Não foi possível buscar a listagem. Tente novamente!'
        );
      },
    });
  }

  getObras() {
    this.loader = true;
    let url = '/v1/FrenteTrabalho';

    this.http.get(environment.url + url).subscribe({
      next: (response: any) => {
        this.obrasOptions = response?.FrenteTrabalhos?.map((x: any) => {
          return {
            name: x.TxNome,
            value: x.IdFrenteTrabalho,
          };
        });

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }

  getDisciplinas() {
    this.loader = true;
    let url = '/v1/Disciplina';

    this.http.get(environment.url + url).subscribe({
      next: (response: any) => {
        this.disciplinaOptions = response.map((x: any) => {
          return {
            name: x.NomeDisciplina,
            value: x.IdDisciplina,
            funcionarioId: x.FuncionarioId,
          };
        });

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }

  getImagensProjeto() {
    this.loader = true;

    this.http
      .get<ProjetoResponse>(
        environment.url +
          `/v1/Projeto/Get?IdProjeto=${this.form.ProjetoId}&FlArquivo=false`
      )
      .subscribe({
        next: ({ Projeto: data }: ProjetoResponse) => {
          const [{ Imagem }] = data.RevisoesProjeto;

          this.form.imagemProjeto = undefined;

          this.form.disciplinaList = data.Disciplinas.map(
            (disciplina: Disciplina, index: number) => {
              const ImagemDisciplina = disciplina.RevisoesProjetoDisciplina[0];

              return {
                IdDisciplina: disciplina.DisciplinaId,
                TxNomeDisciplina: this.getNomeDisciplina(
                  disciplina.DisciplinaId
                ),
                TxResponsavelDisciplina: this.getResponsavelDisciplina(
                  disciplina.DisciplinaId
                ),
                ImagemDisciplina: undefined,
              };
            }
          );

          this.loader = false;
        },
        error: (_error: any) => {
          this.loader = false;
          this.toast.error(
            'Não foi possível buscar as imagens do projeto. Tente novamente!'
          );
        },
      });
  }

  getNomeDisciplina(id: number) {
    return this.disciplinaOptions.find((x) => x.value === id)?.name;
  }

  getNomeFuncionario(id: number) {
    return this.responsavelOptions.find((x) => x.idUsuario === id)?.name;
  }

  getResponsavelDisciplina(id: number): string {
    const disciplina = this.disciplinaOptions.find((x) => x.value === id);
    if (disciplina) {
      return this.getNomeFuncionario(disciplina.funcionarioId);
    }
    return '';
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['Editando']) {
        this.editando = params['Editando'] === 'true';
        this.form.ProjetoId = +params['IdProjeto'] || 0;
        this.form.NmProjeto = params['NmProjeto'] || '';
        this.form.FuncionarioId = +params['FuncionarioId'] || 0;
        this.form.ObraId = +params['ObraId'] || 0;
        this.form.FrenteTrabalhoId = +params['FrenteTrabalhoId'] || 0;
        this.form.DtInicio = this.formatData(params['DtInicio']) || '';
        this.form.DtTermino = this.formatData(params['DtTermino']) || '';
        this.totalEtapas = 1;
      }
    });
  }

  formatData(data: string): string {
    if (!data) return '';

    const parts = data.split('T');
    const dataSemHorario = parts[0];

    const [ano, mes, dia] = dataSemHorario.split('-');

    return `${dia}/${mes}/${ano}`;
  }

  updateDisciplinas(novasDisciplinas: any) {
    const disciplinasNovas: IDisciplinasList[] = [];

    novasDisciplinas.map((dscp: any) => {
      const existente = disciplinasNovas.find(
        (dscpN) => dscpN.IdDisciplina == dscp.value
      );

      if (!existente)
        disciplinasNovas.push({
          IdDisciplina: dscp.value,
          TxNomeDisciplina: dscp.name,
          TxResponsavelDisciplina: this.getResponsavelDisciplina(dscp.value),
          ImagemDisciplina: undefined,
        });
      else return;
    });

    this.form.disciplinaList = disciplinasNovas;
  }

  onFileDropped(field: string, file: FileRead, index: number) {
    const formattedBase64 = file.base64.replace(
      /^data:image\/jpeg;base64,/,
      ''
    );

    if (field === 'TxImagemProjeto') {
      this.form.imagemProjeto = {
        IdImagemProjeto: 0,
        NmImagemProjeto: file.name,
        TxImagemProjeto: file.base64,
      };
    }

    if (field) {
      this.form.disciplinaList.map((dscp) => {
        if (dscp.TxNomeDisciplina === field) {
          dscp.ImagemDisciplina = {
            IdImagemProjetoDisciplina: 0,
            TxImagemProjetoDisciplina: file.base64,
            NmImagemProjetoDisciplina: file.name,
          };
        }
      });
    }
  }
}
