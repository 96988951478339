import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DialogModule } from 'primeng/dialog';

import { ComponentsModule } from '../components/components.module';
import { LoginLayoutComponent } from './login/login-layout/login-layout.component';
import { GestaoPerfisLayoutComponent } from './gestao-perfis/gestao-perfis-layout/gestao-perfis-layout.component';
import { GestaoAcessoLayoutComponent } from './gestao-acesso/gestao-acesso-layout/gestao-acesso-layout.component';
import { CriarPerfilLayoutComponent } from './gestao-perfis/criar-perfil-layout/criar-perfil-layout.component';
import { CriarUsuarioLayoutComponent } from './gestao-acesso/criar-usuario-layout/criar-usuario-layout.component';
import { DashboardRhLayoutComponent } from './dashboard-rh/dashboard-rh-layout/dashboard-rh-layout.component';
import { FrenteTrabalhoLayoutComponent } from './frente-trabalho/frente-trabalho-layout/frente-trabalho-layout.component';
import { NovaSenhaComponent } from './nova-senha/nova-senha/nova-senha.component';
import { ApontamentoLayoutComponent } from './apontamento/apontamento-layout/apontamento-layout.component';
import { PontoLayoutComponent } from './ponto/ponto-layout/ponto-layout.component';
import { DashboardEncarregadoLayoutComponent } from './dashboard-encarregado/dashboard-encarregado-layout/dashboard-encarregado-layout.component';
import { AdvertenciaLayoutComponent } from './advertencia/advertencia-layout/advertencia-layout.component';
import { CadastrarEpiLayoutComponent } from './epis/cadastrar-epi-layout/cadastrar-epi-layout.component';
import { SuprimentosLayoutComponent } from './suprimentos/suprimentos-layout/suprimentos-layout.component';
import { FornecedorLayoutComponent } from './fornecedor/fornecedor-layout/fornecedor-layout.component';
import { TermosLayoutComponent } from './ponto/termos-layout/termos-layout.component';
import { NotFoundLayoutComponent } from './not-found/not-found-layout/not-found-layout.component';
import { HorarioDivergenteLayoutComponent } from './ponto/horario-divergente-layout/horario-divergente-layout.component';
import { SelecaoPessoaLayoutComponent } from './ponto/selecao-reeducando-layout/selecao-reeducando-layout.component';
import { PedidoMaterialLayoutComponent } from './pedido-material/pedido-material-layout/pedido-material-layout.component';
import { PlanejamentoLayoutComponent } from './planejamento/planejamento-layout/planejamento-layout.component';
import { DiarioObraLayoutComponent } from './diario-obra/diario-obra-layout/diario-obra-layout.component';
import { ProjetosLayoutComponent } from './projetos/projetos-layout/projetos-layout.component';
import { CadastrarProjetoLayoutComponent } from './projetos/cadastrar-projeto-layout/cadastrar-projeto-layout.component';
import { ListagemEpiLayoutComponent } from './epis/listagem-epi-layout/listagem-epi-layout.component';
import { HomeLayoutComponent } from './home/home-layout/home-layout.component';
import { DisciplinasLayoutComponent } from './disciplinas/disciplinas-layout/disciplinas-layout.component';
import { CadastrarDisciplinaLayoutComponent } from './disciplinas/cadastrar-disciplina-layout/cadastrar-disciplina-layout.component';
import { ExtracaoRelatorioLayoutComponent } from './apontamento/extracao-relatorio-layout/extracao-relatorio-layout.component';
import { ValidarProjetoLayoutComponent } from './engenharia/validar-projeto-layout/validar-projeto-layout.component';
import { RevisaoLayoutComponent } from './projetos/revisao-layout/revisao-layout.component';
import { ListagemFrenteTrabalhoLayoutComponent } from './frente-trabalho/listagem-frente-trabalho-layout/listagem-frente-trabalho-layout.component';
import { EngenhariaLayoutComponent } from './engenharia/engenharia-layout/engenharia-layout.component';
import { AssociarEpiLayoutComponent } from './epis/associar-epi-layout/associar-epi-layout.component';
import { AssociarFrenteTrabalhoLayoutComponent } from './frente-trabalho/associar-frente-trabalho-layout/associar-frente-trabalho-layout.component';
import { CadastrarPessoaLayoutComponent } from './pessoas/cadastrar-pessoa-layout/cadastrar-pessoa-layout.component';
import { ListagemPessoasLayoutComponent } from './pessoas/listagem-pessoas-layout/listagem-pessoas-layout.component';
import { CriarApontamentoLayoutComponent } from './apontamento/criar-apontamento-layout/criar-apontamento-layout.component';
import { ListagemLicencasLayoutComponent } from './licencas/listagem-licencas-layout/listagem-licencas-layout.component';
import { RegistrarLicencaLayoutComponent } from './licencas/registrar-licenca-layout/registrar-licenca-layout.component';
import { AcrescimoLayoutComponent } from './acrescimo/acrescimo-layout/acrescimo-layout.component';
import { EditarAcrescimoLayoutComponent } from './acrescimo/editar-acrescimo-layout/editar-acrescimo-layout.component';
import { HorariosLayoutComponent } from './apontamento/horarios-layout/horarios-layout.component';
import { LogApontamentosLayoutComponent } from './logs/log-apontamentos-layout/log-apontamentos-layout.component';
@NgModule({
  declarations: [
    LoginLayoutComponent,
    GestaoPerfisLayoutComponent,
    CriarPerfilLayoutComponent,
    GestaoAcessoLayoutComponent,
    CriarUsuarioLayoutComponent,
    DashboardRhLayoutComponent,
    FrenteTrabalhoLayoutComponent,
    NovaSenhaComponent,
    ApontamentoLayoutComponent,
    PontoLayoutComponent,
    DashboardEncarregadoLayoutComponent,
    AdvertenciaLayoutComponent,
    CadastrarEpiLayoutComponent,
    SuprimentosLayoutComponent,
    FornecedorLayoutComponent,
    TermosLayoutComponent,
    NotFoundLayoutComponent,
    HorarioDivergenteLayoutComponent,
    SelecaoPessoaLayoutComponent,
    PedidoMaterialLayoutComponent,
    PlanejamentoLayoutComponent,
    DiarioObraLayoutComponent,
    EngenhariaLayoutComponent,
    ProjetosLayoutComponent,
    CadastrarProjetoLayoutComponent,
    ListagemEpiLayoutComponent,
    HomeLayoutComponent,
    DisciplinasLayoutComponent,
    CadastrarDisciplinaLayoutComponent,
    ExtracaoRelatorioLayoutComponent,
    ValidarProjetoLayoutComponent,
    RevisaoLayoutComponent,
    ListagemFrenteTrabalhoLayoutComponent,
    AssociarEpiLayoutComponent,
    AssociarFrenteTrabalhoLayoutComponent,
    CadastrarPessoaLayoutComponent,
    ListagemPessoasLayoutComponent,
    CriarApontamentoLayoutComponent,
    ListagemLicencasLayoutComponent,
    RegistrarLicencaLayoutComponent,
    AcrescimoLayoutComponent,
    EditarAcrescimoLayoutComponent,
    HorariosLayoutComponent,
    LogApontamentosLayoutComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxMaskDirective,
    ComponentsModule,
    PdfViewerModule,
    DialogModule,
  ],
  exports: [
    LoginLayoutComponent,
    GestaoPerfisLayoutComponent,
    CriarPerfilLayoutComponent,
    GestaoAcessoLayoutComponent,
    CriarUsuarioLayoutComponent,
    DashboardRhLayoutComponent,
    FrenteTrabalhoLayoutComponent,
    NovaSenhaComponent,
    ApontamentoLayoutComponent,
    PontoLayoutComponent,
    DashboardEncarregadoLayoutComponent,
    AdvertenciaLayoutComponent,
    CadastrarEpiLayoutComponent,
    SuprimentosLayoutComponent,
    FornecedorLayoutComponent,
    TermosLayoutComponent,
    NotFoundLayoutComponent,
    HorarioDivergenteLayoutComponent,
    SelecaoPessoaLayoutComponent,
    PedidoMaterialLayoutComponent,
    PlanejamentoLayoutComponent,
    DiarioObraLayoutComponent,
    EngenhariaLayoutComponent,
    ProjetosLayoutComponent,
    CadastrarProjetoLayoutComponent,
    ListagemEpiLayoutComponent,
    HomeLayoutComponent,
    DisciplinasLayoutComponent,
    CadastrarDisciplinaLayoutComponent,
    ExtracaoRelatorioLayoutComponent,
    ValidarProjetoLayoutComponent,
    RevisaoLayoutComponent,
    ListagemFrenteTrabalhoLayoutComponent,
    AssociarEpiLayoutComponent,
    AssociarFrenteTrabalhoLayoutComponent,
    CadastrarPessoaLayoutComponent,
    ListagemPessoasLayoutComponent,
    CriarApontamentoLayoutComponent,
    ListagemLicencasLayoutComponent,
    RegistrarLicencaLayoutComponent,
    AcrescimoLayoutComponent,
    EditarAcrescimoLayoutComponent,
    HorariosLayoutComponent,
    LogApontamentosLayoutComponent,
  ],
  providers: [provideEnvironmentNgxMask({ validation: false })],
})
export class LayoutModule {}
