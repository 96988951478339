import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../services/common/http/common.http.service';
import { IAcrescimo } from '../acrescimo-layout/acrescimo-layout.model';

@Component({
  selector: 'app-editar-acrescimo-layout',
  templateUrl: './editar-acrescimo-layout.component.html',
  styleUrls: ['./editar-acrescimo-layout.component.scss'],
})
export class EditarAcrescimoLayoutComponent implements OnInit {
  tableData: IAcrescimo[] = [];
  editando: boolean = false;
  loader: boolean = false;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.activateRoute();
    this.getAcrescimos();
    this.changeDetectorRef.detectChanges();
  }

  form: IAcrescimo = {
    IdAcrescimo: 0,
    TxDescricao: '',
    VlPercentual: 55,
  };

  getAcrescimos(page?: number) {
    this.loader = true;

    this.httpService.useGet('Acrescimo').subscribe({
      next: (response: any) => {
        const data = response;

        this.tableData = data.filter(
          (item: IAcrescimo) => item.IdAcrescimo === this.form.IdAcrescimo
        );

        this.form = {
          ...this.form,
          TxDescricao: this.tableData[0].TxDescricao,
          VlPercentual: this.tableData[0].VlPercentual,
        };

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error(
          'Não foi possível buscar as disciplinas. Tente novamente!'
        );
      },
    });

    this.loader = false;
  }

  editarAcrescimo() {
    this.httpService.usePut('Acrescimo', this.form).add(() => {
      this.router.navigate(['recursos-humanos/acrescimo']);
    });
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['Editando']) {
        this.editando = params['Editando'];

        this.form = {
          IdAcrescimo: Number(params['IdAcrescimo']),
        };
      }
    });
  }
}
