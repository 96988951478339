import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../../layout/layout.module';
import { ComponentsModule } from '../../components/components.module';
import { EncarregadoRoutingModule } from './encarregado-routing.module';
import { ApontamentoComponent } from './apontamento/apontamento/apontamento.component';
import { PontoComponent } from './ponto/ponto/ponto.component';
import { DashboardEncarregadoComponent } from './dashboard-encarregado/dashboard-encarregado/dashboard-encarregado.component';
import { AdvertenciaComponent } from './advertencia/advertencia/advertencia.component';
import { CadastrarEpiComponent } from './epis/cadastrar-epi/cadastrar-epi.component';
import { ListagemEpiComponent } from './epis/listagem-epi/listagem-epi.component';
import { AssociarEpiComponent } from './epis/associar-epi/associar-epi.component';
import { CriarApontamentoComponent } from './apontamento/criar-apontamento/criar-apontamento.component';

@NgModule({
  declarations: [
    ApontamentoComponent,
    CriarApontamentoComponent,
    PontoComponent,
    DashboardEncarregadoComponent,
    AdvertenciaComponent,
    CadastrarEpiComponent,
    ListagemEpiComponent,
    AssociarEpiComponent,
  ],
  imports: [
    CommonModule,
    EncarregadoRoutingModule,
    ComponentsModule,
    LayoutModule,
  ],
  exports: [],
})
export class EncarregadoModule {}
