import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppExportBase64 } from '../../../services/exportBase64/exportBase64.service';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';
import { IPessoaData } from './horarios-layout.model';
@Component({
  selector: 'app-horarios-layout',
  templateUrl: './horarios-layout.component.html',
  styleUrls: ['./horarios-layout.component.scss'],
})
export class HorariosLayoutComponent implements OnInit {
  @Output() inputClose = new EventEmitter<boolean>();
  @Input() pessoaData: IPessoaData = {};
  visible: boolean = true;
  loader: boolean = false;

  constructor(
    private httpService: HttpService,
    private exportBase64Service: AppExportBase64,
    private toast: ToastrService,
    private permissionService: PermissionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permissionService.getPermission();
    this.changeDetectorRef.detectChanges();
  }
}
