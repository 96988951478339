<app-menu>
  <div class="desktop">
    <div class="header">
      <app-titulo text="Engenharia"></app-titulo>
      <div class="header-buttons">
        <app-botao
          type="primario"
          text="Ver projetos"
          width="100%"
          icon="assets/images/graphics.svg"
          (click)="navegarVerProjetos()"
        ></app-botao>
      </div>
    </div>
    <div class="table-header">
      <h1 class="table-title">Validar projetos</h1>
      <div class="table-filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          [(ngModel)]="pesquisaParams.TxNomeProjeto"
          (ngModelChange)="getProjetos()"
        ></app-input>
      </div>
    </div>
    <app-tabela [data]="tableData" [columns]="tableColumns"></app-tabela>
    <app-paginacao
      [pages]="pesquisaParams.MaxPages"
      (selectedPage)="mudarPagina($event)"
    ></app-paginacao>
  </div>

  <div class="mobile">
    <app-titulo text="Engenharia"></app-titulo>
    <div class="listagem">
      <div
        class="menu-mobile"
        (click)="this.expandirCardsMobile = !this.expandirCardsMobile"
      >
        <app-titulo class="title" text="Validar projetos"></app-titulo>
        <img
          class="expandir-icon"
          src="assets/images/arrow-down.svg"
          *ngIf="!expandirCardsMobile"
        />
        <img
          class="expandir-icon"
          src="assets/images/arrow-up.svg"
          *ngIf="expandirCardsMobile"
        />
      </div>
      <div class="mobile-filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          [(ngModel)]="pesquisaParams.TxNomeProjeto"
          (ngModelChange)="getProjetos()"
        ></app-input>
        <app-botao
          type="primario"
          text="Ver projetos"
          width="100%"
          icon="assets/images/graphics.svg"
          (click)="navegarVerProjetos()"
        ></app-botao>
      </div>
      <app-card-mobile
        [data]="tableData"
        [rows]="rowsMobile"
        [expand]="expandirCardsMobile"
      ></app-card-mobile>
      <app-paginacao
        [pages]="pesquisaParams.MaxPages"
        (selectedPage)="mudarPagina($event)"
      ></app-paginacao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
