import { Component, OnInit } from '@angular/core';
import { IFornecedorForm } from './fornecedor-layout.model';

@Component({
  selector: 'app-fornecedor-layout',
  templateUrl: './fornecedor-layout.component.html',
  styleUrls: ['./fornecedor-layout.component.scss'],
})
export class FornecedorLayoutComponent implements OnInit {
  form: IFornecedorForm = {
    nome: '',
    email: '',
    telefone: '',
    endereco: '',
    precoFrete: 0,
    previsaoEntrega: '',
    materiais: [],
  };

  mockedUsuarios = [
    {
      name: 'Higor Santos',
      value: '1',
      cpf: '000.000.000-00',
    },
    {
      name: 'Matheus Batista',
      value: '2',
      cpf: '111.111.111-11',
    },
  ];

  mockedFabricas = [
    {
      name: 'Pedra',
      value: '1',
    },
    {
      name: 'Cimento',
      value: '2',
    },
    {
      name: 'Madeira',
      value: '2',
    },
  ];

  mockedTurnos = [
    {
      name: 'Manhã',
      value: 'manha',
      horario: '07h às 16h',
    },
    {
      name: 'Tarde',
      value: 'tarde',
      horario: '12h às 21h',
    },
  ];

  associarPessoa() {}

  atualizarMateriais(materiais: any) {
    this.form.materiais = materiais;
  }

  constructor() {}

  ngOnInit() {}
}
