import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editar-acrescimo',
  templateUrl: './editar-acrescimo.component.html',
  styleUrls: ['./editar-acrescimo.component.scss'],
})
export class EditarAcrescimoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
