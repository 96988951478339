import { NgModule } from '@angular/core';
import { LayoutModule } from '../../layout/layout.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotFoundRoutingModule } from './not-found-routing.module';

@NgModule({
  declarations: [NotFoundComponent],
  imports: [NotFoundRoutingModule, LayoutModule],
  exports: [],
})
export class NotFoundModule {}
