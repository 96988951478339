import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { InterceptorService } from './services/interceptor.service';
import { LoginModule } from './modules/login/login.module';
import { GestaoRhModule } from './modules/gestao-rh/gestao-rh.module';
import { EncarregadoModule } from './modules/encarregado/encarregado.module';
import { SuprimentosModule } from './modules/suprimentos/suprimentos.module';
import { NotFoundModule } from './modules/not-found/not-found.module';
import { PlanejamentoModule } from './modules/planejamento/planejamento.module';
import { EngenhariaModule } from './modules/engenharia/engenharia.module';
import { HomeModule } from './modules/home/home.module';
import { LogsModule } from './modules/logs/logs.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SweetAlert2Module.forRoot(),
    LoginModule,
    GestaoRhModule,
    EncarregadoModule,
    SuprimentosModule,
    PlanejamentoModule,
    EngenhariaModule,
    HomeModule,
    LogsModule,
    NotFoundModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    provideEnvironmentNgxMask({ validation: false }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
