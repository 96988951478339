import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ITableData, IGetEpiResponse } from './listagem-epi-layout.model';
import { ToastrService } from 'ngx-toastr';
import { AppExportBase64 } from '../../../services/exportBase64/exportBase64.service';
import Swal from 'sweetalert2';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';

@Component({
  selector: 'app-listagem-epi-layout',
  templateUrl: './listagem-epi-layout.component.html',
  styleUrl: './listagem-epi-layout.component.scss',
})
export class ListagemEpiLayoutComponent {
  permission: number = 2;
  tableData: ITableData[] = [];
  epis: Array<any> = [];
  expandirCardsMobile: boolean = false;
  counter = 0;
  timer: ReturnType<typeof setTimeout> | null = null;
  loader: boolean = false;

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private http: HttpClient,
    private httpService: HttpService,
    private toast: ToastrService,
    private exportBase64Service: AppExportBase64,
    private cdDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getEpis();
    this.cdDetectorRef.detectChanges();
  }

  pesquisaParams = {
    Descricao: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
  };

  tableColumns = [
    { header: 'Equipamento', key: 'equipamento', type: 'text' },
    { header: 'Área', key: 'areaEpi', type: 'text' },
    { header: 'Prazo', key: 'prazoEpi', type: 'text' },
    { header: 'Qtd. EPIs', key: 'quantidadeEpi', type: 'text' },
    {
      header: 'Qtd. Pessoas',
      key: 'quantidadePessoa',
      type: 'text',
    },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Associar',
          img: 'assets/images/user-plus.svg',
          action: (item: any) => this.navegarAssociarEpi(item),
          condition: (item: any) => true,
        },
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          label: 'Deletar',
          img: 'assets/images/del.svg',
          action: (item: any) => this.deleteItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    { header: 'Equipamento', key: 'equipamento', type: 'text', default: true },
    { header: 'Área', key: 'areaEpi', type: 'text' },
    { header: 'Prazo', key: 'prazoEpi', type: 'text' },
    { header: 'Qtd. EPIs', key: 'quantidadeEpi', type: 'text' },
    {
      header: 'Qtd. Pessoas',
      key: 'quantidadePessoa',
      type: 'text',
    },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          label: 'Deletar',
          img: 'assets/images/del.svg',
          action: (item: any) => this.deleteItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  visualizarMotivo(item: any) {}

  visualizarJustificativa(item: any) {}

  navegarCadastrarEpi() {
    this.router.navigate(['encarregado/cadastrar-epi']);
  }

  getEpis() {
    this.loader = true;

    this.httpService.useGet('EPIByFilter', this.pesquisaParams).subscribe(
      (response: any) => {
        this.epis = response.DataSet;
        const data = response.DataSet;

        const area: any = {
          1: 'Elétrica',
          2: 'Construção',
          3: 'Alimentícia',
        };

        this.tableData = data.map(
          (item: IGetEpiResponse) =>
            ({
              idEpi: item.Id,
              equipamento: item.Descricao,
              areaEpi: area[item.Area] ?? '--',
              prazoEpi: `${item.Prazo} dias`,
              quantidadeEpi: item.Quantidade,
              quantidadePessoa: item.QuantidadeReed,
              areaId: item.Area,
            } as ITableData)
        );

        this.loader = false;
      },
      (error) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      }
    );
  }

  onChangeFilter(event: any) {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.counter++;

      this.getEpis();
    }, 250);
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getEpis();
  }

  editItem(item: any) {
    const prazoEpi = item.prazoEpi.split('dias')[0].trim();

    this.router.navigate(['encarregado/cadastrar-epi'], {
      queryParams: {
        idEpi: item.idEpi,
        equipamento: item.equipamento,
        areaEpi: item.areaEpi,
        areaId: item.areaId,
        prazoEpi: prazoEpi,
        quantidadeEpi: item.quantidadeEpi,
        quantidadePessoa: item.quantidadePessoa,
        isMode: 2,
      },
    });
  }

  deleteItem(item: any) {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realizar a exclusão do EPI? Todos os pessoas associados serão desvinculados.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: 'green',
      cancelButtonText: 'Não',
      cancelButtonColor: 'red',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loader = true;

        this.http
          .delete(environment.url + `/v1/EPI?id=${item.idEpi}`)
          .subscribe({
            next: (response: any) => {
              this.loader = false;
              Swal.fire({
                title: 'Sucesso',
                text: 'EPI excluída com sucesso.',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: 'green',
              });
              this.getEpis();
            },
            error: (error: any) => {
              this.loader = false;
              Swal.fire({
                title: 'Erro!',
                text: 'Ocorreu um erro ao deletar a EPI.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: 'green',
              });
              this.getEpis();
            },
          });
      }
    });
  }

  navegarAssociarEpi(item: any) {
    this.router.navigate(['encarregado/associar-epi'], {
      queryParams: {
        idEpi: item.idEpi,
        equipamento: item.equipamento,
        areaEpi: item.areaEpi,
        areaId: item.areaId,
        isMode: 2,
      },
    });
  }
}
