import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { IGetPerfilResponse, ITableData } from './gestao-perfils-layout.model';
import Swal from 'sweetalert2';
import { AppExportBase64 } from '../../../services/exportBase64/exportBase64.service';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';

@Component({
  selector: 'app-gestao-perfis-layout',
  templateUrl: './gestao-perfis-layout.component.html',
  styleUrls: ['./gestao-perfis-layout.component.scss'],
})
export class GestaoPerfisLayoutComponent implements OnInit {
  permission: number = 2;
  expandirMobile: boolean = false;
  loader: boolean = false;
  tableData: ITableData[] | Array<any> = [];
  counter = 0;
  timer: ReturnType<typeof setTimeout> | null = null;
  gestaoAcesso: Array<any> = [];

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private toast: ToastrService,
    private exportBase64Service: AppExportBase64,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getPerfis();
    this.changeDetectorRef.detectChanges();
  }

  form = {
    pesquisar: '',
  };

  tableColumns = [
    { header: 'Nome', key: 'nome', type: 'text' },
    {
      header: 'Situação',
      key: 'situacao',
      type: 'select',
      options: [
        { value: 'ativo', label: 'Ativo' },
        { value: 'inativo', label: 'Inativo' },
      ],
    },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          label: 'Deletar',
          img: 'assets/images/del.svg',
          action: (item: any) => this.deleteItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    { header: 'Nome', key: 'nome', type: 'text', default: true },
    { header: 'Situação', key: 'situacao', type: 'text', default: true },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          label: 'Deletar',
          img: 'assets/images/del.svg',
          action: (item: any) => this.deleteItem(item),
          condition: (item: any) => true,
        },
      ],
      default: true,
    },
  ];

  onChangeSearch(event: any) {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.counter++;
      this.getPerfis(event);
    }, 500);
  }

  getPerfis(pesquisar?: string) {
    this.loader = true;

    let params: { [key: string]: any } = {};
    if (pesquisar !== '' && pesquisar) {
      params['param'] = this.form.pesquisar;
    }

    this.httpService.useGet('PerfilByFilter', params).subscribe({
      next: (response: any) => {
        this.gestaoAcesso = response.GestaoAcesso;
        this.tableData = response.Perfis.map((item: any) => {
          return {
            id: item.IdPerfil,
            nome: item.TxNomePerfil,
            situacao: item.FlStatusPerfil === true ? 'ativo' : 'inativo',
          };
        });

        this.loader = false;
      },
      error: (_error: any) => {
        this.toast.error(
          'Não foi possível buscar apontamentos. Tente novamente!'
        );

        this.loader = false;
      },
    });
  }

  editItem(item: any) {
    const gestaoAcessoItem = this.gestaoAcesso.find(
      (acesso) => acesso.PerfilId === item.id
    );

    if (gestaoAcessoItem) {
      this.router.navigate(['recursos-humanos/gestao-de-perfis/criar-perfil'], {
        queryParams: {
          editando: true,
          perfilId: item.id,
          gestaoAcesso: JSON.stringify(gestaoAcessoItem),
          txPerfil: item.nome,
          situacao: item.situacao,
        },
      });
    } else {
      Swal.fire({
        title: 'Erro!',
        text: 'Perfil não encontrado na gestão de acesso!',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  }

  deleteItem(item: any) {
    this.loader = true;
    this.httpService.useDelete('Perfil', item.id).add(() => {
      this.loader = false;
      Swal.fire({
        title: 'Sucesso!',
        text: 'Perfil deletado com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok',
      });
      this.getPerfis();
    });
  }

  exportarExcel() {
    this.loader = true;
    const body = {
      TxPesquisar: this.form.pesquisar,
    };

    this.httpService.usePostFilter('PerfilXlsx', body).subscribe({
      next: (response: any) => {
        this.exportBase64Service.downloadExcel(
          response.XlsxBase64,
          'gestao-perfis'
        );
        this.loader = false;
      },
      error: (_error) => {
        this.toast.success(
          'Ocorreu um erro ao exportar excel. Tente novamente!'
        );
        this.loader = false;
      },
    });
  }

  createEditPayload(event: any, gestaoAcessoItem: any) {
    const payload = {
      Perfil: {
        IdPerfil: event.item.id,
        TxNomePerfil: event.item.nome,
        FlStatusPerfil: event.item.situacao === 'ativo' ? true : false,
        GestaoAcesso: {
          IdGestaoAcesso: gestaoAcessoItem.IdGestaoAcesso,
          PerfilId: gestaoAcessoItem.IdPerfil,
          NuSistemaGestaoAcesso: gestaoAcessoItem.NuSistemaGestaoAcesso,
          NuAdmin: gestaoAcessoItem.NuAdmin,
          NuRecursosHumano: gestaoAcessoItem.NuRecursosHumano,
          NuDashboardRecursosHumano: gestaoAcessoItem.NuDashboardRecursosHumano,
          NuDashboardEncarregado: gestaoAcessoItem.NuDashboardEncarregado,
          NuEPI: gestaoAcessoItem.NuEPI,
          NuGestaoAcesso: gestaoAcessoItem.NuGestaoAcesso,
          NuApontamentoHora: gestaoAcessoItem.NuApontamentoHora,
          NuAdvertencia: gestaoAcessoItem.NuAdvertencia,
        },
      },
      GestaoAcesso: {
        IdGestaoAcesso: gestaoAcessoItem.IdGestaoAcesso,
        PerfilId: gestaoAcessoItem.IdPerfil,
        NuSistemaGestaoAcesso: gestaoAcessoItem.NuSistemaGestaoAcesso,
        NuAdmin: gestaoAcessoItem.NuAdmin,
        NuRecursosHumano: gestaoAcessoItem.NuRecursosHumano,
        NuDashboardRecursosHumano: gestaoAcessoItem.NuDashboardRecursosHumano,
        NuDashboardEncarregado: gestaoAcessoItem.NuDashboardEncarregado,
        NuEPI: gestaoAcessoItem.NuEPI,
        NuGestaoAcesso: gestaoAcessoItem.NuGestaoAcesso,
        NuApontamentoHora: gestaoAcessoItem.NuApontamentoHora,
        NuAdvertencia: gestaoAcessoItem.NuAdvertencia,
      },
    };

    return payload;
  }

  onChangeSelect(event: any) {
    const gestaoAcessoItem = this.gestaoAcesso.find(
      (acesso) => acesso.PerfilId === event.item.id
    );
    this.loader = true;
    const payload = this.createEditPayload(event, gestaoAcessoItem);

    this.httpService.usePut('PerfilNGestaAcesso', payload).add(() => {
      this.toast.success('Situação editada com sucesso!');
      this.loader = false;
      this.router.navigate(['recursos-humanos/gestao-de-perfis/']);
    });
  }

  criarPerfil() {
    this.router.navigate(['recursos-humanos/gestao-de-perfis/criar-perfil']);
  }

  expandirCardsMobile() {
    this.expandirMobile = !this.expandirMobile;
  }
}
