<app-menu>
  <div class="desktop">
    <div class="header">
      <app-titulo text="Apontamento de horas"></app-titulo>
      <div class="header-buttons">
        <app-botao
          type="primario"
          text="Adicionar ponto"
          width="100%"
          icon="assets/images/add.svg"
          (click)="navegarPonto()"
          *ngIf="this.permission === 0"
        ></app-botao>
        <!-- <app-botao
            type="primario"
            text="Adicionar ponto manual"
            width="100%"
            icon="assets/images/add.svg"
            (click)="navegarPontoManual()"
            *ngIf="this.permission === 0"
          ></app-botao> -->
        <app-botao
          type="secundario"
          text="Extração apontamento diário"
          width="100%"
          icon="assets/images/download-icon.svg"
          (click)="apontamentoDiario()"
        ></app-botao>
        <app-botao
          type="secundario"
          text="Extração de relatório"
          width="100%"
          icon="assets/images/download-icon.svg"
          (click)="extracaoRelatorio()"
        ></app-botao>
        <app-botao
          type="secundario"
          text="Extração de advertências"
          width="100%"
          icon="assets/images/download-icon.svg"
          (click)="extracaoAdvertencia()"
        ></app-botao>
      </div>
    </div>
    <div class="table-header">
      <h1 class="table-title">Apontamentos</h1>
      <div class="table-filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          [(ngModel)]="pesquisaParams.TxPesquisar"
          (ngModelChange)="getApontamentos()"
        ></app-input>
        <app-input
          type="date"
          placeholder="Data inicial"
          [(ngModel)]="pesquisaParams.DtInicio"
          (blurEvent)="getApontamentos()"
        ></app-input>
        <app-input
          type="date"
          placeholder="Data final"
          [(ngModel)]="pesquisaParams.DtFim"
          (blurEvent)="getApontamentos()"
        ></app-input>
      </div>
    </div>
    <app-tabela [data]="tableData" [columns]="tableColumns"></app-tabela>
    <div class="paginacao">
      <app-paginacao
        [pages]="this.pesquisaParams.MaxPages"
        (selectedPage)="mudarPagina($event)"
      ></app-paginacao>
    </div>
  </div>
  <div class="mobile">
    <app-titulo text="Apontamento de horas"></app-titulo>
    <div class="apontamentos">
      <div class="menu-mobile">
        <app-titulo text="Apontamentos"></app-titulo>
        <img
          *ngIf="!expandirCardsMobile"
          (click)="expandirCardsMobile = !expandirCardsMobile"
          src="assets/images/arrow-down.svg"
          class="expandir-icon"
        />
        <img
          *ngIf="expandirCardsMobile"
          (click)="expandirCardsMobile = !expandirCardsMobile"
          src="assets/images/arrow-up.svg"
          class="expandir-icon"
        />
      </div>
      <div class="table-filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          [(ngModel)]="pesquisaParams.TxPesquisar"
          (ngModelChange)="getApontamentos()"
        ></app-input>
        <app-input
          type="date"
          placeholder="Data inicial"
          [(ngModel)]="pesquisaParams.DtInicio"
          (blurEvent)="getApontamentos()"
          *ngIf="this.expandirCardsMobile"
        ></app-input>
        <app-input
          type="date"
          placeholder="Data final"
          [(ngModel)]="pesquisaParams.DtFim"
          (blurEvent)="getApontamentos()"
          *ngIf="this.expandirCardsMobile"
        ></app-input>
      </div>
      <div class="mobile-buttons" *ngIf="this.expandirCardsMobile">
        <app-botao
          type="primario"
          text="Adicionar ponto"
          width="100%"
          icon="assets/images/add.svg"
          (click)="navegarPonto()"
        ></app-botao>
      </div>
      <app-card-mobile
        [data]="tableData"
        [rows]="rowsMobile"
        [expand]="expandirCardsMobile"
      >
      </app-card-mobile>
      <app-paginacao
        [pages]="this.pesquisaParams.MaxPages"
        (selectedPage)="mudarPagina($event)"
      ></app-paginacao>
      <app-botao
        type="secundario"
        text="Apontamento diário"
        width="100%"
        icon="assets/images/download-icon.svg"
        (click)="apontamentoDiario()"
      ></app-botao>
      <app-botao
        type="secundario"
        width="100%"
        text="Extração de relatório"
        icon="assets/images/download-icon.svg"
        (click)="extracaoRelatorio()"
      ></app-botao>
    </div>
  </div>
</app-menu>

<app-horarios-layout
  *ngIf="visibleHorariosApontados"
  [pessoaData]="pessoaData"
  (inputClose)="verHorarios()"
></app-horarios-layout>
<app-extracao-relatorio-layout
  *ngIf="visibleExtracaoRelatorio"
  (inputClose)="extracaoRelatorio()"
></app-extracao-relatorio-layout>
<app-loader *ngIf="loader"></app-loader>
