<app-menu>
  <div class="desktop">
    <div class="header">
      <app-titulo text="Cadastrar pedido"></app-titulo>
    </div>
    <div class="form">
      <app-input
        type="text"
        width="100%"
        label="Fornecedor"
        name="nome"
        [(ngModel)]="form.nome"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Email"
        name="email"
        [(ngModel)]="form.email"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Telefone/Celular"
        name="telefone"
        [(ngModel)]="form.telefone"
      ></app-input>
      <app-input
        type="select"
        width="100%"
        label="Obra destinada"
        name="endereco"
        [(ngModel)]="form.endereco"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Endereço"
        name="endereco"
        [(ngModel)]="form.endereco"
      ></app-input>
      <app-select-adicao
        label="Material fornecido"
        [selectData]="mockedFabricas"
        (items)="atualizarMateriais($event)"
      ></app-select-adicao>
      <div>
        <label *ngIf="this.form.materiais.length > 0"
          >Quantidade do material (Kg)</label
        >
        <ul class="materials">
          <div
            class="materials-div"
            *ngFor="let material of this.form.materiais"
          >
            <li>
              {{ material.name }}
            </li>
            <app-input type="text"></app-input>
          </div>
        </ul>
      </div>
      <app-input
        type="money"
        width="100%"
        label="Preço do frete"
        name="precoFrete"
        [(ngModel)]="form.precoFrete"
      ></app-input>
      <app-input
        type="money"
        width="100%"
        label="Valor total"
        name="precoFrete"
        [(ngModel)]="form.precoFrete"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Previsão de entrega"
        name="previsaoEntrega"
        [(ngModel)]="form.previsaoEntrega"
      ></app-input>
    </div>
    <div class="form-button">
      <app-botao
        width="100%"
        text="Cadastrar fornecedor"
        (click)="associarPessoa()"
      ></app-botao>
    </div>
  </div>
</app-menu>
