import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppGuard } from '../../services/auth.guard';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AppGuard],
    data: {
      title: 'Início | BUILTUP',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
