<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo text="Associar frente de trabalho"></app-titulo>
    </div>

    <form #frenteDeTrabalhoForm="ngForm">
      <app-input
        type="text"
        label="Frente de trabalho"
        name="tipoFrenteDeTrabalho"
        [(ngModel)]="form.NomeFrenteTrabalho"
        [disabled]="true"
      ></app-input>
      <app-input
        type="text"
        label="Proprietário"
        name="proprietario"
        [(ngModel)]="form.Proprietario"
        [disabled]="true"
      ></app-input>
      <app-input
        type="text"
        label="Encarregado"
        name="encarregado"
        [(ngModel)]="form.Encarregado"
        [disabled]="true"
      ></app-input>
      <app-select-check
        label="Pessoa(s)"
        name="pessoa"
        [selectData]="pessoaOptions"
        [(ngModel)]="form.PessoaIds"
        (ngModelChange)="changeSelect($event)"
        [requiredInput]="true"
      ></app-select-check>
      <div>
        <label *ngIf="this.form.PessoaIds.length === 1"
          >Pessoa selecionado ({{ this.form.PessoaIds.length }})</label
        >
        <label *ngIf="this.form.PessoaIds.length > 1"
          >Pessoas selecionados ({{ this.form.PessoaIds.length }})</label
        >
        <ul class="pessoa-list">
          <li *ngFor="let pessoa of pessoaList">
            {{ pessoa }}
          </li>
        </ul>
      </div>
    </form>
    <div class="form-buttons-unique">
      <app-botao
        text="Associar"
        (click)="associarFrenteDeTrabalho()"
        *ngIf="this.permission === 0"
      ></app-botao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
