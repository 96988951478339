@if (type === 'primario') {
<button
  class="primario"
  [style.width]="width"
  [style.color]="color"
  [style.backgroundColor]="bgColor"
  [style.border]="border"
  [disabled]="disabled"
>
  <img [src]="icon" class="icon-photo" />
  {{ text }}
</button>
} @if (type === 'secundario') {
<button
  class="secundario"
  [style.width]="width"
  [style.color]="color"
  [style.backgroundColor]="bgColor"
  [style.border]="border"
  [disabled]="disabled"
>
  <img [src]="icon" class="icon-photo" />
  {{ text }}
</button>
}
