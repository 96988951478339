import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../services/common/http/common.http.service';
import {
  ILicencaOptions,
  IPessoaOptions,
  IRegistrarLicencaForm,
} from './registrar-licenca-layout.model';
import { FileRead } from '../../../components/drag-drop/drag-drop.model';
import { format } from 'date-fns';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registrar-licenca-layout',
  templateUrl: './registrar-licenca-layout.component.html',
  styleUrls: ['./registrar-licenca-layout.component.scss'],
})
export class RegistrarLicencaLayoutComponent implements OnInit {
  pessoaOptions: IPessoaOptions[] = [];
  licencaOptions: ILicencaOptions[] = [];
  editando: boolean = false;
  loader: boolean = false;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getPessoas();
    this.getLicencas();
    this.activateRoute();
    this.changeDetectorRef.detectChanges();
  }

  form: IRegistrarLicencaForm = {
    PessoaId: 0,
    LicencaId: 0,
    DtInicio: '',
    DtFim: '',
    ImagemLicenca: {
      TxImagem: '',
      NmImagem: '',
    },
  };

  getPessoas() {
    this.loader = true;

    this.httpService.useGet('Pessoa').subscribe({
      next: (response: any[]) => {
        this.pessoaOptions = response.map((x) => {
          return {
            name: x.TxNome,
            value: x.IdPessoa,
          };
        });

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }

  getLicencas() {
    this.loader = true;

    this.httpService.useGet('Licencas').subscribe({
      next: (response: any[]) => {
        this.licencaOptions = response.map((x) => {
          return {
            value: x.IdLicenca,
            name: x.TxNome,
            IdLicenca: x.IdLicenca,
            TxNome: x.TxNome,
            QtDias: x.QtDias,
          };
        });
        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }

  setDiasLicenca(id: number) {
    const licenca = this.licencaOptions.find((x) => x.value === id);

    if (this.form.DtInicio) {
      const dtInicioDate = new Date(this.form.DtInicio);
      const qtDias = licenca?.QtDias || 0;
      const dtFimDate = new Date(dtInicioDate);

      dtFimDate.setDate(dtInicioDate.getDate() + qtDias);
      const formattedDtFimDate = format(dtFimDate, "yyyy-MM-dd'T'HH:mm");

      this.form.DtFim = formattedDtFimDate;
    }
  }

  cadastrarLicenca() {
    if (this.editando) {
      const params = {
        IdLicencaPessoa: this.form.IdLicencaPessoa,
        DtInicio: this.form.DtInicio,
      };

      this.httpService.usePatch('TempoLicenca', this.form).subscribe({
        next: () => {
          Swal.fire({
            title: 'Sucesso!',
            text: `Licença editada com sucesso!`,
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: 'green',
          });
          this.router.navigate(['recursos-humanos/licencas']);
        },
        error: () => {
          Swal.fire({
            title: 'Atenção!',
            text: 'Erro ao editar a licença.',
            icon: 'warning',
            confirmButtonText: 'Ok',
          });
        },
      });
      return;
    }

    this.httpService.usePost('LicencaCriar', this.form).add(() => {
      this.router.navigate(['recursos-humanos/licencas']);
    });
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['Editando']) {
        this.editando = params['Editando'];
        const dtInicio = format(params['DtInicio'], "yyyy-MM-dd'T'HH:mm");
        const dtFim = format(params['DtFim'], "yyyy-MM-dd'T'HH:mm");

        this.setForm({
          LicencaId: +params['LicencaId'],
          IdLicencaPessoa: +params['IdLicencaPessoa'],
          PessoaId: +params['PessoaId'],
          DtInicio: dtInicio,
          DtFim: dtFim,
        });
      }
    });
  }

  setForm(params: any) {
    this.form = {
      ...this.form,
      ...params,
    };
  }

  onSelectChange(id: number, type: 'pessoa' | 'licenca') {
    if (type === 'pessoa') this.form.PessoaId = id;
    if (type === 'licenca') {
      this.form.LicencaId = id;
      this.setDiasLicenca(id);
    }

    this.changeDetectorRef.detectChanges();
  }

  onFileDropped(field: string, file: FileRead) {
    const formattedBase64 = file.base64.replace(
      /^data:image\/jpeg;base64,/,
      ''
    );

    if (field == 'fotoLicenca') {
      this.form.ImagemLicenca.TxImagem = formattedBase64;
      this.form.ImagemLicenca.NmImagem = file.name;
    }
  }
}
