<app-menu>
  <div class="desktop">
    <div class="header">
      <app-titulo text="Acessos"></app-titulo>
      <div class="header-buttons">
        <app-botao
          type="primario"
          text="Criar usuário"
          width="100%"
          icon="assets/images/add.svg"
          (click)="criarUsuario()"
          *ngIf="this.permission === 0"
        ></app-botao>
        <app-botao
          type="secundario"
          text="Exportar Excel"
          width="100%"
          icon="assets/images/download-icon.svg"
          (click)="exportarExcel()"
        ></app-botao>
      </div>
    </div>
    <div class="table-header">
      <h1 class="table-title">Usuários</h1>
      <div class="filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          [(ngModel)]="form.pesquisar"
          (ngModelChange)="pesquisar($event)"
        ></app-input>
      </div>
    </div>
    <app-tabela [data]="usuarios" [columns]="tableColumns"></app-tabela>
  </div>
  <div class="mobile">
    <app-titulo text="Acessos"></app-titulo>
    <div class="menu-mobile">
      <app-titulo text="Usuários"></app-titulo>
      <img
        *ngIf="!expandirCardsMobile"
        (click)="expandirCardsMobile = !expandirCardsMobile"
        src="assets/images/arrow-down.svg"
        class="expandir-icon"
      />
      <img
        *ngIf="expandirCardsMobile"
        (click)="expandirCardsMobile = !expandirCardsMobile"
        src="assets/images/arrow-up.svg"
        class="expandir-icon"
      />
    </div>
    <div class="table-filters">
      <app-input
        type="search"
        placeholder="Pesquisar"
        icon="assets/images/search.svg"
        [(ngModel)]="form.pesquisar"
        (ngModelChange)="pesquisar($event)"
      ></app-input>
    </div>
    <div class="mobile-buttons" *ngIf="expandirCardsMobile">
      <app-botao
        type="primario"
        text="Criar usuário"
        width="100%"
        icon="assets/images/add.svg"
        (click)="criarUsuario()"
        *ngIf="this.permission === 0"
      ></app-botao>
    </div>
    <app-card-mobile
      [rows]="rowsMobile"
      [data]="usuarios"
      [expand]="expandirCardsMobile"
    ></app-card-mobile>
    <app-botao
      type="secundario"
      text="Exportar Excel"
      width="100%"
      icon="assets/images/download-icon.svg"
      (click)="exportarExcel()"
    ></app-botao>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
