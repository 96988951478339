import { Funcionario } from './../../projetos/projetos-layout/projetos-layout.model';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { DataSet, Root } from './disciplinas-layout.model';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';
import { formatTel } from '../../../utils/utility-functions';

@Component({
  selector: 'app-disciplinas-layout',
  templateUrl: './disciplinas-layout.component.html',
  styleUrls: ['./disciplinas-layout.component.scss'],
})
export class DisciplinasLayoutComponent implements OnInit {
  tableData: any[] = [];
  expandirCardsMobile: boolean = false;
  loader: boolean = false;

  pesquisaParams = {
    TxPesquisar: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
  };

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permissionService.getPermission();
    this.getDisciplinas();
    this.changeDetectorRef.detectChanges();
  }

  tableColumns = [
    { header: 'Disciplina', key: 'NomeDisciplina', type: 'text' },
    { header: 'Responsável', key: 'NmResponsavel', type: 'text' },
    { header: 'Telefone', key: 'TxTelefone', type: 'text' },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          label: 'Deletar',
          img: 'assets/images/del.svg',
          action: (item: any) => this.deleteItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    {
      header: 'Disciplina',
      key: 'NomeDisciplina',
      type: 'text',
      default: true,
    },
    {
      header: 'Responsável',
      key: 'NmResponsavel',
      type: 'text',
      default: true,
    },
    { header: 'Telefone', key: 'TxTelefone', type: 'text' },
    {
      header: 'Ações',
      key: 'acoes',
      type: 'buttons',
      buttons: [
        {
          img: 'assets/images/edit.svg',
          label: 'Editar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
        {
          img: 'assets/images/del.svg',
          label: 'Deletar',
          action: (item: any) => this.deleteItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  getDisciplinas(page?: number) {
    this.loader = true;
    const params = this.pesquisaParams;

    this.httpService.useGet('DisciplinaByFilter', params).subscribe({
      next: (response: Root) => {
        const data = response.DataSet;

        this.tableData = data.map((item: DataSet) => {
          return {
            IdDisciplina: item.Disciplina.IdDisciplina,
            NomeDisciplina: item.Disciplina.NomeDisciplina ?? 'Não Informado',
            NmResponsavel:
              item.Funcionario.TxNomeFuncionario ?? 'Não Informado',
            TxTelefone:
              formatTel(item.Funcionario.TxTelefoneFuncionario) ??
              'Não Informado',
          };
        });
        this.pesquisaParams.MaxPages =
          Math.ceil(response.Total / this.pesquisaParams.TamanhoPagina) || 1;

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error(
          'Não foi possível buscar as disciplinas. Tente novamente!'
        );
      },
    });
  }

  onChangeSearch(search: string) {
    this.pesquisaParams.TxPesquisar = search;
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getDisciplinas();
  }

  editItem(item: any) {
    const disciplinaItem = this.tableData.find(
      (x: any) => x.IdDisciplina === item.IdDisciplina
    );

    if (disciplinaItem) {
      this.router.navigate(['engenharia/cadastrar-disciplina'], {
        queryParams: {
          IdDisciplina: disciplinaItem.IdDisciplina,
          NomeDisciplina: disciplinaItem.NomeDisciplina,
          FuncionarioId: disciplinaItem.FuncionarioId,
          Editando: true,
        },
      });
    } else {
      Swal.fire({
        title: 'Atenção!',
        text: 'Disciplina não encontrada.',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }

  deleteItem(item: any) {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realizar a exclusão da disciplina?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: 'green',
      confirmButtonText: 'Sim',
      cancelButtonColor: 'red',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loader = true;
        const idDisciplina = item.IdDisciplina;

        this.httpService
          .useDelete('DisciplinaDelete', { idDisciplina })
          .add(() => {
            this.getDisciplinas();
            this.loader = false;
          });
      }
    });
  }

  navegarVerProjetos() {
    this.router.navigate(['engenharia/projetos']);
  }

  navegarCadastrarArea() {
    this.router.navigate(['engenharia/cadastrar-disciplina']);
  }
}
