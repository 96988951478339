import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import moment from 'moment';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { IHorarioDivergenteForm } from './horario-divergente.model';
import { HttpService } from '../../../services/common/http/common.http.service';

@Component({
  selector: 'app-horario-divergente-layout',
  templateUrl: './horario-divergente-layout.component.html',
  styleUrls: ['./horario-divergente-layout.component.scss'],
})
export class HorarioDivergenteLayoutComponent implements OnInit, AfterViewInit {
  @Input() dadosPessoa: any = {};
  loader: boolean = false;
  isMode: number = 0;
  image: string = '';
  nameImage: string = '';
  imagePath: any;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.atualizarForm();
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.isMode = params['isMode'];
      const idPessoa = params['IdPessoa'];
      const ApontamentoHoraId = params['idApontamento'];
      const FlArquivo = true;

      this.form.apontamentoHoraId = ApontamentoHoraId;
      if (params['isMode'] == 0) {
        this.httpService
          .useGet('DivergenciaById', { ApontamentoHoraId, FlArquivo })
          .subscribe({
            next: (response: any) => {
              this.nameImage =
                response.Divergencia['ImagemJustificativa'][
                  'NmImagemJustificativa'
                ];
              this.image =
                response.Divergencia['ImagemJustificativa'][
                  'TxImagemJustificativa'
                ];
              this.form.motivo = Number(
                response.Divergencia.NuMotivoDivergencia
              );

              this.form.horario = this.getHours(
                response.Divergencia.ApontamentoHora.DtCriacao
              );
              this.form.turno = this.getTurno(
                response.Divergencia.ApontamentoHora.NuTurnoApontamento
              );
              this.changeDetectorRef.detectChanges();
              this.loader = false;
            },
          });
      }
      this.httpService.useGet('PessoaByFilter', { idPessoa }).subscribe({
        next: (response: any) => {
          this.form.nome = response.DataSet[0].TxNome;
          this.form.cpf = response.DataSet[0].TxCPF ?? 'Não Informado';
          this.loader = false;
        },
      });
    });
  }

  getTurno = (turno: number): string => {
    switch (turno) {
      case 0:
        return 'Manhã';
      case 1:
        return 'Tarde';
      case 2:
        return 'Noite';
      default:
        return 'Não Informado';
    }
  };

  getHours(date: Date): string {
    if (date !== null) {
      return moment(date).format('HH:mm');
    }
    return 'Não Informado';
  }

  form: IHorarioDivergenteForm = {
    nome: '',
    cpf: '',
    alocacao: '',
    turno: '',
    horario: '',
    motivo: 0,
    justificativa: '',
    apontamentoHoraId: 0,
  };

  atualizarForm() {
    this.form = {
      nome: this.dadosPessoa.TxNome ?? 'Não Informado',
      cpf: this.dadosPessoa.TxCPF ?? 'Não Informado',
      alocacao: this.dadosPessoa.FrenteTrabalhoId ?? 'Não Informado',
      turno: this.getTurno(this.dadosPessoa.NuTurno),
      horario: this.getHours(this.dadosPessoa.DtHorario),
      motivo: this.form.motivo,
      justificativa: '',
      apontamentoHoraId: this.dadosPessoa.ApontamentoHoraId,
    };
  }

  salvarPonto() {
    this.loader = true;
    const payload = this.createPayload();

    this.httpService.usePostFilter('HorarioDivergente', payload).subscribe({
      next: (_response) => {
        this.loader = false;
        Swal.fire({
          title: 'Successo!',
          text: 'Horário salvo com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        }).then((result) => {
          this.router.navigate(['encarregado/apontamentos']);
        });
      },
      error: (error) => {
        this.loader = false;
        Swal.fire({
          title: 'Erro!',
          text: 'Erro ao salvar o horário. Tente novamente!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      },
    });
  }

  createPayload() {
    const body = {
      ApontamentoHoraId: this.form.apontamentoHoraId,
      NuMotivoDivergencia: Number(this.form.motivo),
      TxImagem: this.form.justificativa,
      NmImagem: 'anexo',
    };
    return body;
  }

  onFileDropped(field: string, base64: string) {
    const formattedBase64 = base64.replace(/^data:image\/jpeg;base64,/, '');

    if (field == 'justificativa') {
      this.form.justificativa = formattedBase64;
    }
  }

  onFileConvertImage() {
    const link = document.createElement('a');
    link.href = 'data:image/jpg;base64,' + this.image;
    link.download = this.nameImage + '.jpg';
    link.click();
  }

  mockedSelectData = [
    {
      name: 'Atraso',
      value: 1,
    },
    {
      name: 'Saída Antecipada',
      value: 2,
    },
    {
      name: 'Ausência	Justificada',
      value: 3,
    },
  ];
}
