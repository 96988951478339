import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ICriarUsuarioForm } from './criar-usuario-layout.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from '../../../services/permission.service';
import { ValidationService } from '../../../services/validation/validation.service';
import Swal from 'sweetalert2';
import { HttpService } from '../../../services/common/http/common.http.service';
type IOptions = {
  name: string;
  value: number | string;
};
@Component({
  selector: 'app-criar-usuario-layout',
  templateUrl: './criar-usuario-layout.component.html',
  styleUrls: ['./criar-usuario-layout.component.scss'],
})
export class CriarUsuarioLayoutComponent implements OnInit, AfterViewInit {
  permission: number = 2;
  isEditMode = false;
  loader: boolean = false;
  perfilOptions: IOptions[] = [
    {
      name: '',
      value: '',
    },
  ];
  selectDataPerfil: IOptions = {
    name: '',
    value: '',
  };

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private validateService: ValidationService,
    private httpService: HttpService
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getPerfis();
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.activateRoute();
  }

  form = {
    nomeUsuario: '',
    email: '',
    _telefone: '',
    perfil: 0,
  };

  cadastrarOuEditarUsuario() {
    try {
      this.validateService.validateFields(this.form);

      if (this.isEditMode) {
        this.atualizarUsuario();
      } else {
        this.cadastrarUsuario();
      }
    } catch (error: any) {
      Swal.fire({
        title: 'Alerta',
        text:
          error?.message?.toLowerCase() === 'invalid input'
            ? 'Preencha todos os campos.'
            : error?.message,
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
  }

  cadastrarUsuario() {
    this.loader = true;
    const payload = this.createPayload(this.form);

    this.httpService.usePost('UsuarioNFuncionario', payload).add(() => {
      this.router.navigate(['recursos-humanos/gestao-de-acesso']);
    });
  }

  atualizarUsuario() {
    const body = {
      IdFuncionario: 0,
      TxNomeFuncionario: this.form.nomeUsuario,
      TxTelefoneFuncionario: this.form._telefone,
      FlSituacaoFuncionario: true,
      UsuarioId: 0,
      PerfilId: this.form.perfil,
    };

    this.route.queryParams.subscribe((params) => {
      body.IdFuncionario = params['idFuncionario'];
      body.UsuarioId = params['idUsuario'];
    });

    this.httpService.usePut('Funcionario', body).add(() => {
      this.router.navigate(['recursos-humanos/gestao-de-acesso']);
    });
  }

  createPayload(form: ICriarUsuarioForm) {
    const body = {
      TxNome: form.nomeUsuario,
      TxEmail: form.email,
      TxTelefone: form._telefone,
      PerfilId: Number(form.perfil),
    };

    return body;
  }

  getPerfis() {
    this.httpService.useGet('Perfil').subscribe({
      next: (response: any) => {
        this.perfilOptions = response.map((item: any) => ({
          name: item.TxNomePerfil,
          value: item.IdPerfil,
        }));
        this.activateRoute();
      },
      error: () => {},
      complete: () => {},
    });
  }

  activateRoute() {
    this.route.queryParams.subscribe((params) => {
      this.selectDataPerfil = this.findUser(
        this.perfilOptions,
        params['idPerfil']
      );

      if (params && Object.keys(params).length > 0) {
        this.form.perfil = Number(this.selectDataPerfil?.name ?? 0);

        this.isEditMode = true;
        this.form.nomeUsuario = params['nome'] || '';
        this.form.email = params['email'] || '';
        this.form._telefone = params['telefone'] || '';
        this.form.perfil = Number(this.selectDataPerfil?.value) || 0;
      }
    });
  }

  findUser(option: any, event: any) {
    const selectedUser = option.find((x: any) => x.value === Number(event));

    return selectedUser;
  }

  onChangeSelect(event: any) {
    this.selectDataPerfil = this.findUser(this.selectDataPerfil, event);
  }
}
