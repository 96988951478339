<app-menu>
  <div class="desktop">
    <div class="header">
      <app-titulo text="Suprimentos"></app-titulo>
      <div class="header-buttons">
        <app-botao
          type="primario"
          text="Criar pedido"
          width="100%"
          icon="assets/images/add.svg"
          (click)="navegarCriarPedido()"
        ></app-botao>
        <app-botao
          type="primario"
          text="Cadastrar Fornecedor"
          width="100%"
          icon="assets/images/add.svg"
          (click)="navegarCadastrarFornecedor()"
        ></app-botao>
        <app-botao
          type="secundario"
          text="Exportar Excel"
          width="100%"
          icon="assets/images/download-icon.svg"
        ></app-botao>
      </div>
    </div>
    <div class="table-header">
      <h1 class="table-title">Pedidos</h1>
      <div class="table-filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
        ></app-input>
        <app-input placeholder="Data inicial" type="date"></app-input>
      </div>
    </div>
    <app-tabela [data]="mockedData" [columns]="tableColumns"></app-tabela>
    <app-paginacao [pages]="1"></app-paginacao>
  </div>

  <div class="mobile">
    <app-titulo text="Suprimentos"></app-titulo>
    <div class="listagem">
      <div
        class="menu-mobile"
        (click)="this.expandirCardsMobile = !this.expandirCardsMobile"
      >
        <app-titulo class="title" text="Pedidos"></app-titulo>
        <img
          *ngIf="!expandirCardsMobile"
          src="assets/images/arrow-down.svg"
          class="expandir-icon"
        />
        <img
          *ngIf="expandirCardsMobile"
          src="assets/images/arrow-up.svg"
          class="expandir-icon"
        />
      </div>
      <div class="mobile-filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
        ></app-input>
        <app-input
          placeholder="Data inicial"
          type="date"
          *ngIf="this.expandirCardsMobile"
        ></app-input>
        <app-botao
          type="primario"
          text="Criar pedido"
          width="100%"
          icon="assets/images/add.svg"
          (click)="navegarCriarPedido()"
        ></app-botao>
        <app-botao
          type="primario"
          text="Cadastrar Fornecedor"
          width="100%"
          icon="assets/images/add.svg"
          *ngIf="this.expandirCardsMobile"
          (click)="navegarCadastrarFornecedor()"
        ></app-botao>
      </div>
      <app-card-mobile
        [data]="mockedData"
        [rows]="rowsMobile"
        [expand]="expandirCardsMobile"
      ></app-card-mobile>
      <app-paginacao [pages]="1"></app-paginacao>
      <app-botao
        type="secundario"
        text="Exportar Excel"
        width="100%"
        icon="assets/images/download-icon.svg"
      ></app-botao>
    </div>
  </div>
</app-menu>
