import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { IPostPerfil } from './criar-perfil-layout.model';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';

@Component({
  selector: 'app-criar-perfil-layout',
  templateUrl: './criar-perfil-layout.component.html',
  styleUrls: ['./criar-perfil-layout.component.scss'],
})
export class CriarPerfilLayoutComponent implements OnInit {
  permission: number = 2;
  editando: boolean = false;
  perfilId: number | null = null;
  gestaoAcesso: any = null;

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private toast: ToastrService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.activateRoute();
    this.changeDetectorRef.detectChanges();
  }

  form = {
    nomePerfil: '',
  };

  mockedData = [
    {
      acesso: 'Administrador',
      permissao: 'desabilitado',
    },
    {
      acesso: 'Recursos Humanos',
      permissao: 'apenasLeitura',
    },
    {
      acesso: 'Dashboard - Recursos Humanos',
      permissao: 'habilitado',
    },
    {
      acesso: 'Dashboard - Encarregado',
      permissao: 'habilitado',
    },
    {
      acesso: 'EPI',
      permissao: 'habilitado',
    },
    {
      acesso: 'Gestão de acesso',
      permissao: 'habilitado',
    },
    {
      acesso: 'Apontamento de horas',
      permissao: 'habilitado',
    },
    {
      acesso: 'Advertência',
      permissao: 'habilitado',
    },
  ];
  loader: boolean = false;

  tableColumns = [
    { header: 'Acesso', key: 'acesso', type: 'text' },
    {
      header: 'Permissão',
      key: 'permissao',
      type: 'select',
      options: [
        { value: 'habilitado', label: 'Habilitado' },
        { value: 'desabilitado', label: 'Desabilitado' },
        { value: 'apenasLeitura', label: 'Apenas Leitura' },
      ],
    },
  ];

  private formatarFlagName(acesso: string): string {
    const mapeamentoFlagNames: { [key: string]: string } = {
      Administrador: 'NuAdmin',
      'Recursos Humanos': 'NuRecursosHumano',
      'Dashboard - Recursos Humanos': 'NuDashboardRecursosHumano',
      'Dashboard - Encarregado': 'NuDashboardEncarregado',
      EPI: 'NuEPI',
      'Gestão de acesso': 'NuGestaoAcesso',
      'Apontamento de horas': 'NuApontamentoHora',
      Advertência: 'NuAdvertencia',
    };

    return mapeamentoFlagNames[acesso] || acesso;
  }

  cadastrarPerfil() {
    this.loader = true;
    const payload = this.createPayload();

    this.httpService.usePostFilter('PerfilNGestaAcesso', payload).subscribe({
      next: (_response) => {
        this.toast.success('Perfil cadastrado com sucesso!');
        this.loader = false;
        this.router.navigate(['recursos-humanos/gestao-de-perfis']);
      },
      error: (_error) => {
        this.toast.success(
          'Ocorreu um erro ao cadastrar o perfil. Tente novamente!'
        );
        this.loader = false;
      },
    });
  }

  editarPerfil() {
    this.loader = true;
    const payload = this.createEditPayload();

    this.httpService.usePut('PerfilNGestaAcesso', payload).add(() => {
      this.toast.success('Perfil editado com sucesso!');
      this.loader = false;
      this.router.navigate(['recursos-humanos/gestao-de-perfis']);
    });
  }

  private getPermissaoValue(permissao: string): number {
    switch (permissao) {
      case 'habilitado':
        return 0;
      case 'apenasLeitura':
        return 1;
      case 'desabilitado':
        return 2;
      default:
        return 0;
    }
  }

  private setGestaoAcessoPermissao(
    payload: any,
    acesso: string,
    permissaoValue: number
  ) {
    switch (acesso) {
      case 'Administrador':
        payload.GestaoAcesso.NuAdmin = permissaoValue;
        payload.Perfil.GestaoAcesso.NuAdmin = permissaoValue;
        break;
      case 'Recursos Humanos':
        payload.GestaoAcesso.NuRecursosHumano = permissaoValue;
        payload.Perfil.GestaoAcesso.NuRecursosHumano = permissaoValue;
        break;
      case 'Dashboard - Recursos Humanos':
        payload.GestaoAcesso.NuDashboardRecursosHumano = permissaoValue;
        payload.Perfil.GestaoAcesso.NuDashboardRecursosHumano = permissaoValue;
        break;
      case 'Dashboard - Encarregado':
        payload.GestaoAcesso.NuDashboardEncarregado = permissaoValue;
        payload.Perfil.GestaoAcesso.NuDashboardEncarregado = permissaoValue;
        break;
      case 'EPI':
        payload.GestaoAcesso.NuEPI = permissaoValue;
        payload.Perfil.GestaoAcesso.NuEPI = permissaoValue;
        break;
      case 'Gestão de acesso':
        payload.GestaoAcesso.NuGestaoAcesso = permissaoValue;
        payload.Perfil.GestaoAcesso.NuGestaoAcesso = permissaoValue;
        break;
      case 'Apontamento de horas':
        payload.GestaoAcesso.NuApontamentoHora = permissaoValue;
        payload.Perfil.GestaoAcesso.NuApontamentoHora = permissaoValue;
        break;
      case 'Advertência':
        payload.GestaoAcesso.NuAdvertencia = permissaoValue;
        payload.Perfil.GestaoAcesso.NuAdvertencia = permissaoValue;
        break;
    }
  }

  createEditPayload() {
    const payload = {
      Perfil: {
        IdPerfil: this.perfilId,
        TxNomePerfil: this.form.nomePerfil,
        FlStatusPerfil: true,
        GestaoAcesso: {
          IdGestaoAcesso: this.gestaoAcesso.IdGestaoAcesso,
          PerfilId: this.perfilId,
          NuSistemaGestaoAcesso: 0,
          NuAdmin: 0,
          NuRecursosHumano: 0,
          NuDashboardRecursosHumano: 0,
          NuDashboardEncarregado: 0,
          NuEPI: 0,
          NuGestaoAcesso: 0,
          NuApontamentoHora: 0,
          NuAdvertencia: 0,
        },
      },
      GestaoAcesso: {
        IdGestaoAcesso: this.gestaoAcesso.IdGestaoAcesso,
        NuSistemaGestaoAcesso: 0,
        NuAdmin: 0,
        NuRecursosHumano: 0,
        NuDashboardRecursosHumano: 0,
        NuDashboardEncarregado: 0,
        NuEPI: 0,
        NuGestaoAcesso: 0,
        NuApontamentoHora: 0,
        NuAdvertencia: 0,
        PerfilId: this.perfilId,
      },
    };

    this.mockedData.forEach((item) => {
      const permissaoValue = this.getPermissaoValue(item.permissao);
      this.setGestaoAcessoPermissao(payload, item.acesso, permissaoValue);
    });

    return payload;
  }

  createPayload() {
    const payload = {
      Perfil: {
        IdPerfil: 0,
        TxNomePerfil: this.form.nomePerfil,
        FlStatusPerfil: 1,
        GestaoAcesso: {
          IdGestaoAcesso: 0,
          NuSistemaGestaoAcesso: 0,
          NuAdmin: 0,
          NuRecursosHumano: 0,
          NuDashboardRecursosHumano: 0,
          NuDashboardEncarregado: 0,
          NuEPI: 0,
          NuGestaoAcesso: 0,
          NuApontamentoHora: 0,
          NuAdvertencia: 0,
          PerfilId: 0,
        },
      },
      GestaoAcesso: {
        IdGestaoAcesso: 0,
        NuSistemaGestaoAcesso: 0,
        NuAdmin: 0,
        NuRecursosHumano: 0,
        NuDashboardRecursosHumano: 0,
        NuDashboardEncarregado: 0,
        NuEPI: 0,
        NuGestaoAcesso: 0,
        NuApontamentoHora: 0,
        NuAdvertencia: 0,
        PerfilId: 0,
      },
    };

    this.mockedData.forEach((item) => {
      const permissaoValue = this.getPermissaoValue(item.permissao);
      this.setGestaoAcessoPermissao(payload, item.acesso, permissaoValue);
    });

    return payload;
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.editando = params['editando'] === 'true';
      if (this.editando) {
        this.perfilId = +params['perfilId'];
        this.gestaoAcesso = JSON.parse(params['gestaoAcesso']);

        this.mockedData = this.mockedData.map((item) => {
          const flagName = this.formatarFlagName(item.acesso);
          const permissaoValue = this.gestaoAcesso[flagName];

          let permissaoLabel = 'desabilitado'; // Valor padrão
          if (permissaoValue === 0) permissaoLabel = 'habilitado';
          else if (permissaoValue === 1) permissaoLabel = 'apenasLeitura';

          return { ...item, permissao: permissaoLabel };
        });
        this.form.nomePerfil = params['txPerfil'];
      }
    });
  }
}
