import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITableColumn } from './tabela.model';
@Component({
  selector: 'app-tabela',
  templateUrl: './tabela.component.html',
  styleUrls: ['./tabela.component.scss'],
})
export class TabelaComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() columns: ITableColumn[] = [];
  @Input() condition: boolean = false;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  onSelectChange(value: any, item: any) {
    const body = {
      value: value,
      item: item,
    };
    this.ngModelChange.emit(body);
  }

  constructor() {}

  ngOnInit() { }
}
