import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-criar-perfil',
  templateUrl: './criar-perfil.component.html',
  styleUrls: ['./criar-perfil.component.scss'],
})
export class CriarPerfilComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
