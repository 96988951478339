import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjetosComponent } from './projetos/projetos/projetos.component';
import { CadastrarProjetoComponent } from './projetos/cadastrar-projeto/cadastrar-projeto.component';
import { AppGuard } from '../../services/auth.guard';
import { DisciplinaComponent } from './disciplinas/disciplinas/disciplinas.component';
import { CadastrarDisciplinaComponent } from './disciplinas/cadastrar-area/cadastrar-disciplina.component';
import { ValidarProjetoComponent } from './engenharia/validar-projeto/validar-projeto.component';
import { RevisaoComponent } from './projetos/revisao/revisao.component';
import { EngenhariaComponent } from './engenharia/engenharia/engenharia.component';

const routes: Routes = [
  {
    path: 'engenharia/projetos',
    component: ProjetosComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuAdmin'], title: 'Projetos | BUILTUP' },
  },
  {
    path: 'engenharia/cadastrar-projeto',
    component: CadastrarProjetoComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuAdmin'], title: 'Cadastrar Projeto | BUILTUP' },
  },
  {
    path: 'engenharia',
    component: EngenhariaComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuAdmin'], title: 'Engenharia | BUILTUP' },
  },
  {
    path: 'engenharia/validar-projeto',
    component: ValidarProjetoComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuAdmin'], title: 'Validar Projeto | BUILTUP' },
  },
  {
    path: 'engenharia/disciplinas',
    component: DisciplinaComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuAdmin'], title: 'Disciplinas | BUILTUP' },
  },
  {
    path: 'engenharia/cadastrar-disciplina',
    component: CadastrarDisciplinaComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuAdmin'],
      title: 'Cadastrar Disciplina | BUILTUP',
    },
  },
  {
    path: 'engenharia/revisao',
    component: RevisaoComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuAdmin'], title: 'Revisão de Projeto | BUILTUP' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class EngenhariaRoutingModule {}
