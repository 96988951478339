import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpService } from '../../../services/common/http/common.http.service';
import { PermissionService } from '../../../services/permission.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '../../../utils/utility-functions';
import Swal from 'sweetalert2';
import { IGetApontamentosLog } from './log-apontamentos-layout.model';
import { AppExportBase64 } from '../../../services/exportBase64/exportBase64.service';

@Component({
  selector: 'app-log-apontamentos-layout',
  templateUrl: './log-apontamentos-layout.component.html',
  styleUrls: ['./log-apontamentos-layout.component.scss'],
})
export class LogApontamentosLayoutComponent implements OnInit {
  tableData: IGetApontamentosLog[] = [];
  expandirCardsMobile: boolean = false;
  loader: boolean = false;

  pesquisaParams = {
    Pesquisa: '',
    DtInicio: '',
    DtFim: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
  };

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private exportBase64Service: AppExportBase64,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permissionService.getPermission();
    this.getLogs();
    this.changeDetectorRef.detectChanges();
  }

  tableColumns = [
    {
      header: 'Nome e CPF',
      key: '',
      keyArray: ['TxNome', 'TxCpf'],
      type: 'textArray',
    },
    { header: 'Tipo de Contratação', key: 'TxTipoPessoa', type: 'text' },
    { header: 'N° Ficha de Registro	', key: 'TxFichaRegistro', type: 'text' },
    { header: 'Data', key: 'DtRequisicao', type: 'text' },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Baixar imagem',
          img: 'assets/images/info-circle.svg',
          action: (item: any) => this.downloadImagem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    {
      header: 'Nome e CPF',
      key: '',
      keyArray: ['TxNome', 'TxCpf'],
      type: 'textArray',
    },
    { header: 'Tipo de Contratação', key: 'TxTipoContratação', type: 'text' },
    { header: 'N° Ficha de Registro	', key: 'TxNumeroFicha', type: 'text' },
    { header: 'Data', key: 'DtRequisicao', type: 'text' },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Baixar imagem',
          img: 'assets/images/info-circle.svg',
          action: (item: any) => this.downloadImagem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  getLogs() {
    this.loader = true;
    const params = this.pesquisaParams;

    this.httpService.useGet('ApontamentoLog', params).subscribe({
      next: (response: any) => {
        const data = response.DataSet;

        this.tableData = data.map((item: any) => {
          return {
            IdApontamentoLog: item.IdApontamentoLog,
            DtRequisicao: formatDate(item.DtRequisicao),
            TxNome: item.Pessoa.TxNome,
            TxCpf: item.Pessoa.TxCpf,
            TxFichaRegistro: item.Pessoa.TxFichaRegistro,
            TxTipoPessoa: item.Pessoa.TipoPessoaId === 1 ? 'CLT' : 'Reeducando',
          };
        });
        this.pesquisaParams.MaxPages =
          Math.ceil(response.Total / this.pesquisaParams.TamanhoPagina) || 1;

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error(
          'Não foi possível buscar as disciplinas. Tente novamente!'
        );
      },
    });
  }

  downloadImagem(item: any) {
    const logItem = this.tableData.find(
      (x: any) => x.IdApontamentoLog === item.IdApontamentoLog
    );

    console.log(logItem);

    if (logItem) {
      const params = {
        ApontamentoId: item.IdApontamentoLog,
      };
      const imageName = item.TxNome;

      Swal.fire({
        title: 'Deseja baixar a imagem?',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#37aaF7',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          this.httpService.useGet('ApontamentoLogImagem', params).subscribe({
            next: (response: any) => {
              this.exportBase64Service.downloadJPG(response.base64, imageName);
            },
            error: (_error: any) => {
              this.toast.error('Não foi possível baixar a imagem!');
            },
          });
        }
      });
      return;
    }
    this.toast.error('Não foi possível encontrar o log selecionado!');
    return;
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getLogs();
  }

  onChangeSearch(search: string) {
    this.pesquisaParams.Pesquisa = search;
  }
}
