<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo
        [text]="this.editando ? 'Editar projeto' : 'Cadastrar projeto'"
      ></app-titulo>
      <p>Passo {{ this.etapa }} de {{ this.totalEtapas }}</p>
    </div>
    <div [ngSwitch]="etapa">
      <div *ngSwitchCase="1">
        <form #projetoform="ngForm">
          <app-input
            type="text"
            label="Nome do projeto"
            name="NmProjeto"
            [(ngModel)]="form.NmProjeto"
          ></app-input>
          <app-input
            type="select"
            label="Responsável"
            name="FuncionarioId"
            [(ngModel)]="form.FuncionarioId"
            [selectData]="responsavelOptions"
          ></app-input>
          <div>
            <app-input
              type="select"
              label="Obra relacionada"
              name="ObraId"
              [(ngModel)]="form.ObraId"
              [selectData]="obrasOptions"
              [disabled]="this.form.FrenteTrabalhoId! > 0"
            ></app-input>
          </div>
          <div>
            <app-input
              type="select"
              label="Fábrica relacionada"
              name="FrenteTrabalhoId"
              [(ngModel)]="form.FrenteTrabalhoId"
              [selectData]="obrasOptions"
              [disabled]="this.form.ObraId! > 0"
            ></app-input>
          </div>
          <app-input
            type="date"
            label="Data de início"
            name="DtInicio"
            [(ngModel)]="form.DtInicio"
          ></app-input>
          <app-input
            type="date"
            label="Data de término"
            name="DtTermino"
            [(ngModel)]="form.DtTermino"
          ></app-input>
          <app-select-adicao
            label="Disciplinas do projeto"
            [selectData]="disciplinaOptions"
            (items)="updateDisciplinas($event)"
            [disabled]="this.editando"
          />
          <div
            class="disciplinas-list"
            *ngIf="this.form.disciplinaList.length > 0"
          >
            <label>Responsável pela disciplina</label>
            <div
              class="disciplinas-list-grid"
              *ngFor="let disciplina of this.form.disciplinaList"
            >
              <span class="disciplinas-list-item">
                {{ disciplina.TxNomeDisciplina }}
              </span>
              <span class="disciplinas-list-item">
                {{ disciplina.TxResponsavelDisciplina }}
              </span>
            </div>
          </div>
        </form>
        <div class="form-buttons">
          <app-botao
            text="{{ this.editando ? 'Editar' : 'Avançar' }}"
            (click)="this.editando ? atualizarProjeto() : proximaEtapa()"
          ></app-botao>
        </div>
      </div>

      <div *ngSwitchCase="2">
        <form class="form-1fr" #pessoaForm="ngForm">
          <h2 class="subtitle">
            Anexe o PDF do projeto para a validação posterior (Apenas um projeto
            por vez)
          </h2>
          <h2>Envio de arquivos</h2>
          <app-drag-drop-pdf
            label="Projeto"
            text="Arraste para enviar o arquivo"
            acceptedFileType=".pdf"
            [initialFileUrl]="
              form.imagemProjeto && form.imagemProjeto.TxImagemProjeto
                ? form.imagemProjeto.TxImagemProjeto
                : ''
            "
            (fileDropped)="onFileDropped('TxImagemProjeto', $event, 0)"
          ></app-drag-drop-pdf>
        </form>
        <div class="form-buttons-grid">
          <app-botao
            type="secundario"
            text="Anterior"
            (click)="etapaAnterior()"
          ></app-botao>
          <app-botao text="Avançar" (click)="proximaEtapa()"></app-botao>
        </div>
      </div>

      <div *ngSwitchCase="3">
        <form class="form-1fr" #pessoaForm="ngForm">
          <h2 class="subtitle">
            Anexe o PDF do projeto para a validação posterior (Apenas um projeto
            por vez)
          </h2>
          <h2>Envio de arquivos</h2>
          <app-drag-drop-pdf
            *ngFor="let disciplina of this.form.disciplinaList; index as i"
            [label]="disciplina.TxNomeDisciplina.toUpperCase()"
            text="Arraste para enviar o arquivo"
            [initialFileUrl]="
              disciplina.ImagemDisciplina &&
              disciplina.ImagemDisciplina.TxImagemProjetoDisciplina
                ? disciplina.ImagemDisciplina.TxImagemProjetoDisciplina
                : ''
            "
            (fileDropped)="
              onFileDropped(disciplina.TxNomeDisciplina, $event, i)
            "
          ></app-drag-drop-pdf>
        </form>
        <div class="form-buttons-grid">
          <app-botao
            type="secundario"
            text="Anterior"
            (click)="etapaAnterior()"
          ></app-botao>
          <app-botao
            [text]="this.editando ? 'Editar projeto' : 'Cadastrar projeto'"
            (click)="cadastrarProjeto()"
          ></app-botao>
        </div>
      </div>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="this.loader"></app-loader>
