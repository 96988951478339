import { NgModule } from '@angular/core';
import { LayoutModule } from '../../layout/layout.module';
import { ComponentsModule } from '../../components/components.module';
import { PlanejamentoRoutingModule } from './planejamento-routing.module';
import { PlanejamentoComponent } from './planejamento/planejamento/planejamento.component';
import { DiarioObraComponent } from './diario-obra/diario-obra/diario-obra.component';

@NgModule({
  declarations: [PlanejamentoComponent, DiarioObraComponent],
  imports: [PlanejamentoRoutingModule, ComponentsModule, LayoutModule],
  exports: [],
})
export class PlanejamentoModule {}
