<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo text="Revisão de projeto"></app-titulo>
      <p>Passo {{ this.etapa }} de {{ this.totalEtapas }}</p>
    </div>
    <div [ngSwitch]="etapa">
      <div *ngSwitchCase="1">
        <form class="form-1fr" #pessoaForm="ngForm">
          <h2 class="subtitle">
            Anexe o PDF do projeto para a validação posterior (Apenas um projeto
            por vez)
          </h2>
          <h2>Envio de arquivos</h2>
          <div>
            <app-drag-drop-pdf
              label="Projeto"
              text="Arraste para enviar o arquivo"
              acceptedFileType=".pdf"
              (fileDropped)="
                onFileDropped(
                  'projeto',
                  this.form.ImagemProjeto[0].IdImagemProjeto,
                  $event
                )
              "
            ></app-drag-drop-pdf>
          </div>
          <div>
            <h4 *ngIf="form.ImagemProjeto">Versões anteriores</h4>
            <div
              *ngFor="let arquivo of form.ImagemProjeto; let i = index"
              class="archive"
            >
              <div
                (click)="downloadArquivo(arquivo, 'projeto')"
                class="archive-icon-name"
              >
                <img src="assets/images/file.svg" />
                <p>{{ arquivo.NmProjeto }}</p>
              </div>
            </div>
          </div>
        </form>
        <div class="form-buttons">
          <app-botao text="Avançar" (click)="proximaEtapa()"></app-botao>
        </div>
      </div>

      <div *ngSwitchCase="2">
        <form class="form-1fr" #pessoaForm="ngForm">
          <h2 class="subtitle">
            Anexe o PDF do projeto para a validação posterior (Apenas um projeto
            por vez)
          </h2>
          <h2>Envio de arquivos</h2>
          <div *ngFor="let arquivo of form.ImagensDisciplina; let i = index">
            <app-drag-drop-pdf
              text="Arraste para enviar o arquivo"
              [label]="'Disciplina ' + arquivo?.ProjetoDisciplinaId?.toString()"
              (fileDropped)="
                onFileDropped('disciplina', arquivo.ProjetoDisciplinaId, $event)
              "
            ></app-drag-drop-pdf>
            <div>
              <h4 *ngIf="arquivo.RevisoesProjetoDisciplina">
                Versões anteriores
              </h4>
              <div
                class="archive"
                *ngFor="
                  let hist of arquivo.RevisoesProjetoDisciplina?.reverse();
                  let i = index
                "
              >
                <div
                  class="archive-icon-name"
                  (click)="downloadArquivo(hist, 'disciplina')"
                >
                  <img src="assets/images/file.svg" />
                  <p>{{ hist.Imagem.NmImagemRevisaoProjetoDisciplina }}</p>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="form-buttons-grid">
          <app-botao
            type="secundario"
            text="Anterior"
            (click)="etapaAnterior()"
          ></app-botao>
          <app-botao
            text="Confirmar revisão"
            (click)="confirmarRevisao()"
          ></app-botao>
        </div>
      </div>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="this.loader"></app-loader>
