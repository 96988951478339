import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpService } from '../../../services/common/http/common.http.service';
import { PermissionService } from '../../../services/permission.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { IGetLicencas } from './listagem-licencas-layout.model';
import { formatDate } from '../../../utils/utility-functions';

@Component({
  selector: 'app-listagem-licencas-layout',
  templateUrl: './listagem-licencas-layout.component.html',
  styleUrls: ['./listagem-licencas-layout.component.scss'],
})
export class ListagemLicencasLayoutComponent implements OnInit {
  tableData: IGetLicencas[] = [];
  expandirCardsMobile: boolean = false;
  loader: boolean = false;

  pesquisaParams = {
    Pesquisa: '',
    DtInicio: '',
    DtFim: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
  };

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permissionService.getPermission();
    this.getLicencas();
    this.changeDetectorRef.detectChanges();
  }

  tableColumns = [
    {
      header: 'Nome e CPF',
      key: '',
      keyArray: ['TxNome', 'TxCpf'],
      type: 'textArray',
    },
    { header: 'Licença', key: 'LicencaTxNome', type: 'text' },
    { header: 'Entrada', key: 'DtInicio', type: 'text' },
    { header: 'Retorno', key: 'DtFim', type: 'text' },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    {
      header: 'Nome e CPF',
      key: '',
      keyArray: ['TxNome', 'TxCpf'],
      type: 'textArray',
    },
    { header: 'Licença', key: 'LicencaTxNome', type: 'text' },
    { header: 'Entrada', key: 'DtInicio', type: 'text' },
    { header: 'Retorno', key: 'DtFim', type: 'text' },
    {
      header: 'Ações',
      key: 'acoes',
      type: 'buttons',
      buttons: [
        {
          img: 'assets/images/edit.svg',
          label: 'Editar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  getLicencas(page?: number) {
    this.loader = true;
    const params = this.pesquisaParams;

    this.httpService.useGet('LicencasCriadas', params).subscribe({
      next: (response: any) => {
        const data = response.DataSet;

        this.tableData = data.map((item: IGetLicencas) => {
          return {
            IdLicencaPessoa: item.IdLicencaPessoa,
            LicencaId: item.LicencaId,
            LicencaTxNome: item.Licenca.TxNome,
            DtInicio: formatDate(new Date(item.DtInicio)),
            DtFim: formatDate(new Date(item.DtFim)),
            PessoaId: item.PessoaId,
            TxNome: item.Pessoa.TxNome,
            TxCpf: item.Pessoa.TxCpf,
          };
        });
        this.pesquisaParams.MaxPages =
          Math.ceil(response.Total / this.pesquisaParams.TamanhoPagina) || 1;

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error(
          'Não foi possível buscar as disciplinas. Tente novamente!'
        );
      },
    });
  }

  onChangeSearch(search: string) {
    this.pesquisaParams.Pesquisa = search;
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getLicencas();
  }

  editItem(item: any) {
    const licencaItem = this.tableData.find(
      (x: any) => x.LicencaId === item.LicencaId
    );

    if (licencaItem) {
      this.router.navigate(['recursos-humanos/registrar-licenca'], {
        queryParams: {
          LicencaId: licencaItem.LicencaId,
          IdLicencaPessoa: licencaItem.IdLicencaPessoa,
          PessoaId: licencaItem.PessoaId,
          DtInicio: new Date(licencaItem.DtInicio),
          DtFim: new Date(licencaItem.DtFim),
          Editando: true,
        },
      });
    } else {
      Swal.fire({
        title: 'Atenção!',
        text: 'Disciplina não encontrada.',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }

  deleteItem(item: any) {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realizar a exclusão da disciplina?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: 'green',
      confirmButtonText: 'Sim',
      cancelButtonColor: 'red',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loader = true;
        const idDisciplina = item.IdDisciplina;

        this.httpService
          .useDelete('DisciplinaDelete', { idDisciplina })
          .add(() => {
            this.getLicencas();
            this.loader = false;
          });
      }
    });
  }

  navegarRegistrarLicenca() {
    this.router.navigate(['recursos-humanos/registrar-licenca']);
  }
}
