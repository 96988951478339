<div class="container" *ngIf="type === 'entrada'">
  <div class="hour">
    <img src="assets/images/entrada-icon.svg" />
    <span class="blue-color">{{ hour }}</span>
  </div>
  <div>
    <span class="text-hour-type">Entrada</span>
  </div>
</div>

<div class="container saida" *ngIf="type === 'saida'">
  <div class="hour">
    <img src="assets/images/saida-icon.svg" />
    <span class="black-color">{{ hour }}</span>
  </div>
  <div>
    <span class="text-hour-type">Saída</span>
  </div>
</div>
