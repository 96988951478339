<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo
        [text]="
          isMode == true
            ? 'Editar apontamento de hora'
            : 'Criar apontamento de hora'
        "
      ></app-titulo>
    </div>
    <form #apontamentoHoraForm="ngForm" class="form">
      <app-input
        label="Nome do pessoa"
        type="select"
        name="nomePessoa"
        required="true"
        [selectData]="pessoaOptions"
        (ngModelChange)="changeSelect($event, 'nomePessoa')"
        [(ngModel)]="form.nomePessoa"
        [disabled]="isMode"
        [requiredInput]="true"
      />
      <app-input
        type="date-hour"
        label="Data e hora entrada"
        name="dataEntrada"
        required="true"
        [(ngModel)]="form.dataEntrada"
        [requiredInput]="true"
      />
      <app-input
        type="date-hour"
        label="Data e hora saída almoço"
        name="dataSaidaAlmoco"
        required="true"
        [(ngModel)]="form.dataSaidaAlmoco"
        [requiredInput]="true"
      />
      <app-input
        type="date-hour"
        label="Data e hora retorno almoço"
        name="dataRetornoAlmoco"
        required="true"
        [(ngModel)]="form.dataRetornoAlmoco"
        [requiredInput]="true"
      />
      <app-input
        type="date-hour"
        label="Data e hora saída"
        name="dataSaida"
        required="true"
        [(ngModel)]="form.dataSaida"
        [requiredInput]="true"
      />
      <app-input
        type="select"
        label="Motivo apontamento"
        name="nuMotivo"
        required="true"
        [selectData]="motivoOptions"
        [(ngModel)]="form.nuMotivo"
        (ngModelChange)="changeSelect($event, 'motivo')"
      />
      <app-input
        type="textarea"
        label="Descrição do apontamento"
        name="descricaoMotivo"
        maxLength="150"
        class="grid-2fr"
        [(ngModel)]="form.descricaoMotivo"
      ></app-input>
    </form>
    <app-botao
      type="primario"
      text="Salvar"
      (click)="this.registrarApontamento()"
      *ngIf="this.permission === 0"
    ></app-botao>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
