import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from '../services/permission.service';
import { LocalStorageService } from './localStorage.service';

@Injectable({ providedIn: 'root' })
export class AppGuard {
  constructor(
    private readonly authService: AuthService,
    private router: Router,
    private toast: ToastrService,
    private permissionService: PermissionService,
    private localStorageService: LocalStorageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    this.localStorageService.atualizar().then(() => {
      const requiredRoles = route.data['requiredRoles'];

      if (this.authService.isAuthenticatedAndTokenValid()) {
        if (!requiredRoles) {
          return true;
        }

        const roleStatus = this.getRoleStatus(requiredRoles);

        if (roleStatus === 0 || roleStatus === 1) {
          this.permissionService.setPermission(roleStatus);
          return true;
        }
      }

      this.toast.error('Acesso negado.');
      this.router.navigate(['login']);

      return false;
    });
  }

  private getRoleStatus(requiredRoles: string[]): number {
    const acesso = localStorage.getItem('GestaoAcesso');

    if (acesso) {
      const acessoObj = JSON.parse(acesso) || null;
      const roleStatus = requiredRoles
        .map((role) => acessoObj[role])
        .sort((a, b) => a - b)[0];

      return roleStatus ?? 2;
    }

    return 2;
  }
}
