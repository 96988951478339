import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-texto-reconhecimento',
  templateUrl: './texto-reconhecimento.component.html',
  styleUrls: ['./texto-reconhecimento.component.scss'],
})
export class TextoReconhecimentoComponent implements OnInit {
  @Input() textos: string[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {}
}
