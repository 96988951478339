<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo text="Horário divergente"></app-titulo>
    </div>
    <form #horarioDivergenteForm="ngForm">
      <app-input
        label="Nome do pessoa"
        name="nome"
        [(ngModel)]="form.nome"
        [disabled]="true"
      ></app-input>
      <app-input
        label="CPF"
        name="cpf"
        [(ngModel)]="form.cpf"
        [disabled]="true"
      ></app-input>
      <app-input
        label="Turno"
        name="turno"
        [(ngModel)]="form.turno"
        [disabled]="true"
      ></app-input>
      <app-input
        label="Horário"
        name="horario"
        [(ngModel)]="form.horario"
        [disabled]="true"
      ></app-input>
      <app-input
        type="select"
        label="Motivo"
        name="motivo"
        [(ngModel)]="form.motivo"
        [selectData]="mockedSelectData"
        [disabled]="this.isMode == 0 ? true : false"
      ></app-input>
      <!-- <app-drag-drop
        label="Justificativa"
        text="Arraste para enviar a foto"
        name="justificativa"
        class="grid-2fr"
        acceptedFileType=".jpg,image/jpeg"
        (fileDropped)="onFileDropped('justificativa', $event)"
        *ngIf="this.isMode == 0 ? false : true"
      ></app-drag-drop> -->
    </form>
    <div class="form-buttons">
      <app-botao
        text="Salvar ponto"
        (click)="salvarPonto()"
        *ngIf="this.isMode == 0 ? false : true"
      ></app-botao>
      <app-botao
        text="Baixar justificativa"
        (click)="this.onFileConvertImage()"
        *ngIf="this.isMode == 0 ? true : false"
      ></app-botao>
    </div>
  </div>
</app-menu>
