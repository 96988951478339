import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
  OnChanges,
  SimpleChanges,
  HostListener,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

interface SelectOption {
  value: any;
  name: string;
}

@Component({
  selector: 'app-select-check',
  templateUrl: './select-check.component.html',
  styleUrls: ['./select-check.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCheckComponent),
      multi: true,
    },
  ],
})
export class SelectCheckComponent
  implements OnInit, OnChanges, ControlValueAccessor, OnDestroy
{
  @Input() label: string = '';
  @Input() labelColor: string = '';
  @Input() placeholder: string = '';
  @Input() readOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() maxLength: string | null = null;
  @Input() width: string = '';
  @Input() mask: string = '';
  @Input() requiredInput: boolean = false;
  @Input() selectData: SelectOption[] = [];
  @Input() ngModel: any[] = [];
  @Output() ngModelChange: EventEmitter<any[]> = new EventEmitter();
  @Output() blurEvent: EventEmitter<void> = new EventEmitter();

  searchTerm: string = '';
  showOptions: boolean = false;
  filteredOptions: SelectOption[] = [];
  private searchSubject = new Subject<string>();

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this.maxLength === '') {
      this.maxLength = null;
    }
    this.filteredOptions = [...this.selectData];

    this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => this.filterOptions(value));

    document.addEventListener('click', this.onClickOutside.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectData']) {
      this.filteredOptions = [...this.selectData];
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.onClickOutside.bind(this));
  }

  filterOptions(searchTerm: string) {
    if (searchTerm) {
      this.filteredOptions = this.selectData.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      this.filteredOptions = [...this.selectData];
    }
  }

  toggleSelection(option: SelectOption) {
    const index = this.ngModel.indexOf(option.value);
    if (index > -1) {
      this.ngModel.splice(index, 1);
    } else {
      this.ngModel.push(option.value);
    }
    this.onChange(this.ngModel);
    this.ngModelChange.emit(this.ngModel);
  }

  isSelected(option: SelectOption): boolean {
    return this.ngModel.includes(option.value);
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!this.elementRef.nativeElement.contains(target)) {
      this.showOptions = false;
    }
  }

  writeValue(value: any[]): void {
    if (Array.isArray(value)) {
      this.ngModel = value;
    } else {
      this.ngModel = [];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.readOnly = isDisabled;
  }
}
