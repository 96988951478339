import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { IValidarProjetoForm, Root } from './validar-projeto.model';
import Swal from 'sweetalert2';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';
import { concat } from 'rxjs';

@Component({
  selector: 'app-validar-projeto-layout',
  templateUrl: './validar-projeto-layout.component.html',
  styleUrls: ['./validar-projeto-layout.component.scss'],
})
export class ValidarProjetoLayoutComponent implements OnInit {
  loader: boolean = false;

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private http: HttpClient,
    private toast: ToastrService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permissionService.getPermission();
    this.getUsuario();
    this.activateRoute();
    this.getProjeto();
    this.changeDetectorRef.detectChanges();
  }

  form: IValidarProjetoForm = {
    IdProjeto: null,
    IdImagemProjeto: null,
    FuncionarioId: 0,
    NmProjeto: '',
    TxImagemProjeto: '',
  };

  getProjeto() {
    this.loader = true;
    const form = this.form;
    const params = {
      IdProjeto: form.IdProjeto,
      FlArquivo: 'true',
    };

    this.httpService.useGet('ProjetoGet', params).subscribe({
      next: (response: Root) => {
        const data = response.Projeto;
        const docProjeto = response.Projeto.RevisoesProjeto.reverse();

        this.form.IdImagemProjeto = data.IdProjeto;
        this.form.NmProjeto = data.NmProjeto;
        this.form.TxImagemProjeto =
          'data:application/pdf;base64,' +
          docProjeto[0].Imagem.TxImagemRevisaoProjeto;

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error(
          'Não foi possível buscar as imagens do projeto. Tente novamente!'
        );
      },
      complete: () => {
        this.loader = false;
      },
    });
  }

  aprovarProjeto() {
    const payload = {
      IdProjeto: this.form.IdProjeto,
      FuncionarioId: this.form.FuncionarioId,
      DisciplinaId: 0,
      FlAprovado: true,
    };

    this.httpService.usePostFilter('ProjetoValidate', payload).subscribe({
      next: (_response: any) => {
        Swal.fire({
          title: 'Successo!',
          icon: 'success',
          text: 'Projeto aprovado com sucesso!',
          confirmButtonText: 'Ok',
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['engenharia']);
          }
        });
      },
      error: (_error: any) => {
        this.toast.error(
          'Não foi possível aprovar o projeto. Tente novamente!'
        );
      },
    });
  }

  reprovarProjeto() {
    const payload = {
      IdProjeto: this.form.IdProjeto,
      FuncionarioId: this.form.FuncionarioId,
      DisciplinaId: 0,
      FlAprovado: false,
    };

    this.httpService.usePostFilter('ProjetoValidate', payload).subscribe({
      next: (_response: any) => {
        Swal.fire({
          title: 'Successo!',
          icon: 'success',
          text: 'Projeto reprovado com sucesso!',
          confirmButtonText: 'Ok',
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['engenharia']);
          }
        });
      },
      error: (_error: any) => {
        this.toast.error(
          'Não foi possível reprovar o projeto. Tente novamente!'
        );
      },
    });
  }

  getUsuario() {
    const usuarioJSON = localStorage.getItem('Usuario');

    if (usuarioJSON) {
      const usuario = JSON.parse(usuarioJSON);
      const idUsuario = usuario.IdUsuario;
      this.form.FuncionarioId = idUsuario;
      return;
    }

    return;
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.form.IdProjeto = +params['ProjetoId'] || null;
    });
  }
}
