import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';
import { Location } from '@angular/common';
import { validateSchema } from '../../../services/zod/zod.service';
import { IForms, schema } from './advertencia-layout.component.model';

type IOptions = {
  name: string;
  value: string | number;
  cpf?: string;
};

@Component({
  selector: 'app-advertencia-layout',
  templateUrl: './advertencia-layout.component.html',
  styleUrls: ['./advertencia-layout.component.scss'],
})
export class AdvertenciaLayoutComponent implements OnInit {
  permission: number = 2;
  currentNavigation: any;
  loader: boolean = false;
  isMode: number = 0;
  form: IForms = {
    nome: '',
    cpf: '',
    frenteTrabalho: '',
    horario: '',
    turno: 0,
    motivoAdvertencia: 0,
    descricaoAdvertencia: '',
    apontamentoId: 0,
    advertenciaId: 0,
    valorAdvertencia: 0,
  };

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private http: HttpClient,
    private httpService: HttpService,
    private toast: ToastrService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getPessoas();
    this.changeDetectorRef.detectChanges();
  }

  pessoaOptions: IOptions[] = [
    {
      name: '',
      value: '',
      cpf: '',
    },
  ];

  mockedTurnos = [
    {
      name: 'Manhã',
      value: 1,
      horario: '07h às 16h',
    },
    {
      name: 'Tarde',
      value: 2,
      horario: '12h às 21h',
    },
    {
      name: 'Noite',
      value: 3,
      horario: '15h às 23h',
    },
  ];

  mockedMotivo = [
    {
      name: 'Sem equipamento de proteção individual',
      value: 1,
    },
    {
      name: 'Outros',
      value: 2,
    },
  ];

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.isMode = Number(params['isMode']);
      const idPessoa = Number(params['idPessoa']);
      const ApontamentoHoraId = Number(params['ApontamentoHoraId']);
      const AdvertenciaId = Number(params['idAdvertencia']);

      this.form.advertenciaId = AdvertenciaId;
      this.httpService
        .useGet('AdvertenciaById', { ApontamentoHoraId })
        .subscribe({
          next: (response: any) => {
            this.form.turno = Number(
              response.Advertencia['NuTurnoAdvertencia']
            );
            this.form.descricaoAdvertencia =
              response.Advertencia['TxDescricaoAdvertencia'];
            this.form.motivoAdvertencia =
              response.Advertencia['NuMotivoAdvertencia'];
            this.form.frenteTrabalho =
              response.Advertencia['alocado'] === 'Não Informado'
                ? ''
                : response.Advertencia['alocado'];
            this.form.apontamentoId = response.Advertencia['ApontamentoHoraId'];
            this.form.valorAdvertencia = Number(
              response.Advertencia['VlAdvertencia']
            );
            this.changeDetectorRef.detectChanges();
            this.loader = false;
          },
        });

      this.httpService.useGet('PessoaByFilter', { idPessoa }).subscribe({
        next: (response: any) => {
          this.form.apontamentoId = Number(ApontamentoHoraId);
          this.form.nome = response.DataSet[0].TxNome;
          this.form.cpf = response.DataSet[0].TxCPF;
          this.loader = false;
        },
      });

      if (!ApontamentoHoraId) {
        Swal.fire({
          title: 'Atenção!',
          text: 'O registro não possui apontamento de horas. Favor verificar.',
          icon: 'info',
          confirmButtonText: 'Ok',
          confirmButtonColor: 'green',
        }).then((result) => {
          if (result.isConfirmed) {
            this.location.back();
          }
        });
      }
    });
  }

  escolherPessoa(value: any) {
    const usuario = this.pessoaOptions.find(
      (usuario) => usuario?.value === Number(value)
    );
    this.form.cpf = usuario?.cpf || '';
  }

  escolherTurno(value: any) {
    const turno = this.mockedTurnos.find((turno) => turno?.value === value);
    this.form.horario = turno?.horario || '';
  }

  escolherAdvertencia(value: any) {
    const motivo = this.mockedMotivo.find((motivo) => motivo?.value === value);
    this.form.descricaoAdvertencia = motivo?.name || '';
  }

  getPessoas() {
    this.loader = true;

    this.httpService.useGet('Pessoa').subscribe({
      next: (response: any[]) => {
        this.pessoaOptions = response.map((x) => {
          return {
            name: x.TxNome,
            value: x.IdPessoa,
            cpf: x.TxCPF,
          };
        });
        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }

  salvarAdvertencia() {
    this.form.valorAdvertencia = Number(this.form.valorAdvertencia);

    try {
      const formValidated = validateSchema(schema, this.form);

      const payload = {
        NuMotivoAdvertencia: formValidated.motivoAdvertencia,
        NuTurnoAdvertencia: formValidated.turno,
        TxDescricaoAdvertencia: formValidated.descricaoAdvertencia,
        ApontamentoHoraId: formValidated.apontamentoId,
        IdAdvertencia: formValidated.advertenciaId,
        VlAdvertencia: Number(formValidated.valorAdvertencia),
      };

      if (Number(formValidated.advertenciaId) === 0) {
        this.httpService.usePost('Advertencia', payload).add(() => {
          this.location.back();
        });
        this.loader = false;
      } else {
        this.httpService.usePut('Advertencia', payload).add(() => {
          this.location.back();
        });
        this.loader = false;
      }
    } catch (error: any) {
      Swal.fire({
        title: 'Alerta',
        text:
          error?.message?.toLowerCase() === 'invalid input'
            ? 'Preencha todos os campos.'
            : error?.message,
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
  }
}
