import { Component, Input, OnInit } from '@angular/core';

type CardTypes = 'entrada' | 'saida';

@Component({
  selector: 'app-card-horario',
  templateUrl: './card-horario.component.html',
  styleUrls: ['./card-horario.component.scss'],
})
export class CardHorarioComponent implements OnInit {
  @Input() type: CardTypes = 'entrada';
  @Input() hour: string = '';

  constructor() {}

  ngOnInit() {}
}
