import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ITableData, IDataSetPessoa } from './listagem-pessoas-layout.model';
import { ToastrService } from 'ngx-toastr';
import { AppExportBase64 } from '../../../services/exportBase64/exportBase64.service';
import Swal from 'sweetalert2';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';
import { formatCpf } from '../../../utils/utility-functions';

@Component({
  selector: 'app-listagem-pessoas-layout',
  templateUrl: './listagem-pessoas-layout.component.html',
  styleUrl: './listagem-pessoas-layout.component.scss',
})
export class ListagemPessoasLayoutComponent {
  permission: number = 2;
  loader: boolean = false;
  tableData: ITableData[] = [];
  expandirMetricasMobile: boolean = false;
  expandirCardsMobile: boolean = false;
  pessoas: Array<any> = [];
  counter = 0;
  timer: ReturnType<typeof setTimeout> | null = null;

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private toast: ToastrService,
    private exportBase64Service: AppExportBase64,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getPessoas();
    this.changeDetectorRef.detectChanges();
  }

  pesquisaParams = {
    TxNome: '',
    TxCpf: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
  };

  tableColumns = [
    {
      header: 'Nome e CPF',
      key: '',
      keyArray: ['nome', 'cpf'],
      type: 'textArray',
    },
    { header: 'Tipo de Contratação', key: 'tipoContratacao', type: 'text' },
    { header: 'N° Ficha de Registro', key: 'nuFicha', type: 'text' },
    { header: 'Nome da Mãe', key: 'nomeMae', type: 'text' },
    {
      header: 'Suspender',
      key: 'ativo',
      type: 'checkbox',
      action: (item: any) => this.suspenderPessoa(item),
      condition: (item: any) => true,
    },
    {
      header: 'Ações',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Editar',
          img: 'assets/images/edit.svg',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    { header: 'Nome', key: 'nome', type: 'text', default: true },
    {
      header: 'CPF',
      key: 'cpf',
      type: 'text',
      default: true,
      style: 'blue',
    },
    { header: 'N° Ficha de Registro', key: 'nuFicha', type: 'text' },
    { header: 'Nome da Mãe', key: 'nomeMae', type: 'text' },
    {
      header: 'Ações',
      key: 'acoes',
      type: 'buttons',
      buttons: [
        {
          img: 'assets/images/edit.svg',
          label: 'Editar',
          action: (item: any) => this.editItem(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  visualizarMotivo(item: any) {}

  visualizarJustificativa(item: any) {}

  navegarCadastrarPessoa() {
    this.router.navigate(['recursos-humanos/cadastrar-pessoa']);
  }

  navegarAssociarPessoa() {
    this.router.navigate(['recursos-humanos/associar-pessoa']);
  }

  calcularTotal(
    primeiroValor: string | number,
    segundoValor: string | number
  ): string {
    const valor1 =
      typeof primeiroValor === 'string'
        ? parseInt(primeiroValor, 10) || 0
        : primeiroValor;
    const valor2 =
      typeof segundoValor === 'string'
        ? parseInt(segundoValor, 10) || 0
        : segundoValor;
    const total = valor1 + valor2;
    return total.toString();
  }

  exportarExcel() {
    this.loader = true;
    const body = {
      TxNome: this.pesquisaParams.TxNome,
      TxCPF: this.pesquisaParams.TxCpf,
    };
    this.httpService.usePostFilter('DashboardRHXlsx', body).subscribe({
      next: (response: any) => {
        this.exportBase64Service.downloadExcel(
          response.DataSet,
          'dashboard-rh'
        );
        this.loader = false;
      },
      error: (_error) => {
        this.toast.error('Ocorreu um erro ao exportar excel. Tente novamente!');
        this.loader = false;
      },
    });
  }

  formatarCpfPesquisa(cpf: string): string {
    return cpf.replace(/\D/g, '');
  }

  getPessoas() {
    this.loader = true;
    const params = this.pesquisaParams;

    this.httpService.useGet('PessoaByFilter', params).subscribe(
      (response: any) => {
        this.pessoas = response.DataSet;
        const data = response.DataSet;

        this.tableData = data.map(
          (item: IDataSetPessoa) =>
            ({
              nome: item.TxNome ?? 'Não Informado',
              tipoContratacao: item.TipoPessoaId == 1 ? 'CLT' : 'Reeducando',
              cpf: formatCpf(item.TxCPF ?? ''),
              nomeMae: item.TxNomeMae ?? 'Não Informado',
              nuFicha: item.TxFichaRegistro ?? 'Não Informado',
              idPessoa: item.IdPessoa,
              status: item.NuStatus,
              ativo: item.NuStatus == 1 ? false : true,
            } as ITableData)
        );

        this.pesquisaParams.MaxPages =
          Math.ceil(response.Total / this.pesquisaParams.TamanhoPagina) || 1;

        this.loader = false;
      },
      (error) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      }
    );
  }

  onChangeFilter(event: any) {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.counter++;

      this.getPessoas();
    }, 250);
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getPessoas();
  }

  editItem(item: any) {
    const pessoaItem = this.pessoas.find(
      (x) => Number(x.IdPessoa) === Number(item.idPessoa)
    );

    if (pessoaItem) {
      this.router.navigate(['recursos-humanos/cadastrar-pessoa'], {
        queryParams: {
          editando: true,
          pessoa: JSON.stringify(pessoaItem),
          txNome: item.nome,
          idPessoa: pessoaItem.IdPessoa,
        },
      });
    } else {
      Swal.fire({
        title: 'Atenção!',
        text: 'Pessoa selecionada não encontrada.',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }

  deleteItem(item: any) {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realizar a exclusão da pessoa?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loader = true;

        this.httpService.useDelete('PessoaDesativar', item.idPessoa).add(() => {
          this.loader = false;
          this.getPessoas();
        });
      }
    });
  }

  suspenderPessoa(item: any) {
    let statusPessoa: number;
    const body = {
      IdPessoa: 0,
      NuStatus: 1,
    };

    statusPessoa = item.status == 1 ? 2 : 1;
    let id = item.idPessoa;
    body.IdPessoa = id;
    body.NuStatus = statusPessoa;

    this.httpService.usePatch('PessoaDesativarAtivar', body, id).subscribe(
      (response) => {
        Swal.fire({
          title: 'Sucesso!',
          text: `${
            statusPessoa == 1 ? 'Remoção da suspensão' : 'Suspensão'
          } realizada com sucesso.`,
          icon: 'success',
          confirmButtonColor: 'green',
          confirmButtonText: 'Ok',
        }).then((result) => {
          if (result.isConfirmed) {
            this.getPessoas();
          }
        });
      },
      (error) => {
        Swal.fire({
          title: 'Alerta!',
          text: 'Ocorreu uma falha no processo de suspensão / reativação, tente novamente.',
          icon: 'warning',
          confirmButtonColor: 'green',
          confirmButtonText: 'Ok',
        });
      }
    );
  }
}
