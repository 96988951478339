import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-revisao',
  templateUrl: './revisao.component.html',
  styleUrls: ['./revisao.component.scss'],
})
export class RevisaoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
