import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppExportBase64 } from '../../../services/exportBase64/exportBase64.service';
import { ToastrService } from 'ngx-toastr';
import {
  ITableData,
  IGraphsData,
  IDashboardItens,
} from './dashboard-encarregado-layout.model';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';
import { formatCpf, formatDate } from '../../../utils/utility-functions';

@Component({
  selector: 'app-dashboard-encarregado-layout',
  templateUrl: './dashboard-encarregado-layout.component.html',
  styleUrls: ['./dashboard-encarregado-layout.component.scss'],
})
export class DashboardEncarregadoLayoutComponent implements OnInit {
  permission: number = 2;
  loader: boolean = false;
  expandirMetricasMobile: boolean = false;
  expandirCardsMobile: boolean = false;
  tableData: ITableData[] = [];
  graphsData: IGraphsData[] = [];
  counter = 0;
  timer: ReturnType<typeof setTimeout> | null = null;

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private toast: ToastrService,
    private exportBase64Service: AppExportBase64,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getDashboard();
    this.changeDetectorRef.detectChanges();
  }

  pesquisaParams = {
    TxPesquisa: '',
    DtInicio: '',
    DtFim: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
  };

  graphApontamentoHoras: IGraphsData = {
    title: 'Apontamento de horas',
    description: 'Registros (Diário)',
    descriptionValue: '',
    description2: 'Atrasos (Diário)',
    description2Value: '',
    totalValue: '',
    totalText: '',
  };
  graphJustificativas: IGraphsData = {
    title: 'Justificativas',
    description: 'Quantidade (Diário)',
    descriptionValue: '',
    description2: '',
    description2Value: '',
    totalValue: '',
    totalText: '',
  };
  graphAdvertencia: IGraphsData = {
    title: 'Advertência',
    description: 'Quantidade (Diário)',
    descriptionValue: '',
    description2: '',
    description2Value: '',
    totalValue: '',
    totalText: '',
  };

  tableColumns = [
    {
      header: 'Nome e CPF',
      key: '',
      keyArray: ['nome', 'cpf'],
      type: 'textArray',
    },
    { header: 'Tipo de Contratação', key: 'tipoContratacao', type: 'text' },
    { header: 'Alocação', key: 'alocacao', type: 'text' },
    { header: 'Cidade e UF', key: 'cidadeUf', type: 'text' },
    { header: 'Turno', key: 'turno', type: 'text' },
    { header: 'Data e Horário', key: 'dataHorario', type: 'text' },
    {
      header: 'EPIs',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.visualizarEpis(item),
          condition: (item: any) => this.advertenciaEpis(item),
        },
      ],
    },
    {
      header: 'Advertência',
      key: 'actions',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.visualizarAdvertencia(item),
          condition: (item: any) => this.advertenciaMotivo(item),
        },
      ],
    },
  ];

  rowsMobile = [
    { header: 'Nome', key: 'nome', type: 'text', default: true },
    {
      header: 'CPF',
      key: 'cpf',
      type: 'text',
      default: true,
      style: 'blue',
    },
    { header: 'Alocação', key: 'alocacao', type: 'text' },
    { header: 'Cidade', key: 'cidadeUf', type: 'text' },
    { header: 'Turno', key: 'turno', type: 'text' },
    { header: 'Data e Horário', key: 'dataHorario', type: 'text' },
    {
      header: 'EPIs',
      key: 'epis',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.visualizarEpis(item),
          condition: (item: any) => this.advertenciaEpis(item),
        },
      ],
    },
    {
      header: 'Advertência',
      key: 'advertencia',
      type: 'buttons',
      buttons: [
        {
          label: 'Visualizar',
          action: (item: any) => this.visualizarAdvertencia(item),
          condition: (item: any) => this.advertenciaMotivo(item),
        },
      ],
    },
  ];

  mockedGraphsData = [
    this.graphApontamentoHoras,
    this.graphJustificativas,
    this.graphAdvertencia,
  ];

  advertenciaEpis(item?: any): boolean {
    if (!item.epi) {
      return false;
    }

    return true;
  }

  advertenciaMotivo(item?: any): boolean {
    if (!item.advertencia) {
      return false;
    }
    return true;
  }

  visualizarEpis(item: any) {
    this.router.navigate(['encarregado/cadastrar-epi'], {
      queryParams: {
        idPessoa: item.pessoa,
        ApontamentoHoraId: item.apontamento,
        isMode: 0,
      },
    });
  }

  visualizarAdvertencia(item: any) {
    this.router.navigate(['encarregado/advertencia'], {
      queryParams: {
        idAdvertencia: item.advertencia,
        idPessoa: item.pessoa,
        ApontamentoHoraId: item.apontamento,
        isMode: 0,
      },
    });
  }

  navegarAdicionarPonto() {
    this.router.navigate(['encarregado/ponto']);
  }

  navegarCriarAdvertencia() {
    this.router.navigate(['encarregado/advertencia']);
  }

  navegarAssociarEpi() {
    this.router.navigate(['encarregado/cadastrar-epi']);
  }

  calcularTotal(
    primeiroValor: string | number,
    segundoValor: string | number
  ): string {
    const valor1 =
      typeof primeiroValor === 'string'
        ? parseInt(primeiroValor, 10) || 0
        : primeiroValor;
    const valor2 =
      typeof segundoValor === 'string'
        ? parseInt(segundoValor, 10) || 0
        : segundoValor;
    const total = valor1 + valor2;
    return total.toString();
  }

  exportarExcel() {
    this.loader = true;
    const body = {
      TxPesquisar: this.pesquisaParams.TxPesquisa,
      DtInicio: this.pesquisaParams.DtInicio,
      DtFim: this.pesquisaParams.DtFim,
    };

    this.httpService.usePostFilter('EncarregadoXlsx', body).subscribe({
      next: (response: any) => {
        this.exportBase64Service.downloadExcel(
          response.DataSet,
          'dashboard-encarregado'
        );
        this.loader = false;
      },
      error: (_error) => {
        this.toast.error('Ocorreu um erro ao exportar excel. Tente novamente!');
        this.loader = false;
      },
    });
  }

  getDashboard() {
    this.loader = true;
    const params = this.pesquisaParams;

    this.httpService.useGet('DashboardEncarregado', params).subscribe({
      next: (response: any) => {
        const data = response.DataSet;

        this.setGraphs(data);

        const enumTurno = {
          0: 'Manhã',
          1: 'Tarde',
          2: 'Noite',
        };

        this.tableData = data.DashboardItens.map(
          (item: IDashboardItens) =>
            ({
              nome: item.TxNome ?? 'Não Informado',
              tipoContratacao: 'CLT',
              cpf: formatCpf(item.TxCPF),
              alocacao: item.NomeFrente ?? 'Não Informado',
              cidadeUf: item.TxCidade ?? 'Não Informado',
              turno: enumTurno[item.NuTurno] ?? 'Não Informado',
              dataHorario: formatDate(item.DtUltimoApontamento),
              epi: item.FlEPIs,
              pessoa: item.IdPessoa,
              apontamento: item.ApontamentoId,
              advertencia: item.AdvertenciaId,
            } as ITableData)
        );

        this.pesquisaParams.MaxPages =
          Math.ceil(response.Total / this.pesquisaParams.TamanhoPagina) || 1;

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }

  onChangeFilter(event: any) {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.counter++;

      this.getDashboard();
    }, 250);
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getDashboard();
  }

  setGraphs(data: any) {
    this.graphApontamentoHoras.descriptionValue = data.ApontamentoHoraRegistros;
    this.graphApontamentoHoras.description2Value = data.ApontamentoHoraAtrasos;
    this.graphApontamentoHoras.totalValue = data.ApontamentoHoraTotal;

    this.graphJustificativas.descriptionValue = data.JustificativasDiaria;
    this.graphJustificativas.totalValue = data.JustificativaTotal;

    this.graphAdvertencia.descriptionValue = data.AdvertenciaDiaria;
    this.graphAdvertencia.totalValue = data.AdvertenciaTotal;
  }
}
