import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acrescimo',
  templateUrl: './acrescimo.component.html',
  styleUrls: ['./acrescimo.component.scss'],
})
export class AcrescimoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
