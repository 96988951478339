import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'BUILTUP';

  constructor(private titleService: Title, private router: Router) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.router.routerState.snapshot.root;
          while (child.firstChild) {
            child = child.firstChild;
          }
          return child.data['title'] || this.title;
        })
      )
      .subscribe((title: string) => {
        this.titleService.setTitle(title);
      });
  }
}
