import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listagem-pessoas',
  templateUrl: './listagem-pessoas.component.html',
  styleUrls: ['./listagem-pessoas.component.scss'],
})
export class ListagemPessoasComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
