import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IGetProjetosResponse } from '../../projetos/projetos-layout/projetos-layout.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';

@Component({
  selector: 'app-engenharia-layout',
  templateUrl: './engenharia-layout.component.html',
  styleUrls: ['./engenharia-layout.component.scss'],
})
export class EngenhariaLayoutComponent implements OnInit {
  tableData: IGetProjetosResponse[] = [];
  funcionarios: any = [];
  expandirCardsMobile: boolean = false;
  loader: boolean = false;

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private http: HttpClient,
    private toast: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.permissionService.getPermission();
    this.getProjetos();
    this.changeDetectorRef.detectChanges();
  }

  pesquisaParams = {
    TxNomeProjeto: '',
    Pagina: 0,
    TamanhoPagina: 8,
    MaxPages: 1,
    FlValidar: true,
  };

  tableColumns = [
    { header: 'Nome do projeto', key: 'NmProjeto', type: 'text' },
    { header: 'Responsável', key: 'NmResponsavel', type: 'text' },
    {
      header: 'Projeto',
      key: 'projeto',
      type: 'buttons',
      buttons: [
        {
          label: 'Validar',
          action: (item: any) => this.validarProjeto(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  rowsMobile = [
    {
      header: 'Nome do projeto',
      key: 'NmProjeto',
      type: 'text',
      default: true,
    },
    {
      header: 'Responsável',
      key: 'NmResponsavel',
      type: 'text',
      default: true,
    },
    {
      header: 'Projeto',
      key: 'projeto',
      type: 'buttons',
      buttons: [
        {
          label: 'Validar',
          action: (item: any) => this.validarProjeto(item),
          condition: (item: any) => true,
        },
      ],
    },
  ];

  getProjetos() {
    this.loader = true;
    const params = this.pesquisaParams;

    this.httpService.useGet('ProjetoByFilter', params).subscribe({
      next: (response: any) => {
        const data = response.DataSet;

        this.tableData = data.map((item: any) => {
          return {
            ...item,
            NmProjeto: item.Projeto.NmProjeto ?? 'Não Informado',
            NmResponsavel:
              item.Funcionario.TxNomeFuncionario ?? 'Não Informado',
          };
        });
        this.pesquisaParams.MaxPages =
          Math.ceil(response.Total / this.pesquisaParams.TamanhoPagina) || 1;

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error(
          'Não foi possível buscar os projetos. Tente novamente!'
        );
      },
    });
  }

  validarProjeto(item: any) {
    if (item.FlValidado) {
    }

    this.router.navigate(['engenharia/validar-projeto'], {
      queryParams: {
        ProjetoId: item.Projeto.IdProjeto,
      },
    });
  }

  navegarVerProjetos() {
    this.router.navigate(['engenharia/projetos']);
  }

  mudarPagina(pagina: number) {
    this.pesquisaParams.Pagina = pagina;
    this.getProjetos();
  }
}
