@if (type === 'principal') {
<div class="titlemenu">
  <div class="menu-container">
    <div class="menu">
      <div class="menu-icon">
        <img class="menu-icon-img" *ngIf="src != ''" [src]="src" />
        <p class="principal-p">{{ name }}</p>
      </div>
      <i class="bx bx-chevron-down" style="color: #ffffff" *ngIf="!open"></i>
      <i class="bx bx-chevron-up" style="color: #ffffff" *ngIf="open"></i>
    </div>
  </div>
</div>

} @if (type === 'subtitulo') {
<div class="titlemenu">
  <div class="menu">
    <div class="menu-icon">
      <i
        class="menu-icon-img submenu-icon-img"
        *ngIf="src != ''"
        [class]="src"
        [ngClass]="selected ? 'open' : ''"
      ></i>
      <img *ngIf="sair && src != ''" [src]="src" />
      <p class="subtitulo-p" [ngClass]="selected ? 'open' : ''">{{ name }}</p>
    </div>
  </div>
</div>
}
