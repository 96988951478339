import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apontamento',
  templateUrl: './apontamento.component.html',
  styleUrls: ['./apontamento.component.scss'],
})
export class ApontamentoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
