import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { FileRead } from './drag-drop-pdf.component.model';

@Component({
  selector: 'app-drag-drop-pdf',
  templateUrl: './drag-drop-pdf.component.html',
  styleUrls: ['./drag-drop-pdf.component.scss'],
})
export class DragDropPdfComponent implements OnInit {
  @Input() label: string = '';
  @Input() text: string = '';
  @Input() acceptedFileType: string = 'application/pdf';
  @Input() historyFiles: File[] = [];
  @Input() initialFileUrl: string = '';
  @Input() initialFileName: string = '';
  @Output() fileDropped = new EventEmitter<FileRead>();
  @ViewChild('fileInput') fileInput: any;

  files: NgxFileDropEntry[] = [];
  file: File | null = null;
  base64File: string = '';

  constructor(private toast: ToastrService) {}

  ngOnInit() {
    if (this.initialFileUrl) {
      this.loadInitialFile();
    }
  }

  loadInitialFile() {
    fetch(this.initialFileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.base64File = e.target.result;
          this.file = new File([blob], this.initialFileName, {
            type: blob.type,
          });
          this.fileDropped.emit({
            base64: this.base64File,
            name: this.initialFileName,
          });
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        this.toast.error('Erro ao carregar o arquivo inicial: ' + error);
      });
  }

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.isFileTypeAllowed(file)) {
            this.readFileAsBase64(file);
          } else {
            this.toast.error(
              'Tipo de arquivo não permitido. Apenas arquivos no formato .pdf são aceitos.'
            );
          }
        });
      }
    }
  }

  readFileAsBase64(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.file = file;
      const base64 = e.target.result as string;
      this.base64File = base64;
      this.fileDropped.emit({ base64, name: file.name });
    };
    reader.onerror = (error) => {
      this.toast.error('Erro ao ler o arquivo: ' + error);
    };
    reader.readAsDataURL(file);
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (this.isFileTypeAllowed(file)) {
      this.readFileAsBase64(file);
    } else {
      this.toast.error(
        'Tipo de arquivo não permitido. Apenas arquivos no formato .pdf são aceitos.'
      );
    }
  }

  isFileTypeAllowed(file: File): boolean {
    return file.type === 'application/pdf';
  }

  removeFile() {
    this.file = null;
    this.base64File = '';
    this.fileDropped.emit({ base64: '', name: '' });
  }
}
