import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-criar-apontamento',
  templateUrl: './criar-apontamento.component.html',
  styleUrls: ['./criar-apontamento.component.scss'],
})
export class CriarApontamentoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
