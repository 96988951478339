<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo
        [text]="this.editando ? 'Editar disciplina' : 'Cadastrar disciplina'"
      ></app-titulo>
    </div>
    <form #cadastroDisciplina="ngForm" class="form">
      <app-input
        type="text"
        width="100%"
        label="Nome da disciplina"
        name="NomeDisciplina"
        [(ngModel)]="form.NomeDisciplina"
      ></app-input>
      <app-input
        type="select"
        width="100%"
        name="Nome"
        label="Responsável pela disciplina"
        [selectData]="responsavelOptions"
        [(ngModel)]="form.FuncionarioId"
        (ngModelChange)="onSelectChange($event)"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Telefone/Celular"
        mask="(00) 00000-0000"
        name="telefone"
        [disabled]="true"
        [(ngModel)]="form.TxTelefone"
      ></app-input>
    </form>
    <div class="form-button">
      <app-botao
        width="100%"
        [text]="this.editando ? 'Editar disciplina' : 'Cadastrar disciplina'"
        (click)="cadastrarDisciplina()"
      ></app-botao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
