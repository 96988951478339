import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadastrar-epi',
  templateUrl: './cadastrar-epi.component.html',
  styleUrls: ['./cadastrar-epi.component.scss'],
})
export class CadastrarEpiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
