<app-ponto-layout
  *ngIf="etapa === 1"
  (mudarEtapa)="receberEtapa($event)"
  (estaAtrasado)="receberAtraso($event)"
  (dadosPessoa)="receberDadosPessoa($event)"
></app-ponto-layout>
<app-termos-layout
  *ngIf="etapa === 2"
  (mudarEtapa)="receberEtapa($event)"
  [estaAtrasado]="estaAtrasado"
></app-termos-layout>
<app-horario-divergente-layout
  *ngIf="etapa === 3"
  [dadosPessoa]="dadosPessoa"
></app-horario-divergente-layout>
