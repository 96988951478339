import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionService } from '../../../services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../../../services/common/http/common.http.service';
import Swal from 'sweetalert2';
import {
  DataSetPessoa,
  ICriarApontamentoForm,
  IGetApontamentoByFilterResponse,
  IGetPessoaByFilterResponse,
  IPayloadCreateApontamentoManual,
  IPayloadUpdateApontamentoManual,
} from './criar-apontamento-layout.model';
import { IGetPessoaResponsePagina } from '../../pessoas/listagem-pessoas-layout/listagem-pessoas-layout.model';

type formOptions = 'nomePessoa' | 'motivo';
type IOptions = {
  name: string;
  value: string | number;
};
@Component({
  selector: 'app-criar-apontamento-layout',
  templateUrl: './criar-apontamento-layout.component.html',
  styleUrls: ['./criar-apontamento-layout.component.scss'],
})
export class CriarApontamentoLayoutComponent implements OnInit, AfterViewInit {
  permission: number = 2;
  totalEtapas: number = 2;
  loader: boolean = false;
  isMode: boolean = false;
  idApontamentoHora: number = 0;
  form: ICriarApontamentoForm = {
    nomePessoa: 0,
    dataEntrada: '',
    dataSaidaAlmoco: '',
    dataRetornoAlmoco: '',
    dataSaida: '',
    nuMotivo: 3,
    descricaoMotivo: '',
  };

  constructor(
    private router: Router,
    private httpService: HttpService,
    private permissionService: PermissionService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.getPessoas();
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {}

  setForm(params: ICriarApontamentoForm) {
    this.form = {
      ...this.form,
      ...params,
    };
  }

  motivoOptions = [
    {
      name: 'Falha no reconhecimento facial',
      value: 1,
    },
    {
      name: 'Esquecimento do apontamento de horas',
      value: 2,
    },
    {
      name: 'Outros',
      value: 3,
    },
  ];

  pessoaOptions: IOptions[] = [
    {
      name: '',
      value: 0,
    },
  ];

  changeSelect(item: any, form: formOptions) {
    if (form == 'nomePessoa') this.form.nomePessoa = item;
    if (form == 'motivo') this.form.nuMotivo = item;
  }

  registrarApontamento() {
    this.loader = true;
    const form = this.form;

    if (
      form.dataEntrada &&
      form.dataRetornoAlmoco &&
      form.dataSaida &&
      form.dataSaidaAlmoco &&
      form.nomePessoa &&
      form.nuMotivo &&
      form.descricaoMotivo
    ) {
      if (!this.isMode) {
        const payload = this.createPayload();

        this.httpService
          .usePost('CreateApontamentoHoraManual', payload)
          .add(() => {
            this.loader = false;
          });
      } else {
        const payload: IPayloadUpdateApontamentoManual = this.createPayload();

        payload.IdApontamento = this.idApontamentoHora;

        this.httpService
          .usePut('UpdateApontamentoHoraManual', payload)
          .add(() => {
            this.loader = false;
          });
      }
    } else {
      this.loader = false;

      Swal.fire({
        title: 'Alerta!',
        text: 'Preencha todos os campos obrigatórios',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
  }

  createPayload() {
    const body:
      | IPayloadCreateApontamentoManual
      | IPayloadUpdateApontamentoManual = {
      IdPessoa: Number(this.form.nomePessoa),
      DtEntrada: this.form.dataEntrada,
      DtRetornoAlmoco: this.form.dataRetornoAlmoco,
      DtSaida: this.form.dataSaida,
      DtSaidaAlmoco: this.form.dataSaidaAlmoco,
      NuTipoMotivoApontamento: this.form.nuMotivo,
      TxTipoMotivoApontamento: this.form.descricaoMotivo,
    };
    return body;
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((item) => {
      this.isMode = Boolean(item['Editando']);

      if (item['Editando']) {
        const id = Number(item['IdApontamento']);
        this.getApontamentoHora(id);
        this.idApontamentoHora = id;
      }
    });
  }

  getApontamentoHora(id: number) {
    this.loader = true;
    const IdApontamento = id;
    const errorMessage = () => {
      Swal.fire({
        title: 'Alerta!',
        text: 'O apontamento de horas selecionado não foi encontrado.',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });

      this.router.navigate(['encarregado/apontamentos']);
    };

    this.httpService
      .useGet('ApontamentosByFilters', { IdApontamento })
      .subscribe(
        (response: IGetApontamentoByFilterResponse) => {
          if (response.DataSet.length == 0) return errorMessage();

          const responseForm = response.DataSet[0];

          this.pessoaOptions = [
            {
              name: responseForm.TxNome,
              value: responseForm.IdPessoa,
            },
          ];
          this.setForm({
            dataEntrada: responseForm.DtEntrada,
            dataRetornoAlmoco: responseForm.DtRetornoAlmoco,
            dataSaida: responseForm.DtSaida,
            dataSaidaAlmoco: responseForm.DtSaidaAlmoco,
            descricaoMotivo: responseForm.TxMotivo,
            nomePessoa: responseForm.IdPessoa,
            nuMotivo: responseForm.NuMotivo,
          });

          this.loader = false;
        },
        (error: any) => {
          errorMessage();
          this.loader = false;
        }
      );
  }

  getPessoas() {
    this.loader = true;
    const NuStatus = 1;

    return this.httpService.useGet('PessoaByFilter', { NuStatus }).subscribe({
      next: (response: IGetPessoaResponsePagina) => {
        this.pessoaOptions = response.DataSet.map((x: any) => {
          return {
            name: x.TxNome,
            value: x.IdPessoa,
          };
        });
        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
      complete: () => {
        this.activateRoute();
        this.loader = false;
      },
    });
  }
}
