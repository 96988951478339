<div class="select-add">
  <div class="select-add-input">
    <app-input
      type="select"
      [label]="label"
      name="currentItem"
      [selectData]="selectData"
      [(ngModel)]="currentItem"
      [disabled]="disabled"
      [requiredInput]="requiredInput"
    />
    <ul class="select-add-list">
      <li class="material-li" *ngFor="let item of this.itemsList">
        {{ item.name }}

        <img
          class="remove-button"
          src="assets/images/remove.svg"
          alt="Remover"
          (click)="delItem(item)"
        />
      </li>
    </ul>
  </div>
  <div>
    <button [disabled]="disabled" class="add-button" (click)="addItem()">
      <img src="assets/images/add.svg" alt="Adicionar" />
    </button>
  </div>
</div>
