import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IEpiLayoutForm } from './cadastrar-epi-layout.model';
import Swal from 'sweetalert2';
import { PermissionService } from '../../../services/permission.service';
import { HttpService } from '../../../services/common/http/common.http.service';

@Component({
  selector: 'app-cadastrar-epi-layout',
  templateUrl: './cadastrar-epi-layout.component.html',
  styleUrls: ['./cadastrar-epi-layout.component.scss'],
})
export class CadastrarEpiLayoutComponent implements OnInit {
  permission: number = 2;
  totalEtapas: number = 2;
  loader: boolean = false;
  isMode: number = 0;

  constructor(
    private httpService: HttpService,
    private permissionService: PermissionService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.permission = this.permissionService.getPermission();
    this.activateRoute();
    this.changeDetectorRef.detectChanges();
  }

  form: IEpiLayoutForm = {
    equipamento: '',
    area: '0',
    prazoTroca: '',
    quantidade: 1,
    idEpi: 0,
  };

  mockedSelectData2 = [
    {
      name: 'Elétrica',
      value: '1',
    },
    {
      name: 'Construção',
      value: '2',
    },
    {
      name: 'Alimentícia',
      value: '3',
    },
  ];

  registrarEpi() {
    this.loader = true;
    const form = this.form;

    if (form.equipamento && form.area && form.prazoTroca && form.quantidade) {
      const payload = this.createPayload();

      if (Number(this.isMode) !== 2) {
        this.httpService.usePost('EPI', payload).add(() => {
          this.router.navigate(['encarregado/listagem-epi']);
        });
      } else {
        this.httpService.usePut('EPI', payload).add(() => {
          this.router.navigate(['encarregado/listagem-epi']);
        });
      }
    } else {
      this.loader = false;

      Swal.fire({
        title: 'Alerta!',
        text: 'Preencha todos os campos obrigatórios',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: 'green',
      });
    }
  }

  createPayload() {
    const body = {
      TxDescricaoEPI: this.form.equipamento,
      NuAreaEPI: this.form.area,
      NuPrazoEpi: this.form.prazoTroca,
      NuQuantidadeEPI: this.form.quantidade,
      IdEPI: this.form.idEpi,
    };
    return body;
  }

  activateRoute() {
    this.activatedRoute.queryParams.subscribe((item) => {
      this.isMode = item['isMode'];
      if (item['isMode'] == 2) {
        this.form.equipamento = item['equipamento'];
        this.form.area = item['areaId'];
        this.form.prazoTroca = item['prazoEpi'];
        this.form.quantidade = item['quantidadeEpi'];
        this.form.idEpi = item['idEpi'];
      }
    });
  }
}
