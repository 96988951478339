import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-adicao',
  templateUrl: './select-adicao.component.html',
  styleUrls: ['./select-adicao.component.scss'],
})
export class SelectAdicaoComponent implements OnInit {
  @Input() selectData: any[] = [];
  @Input() label: string = '';
  @Input() labelColor: string = '';
  @Input() disabled: boolean = false;
  @Input() requiredInput: boolean = false;
  @Output() items = new EventEmitter();
  currentItem: string = '';
  itemsList: any[] = [];

  addItem() {
    const itemToAdd = this.selectData.find(
      (item) => item.value === this.currentItem
    );
    if (itemToAdd) {
      const exist = this.itemsList.find(x => x.value == itemToAdd.value);

      if (!exist)
        this.itemsList.push(itemToAdd);
    }

    this.currentItem = '';

    this.items.emit(this.itemsList);
  }

  delItem(itemToRemove: any) {
    const index = this.itemsList.findIndex(
      (item: any) => item.value === itemToRemove.value
    );
    if (index !== -1) {
      this.itemsList.splice(index, 1);
    }

    this.items.emit(this.itemsList);
  }

  constructor() { }

  ngOnInit() { }
}
