import { NgModule } from '@angular/core';
import { LayoutModule } from '../../layout/layout.module';
import { ComponentsModule } from '../../components/components.module';

import { GestaoRhRoutingModule } from './gestao-rh-routing.module';
import { GestaoPerfisComponent } from './gestao-perfis/gestao-perfis/gestao-perfis.component';
import { CriarPerfilComponent } from './gestao-perfis/criar-perfil/criar-perfil.component';
import { GestaoAcessoComponent } from './gestao-acesso/gestao-acesso/gestao-acesso.component';
import { CriarUsuarioComponent } from './gestao-acesso/criar-usuario/criar-usuario.component';
import { DashboardRhComponent } from './dashboard-rh/dashboard-rh/dashboard-rh.component';
import { FrenteTrabalhoComponent } from './frente-trabalho/frente-trabalho/frente-trabalho.component';
import { ListagemFrenteTrabalhoComponent } from './frente-trabalho/listagem-frente-trabalho/listagem-frente-trabalho.component';
import { AssociarFrenteTrabalhoComponent } from './frente-trabalho/associar-frente-trabalho/associar-frente-trabalho.component';
import { CadastrarPessoaComponent } from './pessoas/cadastrar-pessoa/cadastrar-pessoa.component';
import { ListagemPessoasComponent } from './pessoas/listagem-pessoas/listagem-pessoas.component';
import { ListagemLicencasComponent } from './licencas/listagem-licencas/listagem-licencas.component';
import { RegistrarLicencaComponent } from './licencas/registrar-licenca/registrar-licenca.component';
import { AcrescimoComponent } from './acrescimo/acrescimo/acrescimo.component';
import { EditarAcrescimoComponent } from './acrescimo/editar-acrescimo/editar-acrescimo.component';

@NgModule({
  declarations: [
    GestaoPerfisComponent,
    CriarPerfilComponent,
    GestaoAcessoComponent,
    CriarUsuarioComponent,
    DashboardRhComponent,
    FrenteTrabalhoComponent,
    ListagemFrenteTrabalhoComponent,
    AssociarFrenteTrabalhoComponent,
    CadastrarPessoaComponent,
    ListagemPessoasComponent,
    ListagemLicencasComponent,
    RegistrarLicencaComponent,
    AcrescimoComponent,
    EditarAcrescimoComponent,
  ],
  imports: [GestaoRhRoutingModule, ComponentsModule, LayoutModule],
  exports: [],
})
export class GestaoRhModule {}
