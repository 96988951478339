import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-rh',
  templateUrl: './dashboard-rh.component.html',
  styleUrls: ['./dashboard-rh.component.scss'],
})
export class DashboardRhComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
