import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-validar-projeto',
  templateUrl: './validar-projeto.component.html',
  styleUrls: ['./validar-projeto.component.scss'],
})
export class ValidarProjetoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
