import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { HttpService } from '../../../services/common/http/common.http.service';

@Component({
  selector: 'app-termos-layout',
  templateUrl: './termos-layout.component.html',
  styleUrls: ['./termos-layout.component.scss'],
})
export class TermosLayoutComponent implements OnInit {
  @Output() mudarEtapa = new EventEmitter<number>();
  @Input() estaAtrasado = false;
  termosAceitos: boolean = false;
  loader: boolean = false;
  pdfSrc: string =
    'https://vadimdez.github.io/ng2-pdf-viewerassets/pdf-test.pdf';

  constructor(
    private router: Router,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {}

  aceitarTermos() {
    const body = {
      IdApontamentoHora: 0,
    };

    this.activatedRoute.queryParams.subscribe((params) => {
      body.IdApontamentoHora = params['idApontamento'];
    });

    this.httpService.usePost(
      'TermoAceite',
      body,
      'Os termos foram aceitos com sucesso.'
    );

    if (this.estaAtrasado === true) {
      this.mudarEtapa.emit(3);
      return;
    }

    setTimeout(() => {
      this.router.navigate(['encarregado/apontamentos']);
    }, 500);
  }
}
