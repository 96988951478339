<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo text="{{ this.title }}"></app-titulo>
      <p>Passo {{ this.etapa }} de {{ this.totalEtapas }}</p>
    </div>
    <div [ngSwitch]="etapa">
      <div *ngSwitchCase="1">
        <form #frenteDeTrabalhoForm="ngForm">
          <app-input
            label="Nome da frente de trabalho"
            name="nomeFrenteDeTrabalho"
            maxLength="40"
            required="true"
            [requiredInput]="true"
            [(ngModel)]="form.nomeFrenteDeTrabalho"
          ></app-input>
          <app-input
            label="Proprietário"
            name="proprietario"
            [selectData]="proprietarioOptions"
            [(ngModel)]="form.proprietario"
            required="true"
            [requiredInput]="true"
          />
          <app-input
            label="E-mail"
            type="email"
            name="email"
            [(ngModel)]="form.email"
          ></app-input>
          <app-input
            label="Telefone"
            name="telefone"
            mask="(00) 00000-0000"
            [(ngModel)]="form.telefone"
          ></app-input>
          <app-input
            label="CEP"
            name="cep"
            mask="00000-000"
            [disabled]="this.disabledToEdit"
            (ngModelChange)="this.form.cep.length === 8 && buscarCep()"
            [(ngModel)]="form.cep"
          ></app-input>
          <app-input
            label="Endereço"
            name="rua"
            [disabled]="this.disabledToEdit"
            [(ngModel)]="form.rua"
          >
          </app-input>
          <app-input
            label="Nº do local"
            name="numeroLocal"
            maxLength="10"
            [disabled]="this.disabledToEdit"
            [(ngModel)]="form.numeroLocal"
          ></app-input>
          <app-input
            label="Cidade"
            name="cidade"
            maxLength="30"
            [disabled]="this.disabledToEdit"
            [(ngModel)]="form.cidade"
          ></app-input>
        </form>
        <div class="form-buttons-unique">
          <app-botao
            text="Avançar"
            (click)="proximaEtapa()"
            *ngIf="this.permission === 0"
          ></app-botao>
        </div>
      </div>

      <div *ngSwitchCase="2">
        <form #frenteDeTrabalhoForm="ngForm">
          <app-input
            type="select"
            label="Encarregado"
            name="encarregado"
            [selectData]="encarregadoOptions"
            [(ngModel)]="form.encarregado"
            required="true"
            [requiredInput]="true"
            (ngModelChange)="onChangeSelect('encarregado', $event)"
          />
          <app-input
            label="Telefone (Encarregado)"
            name="telefoneEncarregado"
            [disabled]="true"
            [(ngModel)]="form.telefoneEncarregado"
          ></app-input>
          <app-input
            label="Tipo da frente de trabalho"
            type="select"
            name="tipoFrenteDeTrabalho"
            [selectData]="mockedSelectData"
            required="true"
            [requiredInput]="true"
            [(ngModel)]="form.tipoFrenteDeTrabalho"
          ></app-input>
          <app-input
            label="Capacidade de funcionários"
            name="capacidadeFuncionarios"
            mask="0000"
            [required]="true"
            [requiredInput]="true"
            [(ngModel)]="form.capacidadeFuncionarios"
          ></app-input>
        </form>
        <div class="form-buttons">
          <app-botao
            type="secundario"
            text="Anterior"
            (click)="etapaAnterior()"
            *ngIf="this.permission === 0"
          ></app-botao>
          <app-botao
            text="{{ this.isMode == 2 ? 'Editar' : 'Cadastrar' }}"
            (click)="cadastrarFrenteDeTrabalho()"
            *ngIf="this.permission === 0"
          ></app-botao>
        </div>
      </div>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
