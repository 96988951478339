import { NgModule } from '@angular/core';
import { LayoutModule } from '../../layout/layout.module';
import { ComponentsModule } from '../../components/components.module';
import { SuprimentosRoutingModule } from './suprimentos-routing.module';
import { SuprimentosComponent } from './suprimentos/suprimentos/suprimentos.component';
import { FornecedorComponent } from './fornecedor/fornecedor/fornecedor.component';
import { PedidoMaterialComponent } from './pedido-material/pedido-material/pedido-material.component';

@NgModule({
  declarations: [
    SuprimentosComponent,
    FornecedorComponent,
    PedidoMaterialComponent,
  ],
  imports: [SuprimentosRoutingModule, ComponentsModule, LayoutModule],
  exports: [],
})
export class SuprimentosModule {}
