import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GestaoPerfisComponent } from './gestao-perfis/gestao-perfis/gestao-perfis.component';
import { CriarPerfilComponent } from './gestao-perfis/criar-perfil/criar-perfil.component';
import { GestaoAcessoComponent } from './gestao-acesso/gestao-acesso/gestao-acesso.component';
import { CriarUsuarioComponent } from './gestao-acesso/criar-usuario/criar-usuario.component';
import { DashboardRhComponent } from './dashboard-rh/dashboard-rh/dashboard-rh.component';
import { FrenteTrabalhoComponent } from './frente-trabalho/frente-trabalho/frente-trabalho.component';
import { AppGuard } from '../../services/auth.guard';
import { ListagemFrenteTrabalhoComponent } from './frente-trabalho/listagem-frente-trabalho/listagem-frente-trabalho.component';
import { FrenteTrabalhoLayoutComponent } from '../../layout/frente-trabalho/frente-trabalho-layout/frente-trabalho-layout.component';
import { AssociarFrenteTrabalhoComponent } from './frente-trabalho/associar-frente-trabalho/associar-frente-trabalho.component';
import { CadastrarPessoaComponent } from './pessoas/cadastrar-pessoa/cadastrar-pessoa.component';
import { ListagemPessoasComponent } from './pessoas/listagem-pessoas/listagem-pessoas.component';
import { ListagemLicencasComponent } from './licencas/listagem-licencas/listagem-licencas.component';
import { RegistrarLicencaComponent } from './licencas/registrar-licenca/registrar-licenca.component';
import { AcrescimoComponent } from './acrescimo/acrescimo/acrescimo.component';
import { EditarAcrescimoComponent } from './acrescimo/editar-acrescimo/editar-acrescimo.component';

const routes: Routes = [
  {
    path: 'recursos-humanos/dashboard',
    component: DashboardRhComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: [
        'NuDashboardRecursosHumano',
        'NuRecursosHumano',
        'NuAdmin',
      ],
      title: 'Dashboard - Recursos Humanos | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/gestao-de-acesso',
    component: GestaoAcessoComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: [
        'NuGestaoAcesso',
        'NuSistemaGestaoAcesso',
        'NuRecursosHumano',
        'NuAdmin',
      ],
      title: 'Acessos | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/gestao-de-acesso/criar-usuario',
    component: CriarUsuarioComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: [
        'NuGestaoAcesso',
        'NuSistemaGestaoAcesso',
        'NuRecursosHumano',
        'NuAdmin',
      ],
      title: 'Criar Usuário | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/gestao-de-acesso/editar-usuario',
    component: CriarUsuarioComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: [
        'NuGestaoAcesso',
        'NuSistemaGestaoAcesso',
        'NuRecursosHumano',
        'NuAdmin',
      ],
      title: 'Editar Usuário | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/gestao-de-perfis',
    component: GestaoPerfisComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: [
        'NuGestaoAcesso',
        'NuSistemaGestaoAcesso',
        'NuRecursosHumano',
        'NuAdmin',
      ],
      title: 'Perfis | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/gestao-de-perfis/criar-perfil',
    component: CriarPerfilComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: [
        'NuGestaoAcesso',
        'NuSistemaGestaoAcesso',
        'NuRecursosHumano',
        'NuAdmin',
      ],
      title: 'Criar Perfil | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/frente-de-trabalho',
    component: ListagemFrenteTrabalhoComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuRecursosHumano', 'NuAdmin'],
      title: 'Frentes de Trabalho | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/frente-de-trabalho/cadastrar',
    component: FrenteTrabalhoComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuRecursosHumano', 'NuAdmin'],
      title: 'Frentes de Trabalho | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/cadastrar-pessoa',
    component: CadastrarPessoaComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuRecursosHumano', 'NuAdmin'],
      title: 'Cadastrar Pessoa | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/pessoas',
    component: ListagemPessoasComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuRecursosHumano', 'NuAdmin'],
      title: 'Pessoas | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/frente-de-trabalho/associar',
    component: AssociarFrenteTrabalhoComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuRecursosHumano', 'NuAdmin'],
      title: 'Associar Frente de Trabalho | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/licencas',
    component: ListagemLicencasComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuRecursosHumano', 'NuAdmin'],
      title: 'Licenças | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/registrar-licenca',
    component: RegistrarLicencaComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuRecursosHumano', 'NuAdmin'],
      title: 'Registrar Licença | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/acrescimo',
    component: AcrescimoComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuRecursosHumano', 'NuAdmin'],
      title: 'Acréscimo | BUILTUP',
    },
  },
  {
    path: 'recursos-humanos/editar-acrescimo',
    component: EditarAcrescimoComponent,
    canActivate: [AppGuard],
    data: {
      requiredRoles: ['NuRecursosHumano', 'NuAdmin'],
      title: 'Editar Acréscimo | BUILTUP',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class GestaoRhRoutingModule {}
