<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo
        [text]="this.isMode ? 'Editar Pessoa' : 'Cadastrar Pessoa'"
      ></app-titulo>
      <p>Passo {{ this.etapa }} de {{ this.totalEtapas }}</p>
    </div>
    <div [ngSwitch]="etapa">
      <div *ngSwitchCase="1">
        <form #pessoaForm="ngForm">
          <app-input
            label="Número da ficha de registro"
            name="numeroFichaRegistro"
            mask="0000.000.00"
            [disabled]="this.isMode"
            [requiredInput]="true"
            [(ngModel)]="form.numeroFichaRegistro"
          ></app-input>
          <app-input
            label="Tipo de contratação"
            name="tipoContratacao"
            type="select"
            [requiredInput]="true"
            [(ngModel)]="form.tipoContratacao"
            [selectData]="tipoContratacaoOptions"
          ></app-input>
          <app-input
            label="Nome completo"
            name="nomeCompleto"
            maxLength="50"
            [requiredInput]="true"
            [(ngModel)]="form.nomeCompleto"
          ></app-input>
          <app-input
            label="Nome da mãe"
            name="nomeMae"
            maxLength="50"
            [requiredInput]="form.cpf.length < 11"
            [(ngModel)]="form.nomeMae"
          ></app-input>
          <app-input
            label="Nome do pai"
            name="nomePai"
            maxLength="50"
            [(ngModel)]="form.nomePai"
          ></app-input>
          <app-input
            label="RG"
            name="rg"
            mask="00.000.000-0"
            [(ngModel)]="form.rg"
          ></app-input>
          <app-input
            label="CPF"
            name="cpf"
            mask="000.000.000-00"
            [requiredInput]="form.nomeMae.length < 2"
            [(ngModel)]="form.cpf"
          ></app-input>
          <app-input
            label="Data de nascimento"
            name="dataNascimento"
            type="date"
            [(ngModel)]="form.dataNascimento"
          ></app-input>
          <app-input
            label="Local do nascimento"
            name="localNascimento"
            maxLength="30"
            [(ngModel)]="form.localNascimento"
          ></app-input>
        </form>
        <div class="form-buttons-unique">
          <app-botao
            text="Avançar"
            (click)="proximaEtapa()"
            [disabled]="verificacaoEtapa1() ? false : true"
            *ngIf="this.permission === 0"
          ></app-botao>
        </div>
      </div>

      <div *ngSwitchCase="2">
        <form #pessoaForm="ngForm">
          <app-input
            label="Documento Militar"
            name="documentoMilitar"
            mask="000.000.000-0"
            [(ngModel)]="form.documentoMilitar"
          ></app-input>
          <app-input
            label="CTPS"
            name="ctps"
            mask="0000000/0000"
            [(ngModel)]="form.ctps"
          ></app-input>
          <app-input
            label="Cor"
            type="select"
            name="cor"
            [selectData]="corOptions"
            [(ngModel)]="form.cor"
          ></app-input>
          <app-input
            label="Sexo"
            type="select"
            name="sexo"
            [selectData]="sexoOptions"
            [(ngModel)]="form.sexo"
          ></app-input>
          <app-input
            label="Grau de instrução"
            type="select"
            name="grauInstrucao"
            [selectData]="grauInstituicaoOptions"
            [(ngModel)]="form.grauInstrucao"
          ></app-input>
          <app-input
            label="CEP"
            name="cep"
            mask="00000-000"
            (ngModelChange)="this.form.cep.length === 8 && buscarCep()"
            [(ngModel)]="form.cep"
          ></app-input>
          <app-input
            label="Endereço"
            name="endereco"
            maxLength="80"
            [(ngModel)]="form.endereco"
          ></app-input>
          <app-input
            label="Nº da residência"
            name="numeroResidencia"
            maxLength="20"
            [(ngModel)]="form.numeroResidencia"
          ></app-input>
          <app-input
            label="Cidade"
            name="cidade"
            maxLength="25"
            [(ngModel)]="form.cidade"
          ></app-input>
          <app-input
            label="Estado"
            name="estado"
            maxLength="20"
            [(ngModel)]="form.estado"
          ></app-input>
        </form>
        <div class="form-buttons">
          <app-botao
            type="secundario"
            text="Anterior"
            (click)="etapaAnterior()"
            *ngIf="this.permission === 0"
          ></app-botao>
          <app-botao
            text="Avançar"
            (click)="proximaEtapa()"
            *ngIf="this.permission === 0"
          ></app-botao>
        </div>
      </div>

      <div *ngSwitchCase="3">
        <form #pessoaForm="ngForm">
          <app-input
            label="Telefone/Celular"
            name="telefone"
            mask="(00) 00000-0000"
            [(ngModel)]="form.telefone"
          ></app-input>
          <app-input
            type="email"
            label="Email"
            name="email"
            [(ngModel)]="form.email"
          ></app-input>
          <app-input
            label="CNH"
            name="cnh"
            mask="00000000000"
            [(ngModel)]="form.cnh"
          ></app-input>
          <app-input
            label="Categoria"
            type="select"
            name="categoria"
            [selectData]="categoriaCnhOptions"
            [(ngModel)]="form.categoriaCnh"
          ></app-input>
          <app-input
            label="Título de eleitor"
            name="tituloEleitor"
            mask="000000000000"
            [(ngModel)]="form.tituloEleitor"
          ></app-input>
          <app-input
            label="Número da reservista"
            name="numeroReservista"
            mask="000000"
            [(ngModel)]="form.numeroReservista"
          ></app-input>
          <app-input
            label="Número da carteira de trabalho"
            name="numeroCarteiraTrabalho"
            mask="0000000/0000"
            [(ngModel)]="form.numeroCarteiraTrabalho"
          ></app-input>
          <app-input
            label="Número do PIS/PASEP"
            name="numeroPisPasep"
            mask="000.00000.00-0"
            [(ngModel)]="form.numeroPisPasep"
          ></app-input>
        </form>
        <div class="form-buttons">
          <app-botao
            type="secundario"
            text="Anterior"
            (click)="etapaAnterior()"
            *ngIf="this.permission === 0"
          ></app-botao>
          <app-botao
            text="Avançar"
            (click)="proximaEtapa()"
            *ngIf="this.permission === 0"
          ></app-botao>
        </div>
      </div>

      <div *ngSwitchCase="4">
        <form #pessoaForm="ngForm">
          <app-input
            type="date"
            placeholder="Data de admissão"
            name="dataAdmissao"
            [(ngModel)]="form.dataAdmissao"
          ></app-input>
          <app-input
            type="date"
            placeholder="Data de demissão"
            name="dataDemissao"
            [(ngModel)]="form.dataDemissao"
          ></app-input>
          <app-input
            label="Local de trabalho"
            name="localDeTrabalho"
            maxLength="40"
            [(ngModel)]="form.localTrabalho"
          ></app-input>
          <app-input
            label="Estado civil"
            type="select"
            name="estadoCivil"
            [selectData]="estadoCivilOptions"
            [(ngModel)]="form.estadoCivil"
          ></app-input>
          <app-input
            type="money"
            label="Valor do salário"
            name="valorSalario"
            maxLength="15"
            [(ngModel)]="form.valorSalario"
            (ngModelChange)="valorHora()"
          ></app-input>
          <app-input
            label="Carga horária"
            name="cargaHoraria"
            maxLength="20"
            mask="000"
            [(ngModel)]="form.cargaHoraria"
            (ngModelChange)="valorHora()"
          ></app-input>
          <app-input
            type="money"
            label="Valor hora"
            name="valorHora"
            maxLength="15"
            [disabled]="true"
            [(ngModel)]="form.valorHora"
          ></app-input>
          <app-input
            type="money"
            label="Valor da comissão"
            name="valorComissao"
            maxLength="15"
            [(ngModel)]="form.valorComissao"
          ></app-input>
          <app-input
            type="textarea"
            label="Descrição do salário e comissão"
            name="descricaoSalario"
            maxLength="150"
            class="grid-2fr"
            [(ngModel)]="form.descricaoSalario"
          ></app-input>
        </form>
        <div class="form-buttons">
          <app-botao
            type="secundario"
            text="Anterior"
            (click)="etapaAnterior()"
            *ngIf="this.permission === 0"
          ></app-botao>
          <app-botao
            text="Avançar"
            (click)="proximaEtapa()"
            *ngIf="this.permission === 0"
          ></app-botao>
        </div>
      </div>

      <div *ngSwitchCase="5">
        <form #pessoaForm="ngForm">
          <app-input
            label="Possui deficiência"
            type="select"
            name="possuiDeficiencia"
            [selectData]="possuiDeficienciaOptions"
            [(ngModel)]="form.possuiDeficiencia"
          ></app-input>
          <app-input
            *ngIf="this.form.possuiDeficiencia"
            label="Qual?"
            name="deficiencia"
            maxLength="30"
            [(ngModel)]="form.deficiencia"
          ></app-input>
          <app-input
            label="Titular bancário"
            name="titularBancario"
            maxLength="50"
            [(ngModel)]="form.titularBancario"
          ></app-input>
          <app-input
            label="Tipo de conta"
            type="select"
            name="tipoConta"
            [selectData]="tipoContaOptions"
            [(ngModel)]="form.tipoConta"
          ></app-input>
          <app-input
            label="Banco"
            name="banco"
            maxLength="20"
            [(ngModel)]="form.banco"
          ></app-input>
          <app-input
            label="Agência"
            name="agencia"
            mask="0000"
            [(ngModel)]="form.agencia"
          ></app-input>
          <app-input
            label="Número da conta"
            name="numeroConta"
            mask="0000000"
            [(ngModel)]="form.numeroConta"
          ></app-input>
          <app-input
            label="Dígito da conta"
            name="digitoConta"
            mask="0"
            [(ngModel)]="form.digitoConta"
          ></app-input>
          <app-drag-drop
            label="Foto do pessoa"
            text="Arraste para enviar a foto"
            name="fotoPessoa"
            class="grid-2fr"
            acceptedFileType=".jpg,image/jpeg"
            (fileDropped)="onFileDropped('fotoPessoa', $event)"
          ></app-drag-drop>
        </form>
        <div class="form-buttons">
          <app-botao
            type="secundario"
            text="Anterior"
            (click)="etapaAnterior()"
            *ngIf="this.permission === 0"
          ></app-botao>
          <app-botao
            text="Avançar"
            (click)="proximaEtapa()"
            *ngIf="this.permission === 0"
          ></app-botao>
        </div>
      </div>

      <div *ngSwitchCase="6">
        <form #pessoaForm="ngForm">
          <app-drag-drop
            label="Atestado de escolaridade"
            text="Arraste para enviar a foto"
            name="atestadoEscolaridade"
            acceptedFileType=".jpg,image/jpeg"
            (fileDropped)="onFileDropped('atestadoEscolaridade', $event)"
          ></app-drag-drop>
          <app-drag-drop
            label="Exame médico admissional"
            text="Arraste para enviar a foto"
            name="exameAdmissional"
            acceptedFileType=".jpg,image/jpeg"
            (fileDropped)="onFileDropped('exameAdmissional', $event)"
          ></app-drag-drop>
          <app-drag-drop
            label="Carteira de trabalho"
            text="Arraste para enviar a foto"
            name="carteiraTrabalho"
            acceptedFileType=".jpg,image/jpeg"
            (fileDropped)="onFileDropped('carteiraTrabalho', $event)"
          ></app-drag-drop>
          <app-drag-drop
            label="Previdência social (CTPS)"
            text="Arraste para enviar a foto"
            name="previdenciaSocial"
            acceptedFileType=".jpg,image/jpeg"
            (fileDropped)="onFileDropped('previdenciaSocial', $event)"
          ></app-drag-drop>
          <app-drag-drop
            label="Certidão de nascimento"
            text="Arraste para enviar a foto"
            name="certidaoNascimento"
            acceptedFileType=".jpg,image/jpeg"
            (fileDropped)="onFileDropped('certidaoNascimento', $event)"
          ></app-drag-drop>
          <app-drag-drop
            label="Certidão de casamento"
            text="Arraste para enviar a foto"
            name="certidaoCasamento"
            acceptedFileType=".jpg,image/jpeg"
            (fileDropped)="onFileDropped('certidaoCasamento', $event)"
          ></app-drag-drop>
          <app-drag-drop
            label="Declaração do vale transporte"
            text="Arraste para enviar a foto"
            name="valeTransporte"
            acceptedFileType=".jpg,image/jpeg"
            (fileDropped)="onFileDropped('valeTransporte', $event)"
          ></app-drag-drop>
          <app-drag-drop
            label="Prova de quitação do serviço militar"
            text="Arraste para enviar a foto"
            name="servicoMilitar"
            acceptedFileType=".jpg,image/jpeg"
            (fileDropped)="onFileDropped('servicoMilitar', $event)"
          ></app-drag-drop>
        </form>
        <div class="form-buttons">
          <app-botao
            type="secundario"
            text="Anterior"
            (click)="etapaAnterior()"
            *ngIf="this.permission === 0"
          ></app-botao>
          <app-botao
            text="Finalizar"
            (click)="cadastrarPessoa()"
            *ngIf="this.permission === 0"
          ></app-botao>
        </div>
      </div>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="this.loader"></app-loader>
