import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlanejamentoComponent } from './planejamento/planejamento/planejamento.component';
import { DiarioObraComponent } from './diario-obra/diario-obra/diario-obra.component';
import { AppGuard } from '../../services/auth.guard';

const routes: Routes = [
  {
    path: 'planejamento',
    component: PlanejamentoComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuAdmin'] },
  },
  {
    path: 'planejamento/diario',
    component: DiarioObraComponent,
    canActivate: [AppGuard],
    data: { requiredRoles: ['NuAdmin'] },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class PlanejamentoRoutingModule {}
