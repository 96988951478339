import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestao-acesso',
  templateUrl: './gestao-acesso.component.html',
  styleUrls: ['./gestao-acesso.component.scss'],
})
export class GestaoAcessoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
