<div *ngFor="let item of data">
  <div *ngIf="!expand" class="card">
    <ng-container *ngFor="let row of rows">
      <div *ngIf="row.default" class="card-row">
        <strong>{{ row.header }}</strong>

        <ng-container [ngSwitch]="row.type">
          <span *ngSwitchCase="'text'" [ngClass]="getEstilo(item) ?? row.style">
            {{ item[row.key] }}
          </span>

          <div *ngSwitchCase="'buttons'">
            <ng-container *ngFor="let button of row.buttons">
              <ng-container *ngIf="!button.condition || button.condition(item)">
                <button (click)="button.action(item)">
                  <img
                    class="button-img"
                    *ngIf="button.img"
                    [src]="button.img"
                  />
                  <span class="button" *ngIf="!button.img">{{
                    button.label
                  }}</span>
                </button>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div *ngIf="expand" class="card">
    <ng-container *ngFor="let row of rows">
      <div class="card-row">
        <strong>{{ row.header }}</strong>

        <ng-container [ngSwitch]="row.type">
          <span *ngSwitchCase="'text'" [ngClass]="getEstilo(item) ?? row.style">
            {{ item[row.key] }}
          </span>

          <div *ngSwitchCase="'buttons'">
            <ng-container *ngFor="let button of row.buttons">
              <ng-container *ngIf="!button.condition || button.condition(item)">
                <button (click)="button.action(item)">
                  <img
                    class="button-img"
                    *ngIf="button.img"
                    [src]="button.img"
                  />
                  <span class="button" *ngIf="!button.img">{{
                    button.label
                  }}</span>
                </button>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
