<app-menu>
  <div class="desktop">
    <app-voltar class="back"></app-voltar>
    <div class="header">
      <app-titulo text="Editar acréscimo"></app-titulo>
    </div>
    <form #cadastroDisciplina="ngForm" class="form">
      <app-input
        type="text"
        width="100%"
        label="Tipo acréscimo"
        name="TxDescricao"
        [(ngModel)]="form.TxDescricao"
      ></app-input>
      <app-input
        type="text"
        width="100%"
        label="Percentual"
        name="VlPercentual"
        [(ngModel)]="form.VlPercentual"
      ></app-input>
    </form>
    <div class="form-button">
      <app-botao
        width="100%"
        text="Salvar"
        (click)="editarAcrescimo()"
      ></app-botao>
    </div>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
