import { ToastrService } from 'ngx-toastr';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpService } from '../../../services/common/http/common.http.service';

@Component({
  selector: 'app-selecao-reeducando-layout',
  templateUrl: './selecao-reeducando-layout.component.html',
  styleUrls: ['./selecao-reeducando-layout.component.scss'],
})
export class SelecaoPessoaLayoutComponent implements OnInit {
  @Output() pessoaSelecionada = new EventEmitter<string>();
  pessoaOptions: any = [];
  visible: boolean = true;
  loader: boolean = false;

  form = {
    TxNome: '',
    TxFichaRegistro: '',
  };

  constructor(private httpService: HttpService, private toast: ToastrService) {}

  ngOnInit() {
    this.getPessoas();
  }

  getPessoas() {
    this.loader = true;

    this.httpService.useGet('Pessoa').subscribe({
      next: (response: any) => {
        this.pessoaOptions = response.map((x: any) => {
          return {
            name: x.TxNome,
            value: x.IdPessoa,
            fichaRegistro: x.TxFichaRegistro,
          };
        });

        this.loader = false;
      },
      error: (_error: any) => {
        this.loader = false;
        this.toast.error('Não foi possível buscar listagem. Tente novamente!');
      },
    });
  }

  escolherPessoa(value: any) {
    const pessoa = this.pessoaOptions.find(
      (pessoa: any) => pessoa?.value === Number(value)
    );

    this.form.TxFichaRegistro = pessoa?.fichaRegistro || '';
  }

  confirmarPonto() {
    this.pessoaSelecionada.emit(this.form.TxFichaRegistro);
  }
}
