import { ZodError, ZodSchema } from 'zod';

export const validateSchema = function<T> (schema: ZodSchema, data: T): T {
    try {
        const response = schema.parse(data);

        return response;
    } catch (error) {
        const zodError = error as ZodError;

        const issue = zodError?.issues;
        throw new Error(`${issue?.length > 0 ? issue[0].message : 'Erro ao tentar validar o schema'}`)
    }
};
