<app-menu>
  <div class="desktop">
    <div class="header">
      <app-titulo text="Perfis"></app-titulo>
      <div class="header-buttons">
        <app-botao
          type="primario"
          text="Criar perfil"
          width="100%"
          icon="assets/images/add.svg"
          (click)="criarPerfil()"
          *ngIf="this.permission === 0"
        ></app-botao>
        <app-botao
          type="secundario"
          text="Exportar Excel"
          width="100%"
          icon="assets/images/download-icon.svg"
          (click)="exportarExcel()"
        ></app-botao>
      </div>
    </div>
    <div class="table-header">
      <h1 class="table-title">Perfis</h1>
      <div class="filters">
        <app-input
          type="search"
          placeholder="Pesquisar"
          icon="assets/images/search.svg"
          (ngModelChange)="onChangeSearch($event)"
          [(ngModel)]="form.pesquisar"
        ></app-input>
      </div>
    </div>
    <app-tabela
      [data]="tableData"
      [columns]="tableColumns"
      (ngModelChange)="onChangeSelect($event)"
    ></app-tabela>
  </div>

  <div class="mobile">
    <div class="menu-mobile">
      <app-titulo text="Perfis"></app-titulo>
      <app-botao
        type="primario"
        text="Criar perfil"
        width="100%"
        icon="assets/images/add.svg"
        (click)="criarPerfil()"
        *ngIf="this.permission === 0"
      ></app-botao>
    </div>
    <app-card-mobile [rows]="rowsMobile" [data]="tableData"></app-card-mobile>
    <app-botao
      width="100%"
      color="#64748B"
      bgColor="#F8FAFC"
      border="1px solid #D1D5DB"
      icon="assets/images/download-icon.svg"
      text="Exportar Excel"
    ></app-botao>
  </div>
</app-menu>

<app-loader *ngIf="loader"></app-loader>
